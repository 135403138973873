import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: "#708690",
      main: "#445963",
      dark: "#1b3039",
      contrastText: "#fff",
    },
    secondary: {
      light: "#009688",
      main: "#00897b",
      dark: "#00695c",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: "1px solid #e5e5e5",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px 8px",
      },
    },
    MuiRadio: {
      root: {
        padding: "4px 8px",
      },
    },
    MuiSwitch: {
      switchBase: {
        height: "36px",
      },
    },
    MuiFormControl: {
      root: {
        minWidth: "256px",
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 1,
    },
    MuiFormControl: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiInputBase: {
      margin: "dense",
    },
  },
})

export default theme
