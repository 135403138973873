const isAllSpaces = function(text, cursorPosition) {
  // distanceUpper
  const lineStart = getStartPosition2(text, cursorPosition)
  if (lineStart === null) {
    return false
  }
  const lineEnd = getEndPosition(text, cursorPosition)
  for (let i = lineStart; i < lineEnd; i++) {
    if (text[i] !== " ") {
      return false
    }
  }
  return true
}

// const getLineNumber = function(text, cursorPosition) {
//   let count = 0
//   for (let i = 0; i < cursorPosition; i++) {
//     if (text[i] === "\n") count += 1
//   }
//   return count
// }

const getStartPosition2 = function(text, cursorPosition) {
  if (cursorPosition <= 0) return 0
  const newLineCharOrBeginning = scanStringForCharacter(
    text,
    cursorPosition - 1,
    "\n",
    true
  )
  if (newLineCharOrBeginning === null) {
    return null
  }
  if (text[newLineCharOrBeginning] === "\n") {
    return newLineCharOrBeginning + 1
  }
  return newLineCharOrBeginning
}

const getEndPosition = function(text, cursorPosition) {
  const endIndex = text.length
  let cursorPositionCurrent = cursorPosition
  while (text[cursorPositionCurrent] !== "\n") {
    if (cursorPositionCurrent >= endIndex) {
      break
    } else {
      cursorPositionCurrent += 1
    }
  }
  return Math.min(cursorPositionCurrent, endIndex)
}

const getSpacesAtBeginningOfLine = function(text, cursorPosition) {
  let lineStart = getStartPosition2(text, cursorPosition)
  if (lineStart === null) {
    return null
  }

  let count = 0
  while (text[lineStart] === " " && lineStart <= text.length - 1) {
    count += 1
    lineStart += 1
  }
  return count
}

const scanStringForCharacter = function(
  text,
  cursorPosition,
  searchChar,
  left = false
) {
  if (cursorPosition < 0 || cursorPosition > text.length) {
    return null
  }

  let currentPosition = cursorPosition
  // Cursor is at the end of text blinking for next character
  if (currentPosition === text.length) {
    currentPosition -= 1
  }

  const listOfStrings = [].concat(searchChar)

  const downOrUp = left ? -1 : 1
  while (
    !listOfStrings.includes(text.charAt(currentPosition)) &&
    currentPosition < text.length &&
    currentPosition >= 0
  ) {
    currentPosition += downOrUp
  }
  // Need this for slice
  if (currentPosition < 0) {
    return 0
  }
  if (currentPosition > text.length) {
    return text.length
  }
  return currentPosition
}

const getLineText = function(text, cursorPosition) {
  const startIndex = getStartPosition2(text, cursorPosition)
  const endIndex = getEndPosition(text, cursorPosition)
  let textLine = text.substring(startIndex, endIndex)
  textLine = textLine.replace("\n", "")
  return textLine
}

const getNextLineStart = function(text, cursorPosition) {
  const nextLineStart = getEndPosition(text, cursorPosition) + 1
  return nextLineStart < text.length ? nextLineStart : -1
}

const getNextLineStartAndSpaces = function(text, cursorPosition) {
  const nextLineStart = getNextLineStart(text, cursorPosition)
  let numberOfSpaces = -1
  let allSpaces = false
  if (nextLineStart !== -1) {
    numberOfSpaces = getSpacesAtBeginningOfLine(text, nextLineStart)
    allSpaces = isAllSpaces(text, nextLineStart)
  }

  return [nextLineStart, numberOfSpaces, allSpaces]
}

const generateRecursiveSetList = function(text, cursorPosition) {
  if (!text || text.length === 0) {
    return [{ text: "", innerDescriptions: [] }]
  }
  const firstLineSpaces = getSpacesAtBeginningOfLine(text, cursorPosition)

  let currentLineStart = cursorPosition
  let currentLineSpaces = firstLineSpaces
  let allSpaces = false
  const innerDescriptionsList = []
  while (
    currentLineStart !== -1 &&
    (currentLineSpaces >= firstLineSpaces || allSpaces)
  ) {
    if (currentLineSpaces === firstLineSpaces && !allSpaces) {
      innerDescriptionsList.push(currentLineStart)
    }
    ;[
      currentLineStart,
      currentLineSpaces,
      allSpaces,
    ] = getNextLineStartAndSpaces(text, currentLineStart)
  }
  return innerDescriptionsList.map((el) => generateRecursiveSetObject(text, el))
}

const generateRecursiveSetObject = function(text, cursorPosition) {
  const firstLineSpaces = getSpacesAtBeginningOfLine(text, cursorPosition)
  let [nextLineStart, nextLineSpaces, allSpaces] = getNextLineStartAndSpaces(
    text,
    cursorPosition
  )

  // While nextLine exists, and allSpaces is true
  while (nextLineStart !== -1 && allSpaces) {
    ;[nextLineStart, nextLineSpaces, allSpaces] = getNextLineStartAndSpaces(
      text,
      nextLineStart
    )
  }

  let innerDescriptions = []
  if (nextLineStart === -1) {
    innerDescriptions = []
    return {
      text: getLineText(text, cursorPosition),
      innerDescriptions,
    }
  }

  if (nextLineStart !== -1 && nextLineSpaces > firstLineSpaces) {
    innerDescriptions = generateRecursiveSetList(text, nextLineStart)
  }
  return {
    text: getLineText(text, cursorPosition),
    innerDescriptions,
  }
}

export {
  generateRecursiveSetObject,
  generateRecursiveSetList,
  scanStringForCharacter,
  getStartPosition2,
  getSpacesAtBeginningOfLine,
  getLineText,
}
