import PropTypes from "prop-types"
import React from "react"
import TimeList from "./TimeList"
import DistanceList from "./DistanceList"
import PaceList from "./PaceList"
import Divider from "@material-ui/core/Divider"
import moment from "moment"
import {
  blueGrey,
  lightBlue,
  green,
  purple,
  red,
  lime,
  amber,
  indigo,
} from "@material-ui/core/colors"
import Typography from "@material-ui/core/Typography"
import DeviceAccessTime from "@material-ui/icons/AccessTime"
import ActionSwapHoriz from "@material-ui/icons/SwapHoriz"

/*
This component takes waitTime from above, and won't re-render unless that waitTime has
elapsed (Useful on landing page).

The case with the Landing page is that we should not re render until the user stops
typing. It also does the splitting when we have a currentTeamId
*/

function allSame(laps) {
  let same = true
  if (laps.length > 0) {
    const current = laps[0].seconds
    for (let i = 1; i < laps.length; i++) {
      if (laps[i].seconds !== current) same = false
    }
  }
  return same
}

class StatsInner extends React.Component {
  static propTypes = {
    laps: PropTypes.array,
    style: PropTypes.object,
    isLanding: PropTypes.bool,
    metricAbbreviation: PropTypes.string,
  }

  static defaultProps = {
    laps: [],
    style: {},
    isLanding: false,
    height: "100%",
  }

  toTime = (timeInSeconds) => {
    const time = moment()
      .startOf("day")
      .seconds(timeInSeconds)
    if (time.hours() > 0) return time.format("h:mm:ss")

    return time.format("m:ss")
  }

  getDistance = (laps, justNumber) => {
    const totalDistance = laps
      .map((el) => el.distance)
      .reduce((a, b) => a + b, 0)
    if (justNumber) return totalDistance
    if (totalDistance > 0) return totalDistance.toLocaleString()

    return ""

    // return totalDistance > 0 ? totalDistance.toLocaleString() : ''; // Don't display 0
  }

  getTime = (laps) => {
    const totalTime = laps.map((el) => el.seconds).reduce((a, b) => a + b, 0)
    return totalTime > 0 ? this.toTime(totalTime) : "" // Don't display 0:00
  }

  // Need to make more badass with evens/odds, reps ranges
  makeTimeList = (laps, justNumber) => {
    const timeList = []
    const totalTime = laps.map((el) => el.seconds).reduce((a, b) => a + b, 0)
    if (justNumber) timeList.push({ name: "", time: totalTime })
    else if (totalTime > 0) timeList.push({ name: "Total", time: totalTime })

    const intervalLaps = laps.map((el) => el.interval).filter((el) => el)
    if (
      intervalLaps.length > 0 &&
      allSame(intervalLaps) &&
      intervalLaps[0].seconds !== totalTime
    )
      timeList.push({ name: "Interval", time: intervalLaps[0].seconds })

    const holdLaps = laps.map((el) => el.hold).filter((el) => el)
    if (holdLaps.length > 0 && allSame(holdLaps))
      timeList.push({ name: "Hold", time: holdLaps[0].seconds })

    const restLaps = laps.map((el) => el.rest).filter((el) => el)
    if (restLaps.length > 0 && allSame(restLaps))
      timeList.push({ name: "Rest", time: restLaps[0].seconds })

    return timeList
  }

  getDistanceGivenType = (type) => {
    const groupedCount = {}
    this.props.laps.forEach((lap) => {
      if (lap[type]) {
        const typeName = lap[type].name
        const distance = lap.distance
        if (typeName in groupedCount) groupedCount[typeName] += distance
        else groupedCount[typeName] = distance
      }
    })
    const groupedCorrect = []
    for (const name of Object.keys(groupedCount))
      groupedCorrect.push({ name, distance: groupedCount[name] })

    return groupedCorrect
  }

  getDistanceForEquipment = () => {
    const distanceList = []

    this.props.laps.forEach((lap) => {
      lap.equipments.forEach((equipObj) => {
        const thisElement = distanceList.find((el) => el._id === equipObj._id)
        if (thisElement) thisElement.distance += lap.distance
        else {
          const newObj = {
            _id: equipObj._id,
            name: equipObj.name,
            distance: lap.distance,
          }
          distanceList.push(newObj)
        }
      })
    })
    for (let i = 0; i < distanceList.length; i++) delete distanceList[i]._id

    return distanceList
  }

  itemStyles = () => ({
    width: this.props.isMobile ? "28%" : "14%",
    textAlign: "center",
    fontSize: this.props.isMobile ? ".7em" : ".9em",
    margin: "8px 4px",
  })

  // Doesn't imply anything. Can be used on all browsers and mobile
  renderLandingPage = () => {
    const landingStyles = {
      padding: 0,
      margin: 0,
      listStyle: "none",
      WebkitFlexFlow: "row wrap",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      WebkitJustifyContent: "space-around",
    }

    const parseHeader = {
      fontSize: ".9em",
      borderBottom: "2px solid #677787",
      padding: "5px",
      marginTop: 0,
    }

    return this.props.isMobile ? (
      <div
        className="commitflex"
        style={Object.assign({}, landingStyles, this.props.style)}
      >
        <div style={Object.assign({ color: green[500] }, this.itemStyles())}>
          <p style={parseHeader}>STROKE</p>
          <DistanceList distanceList={this.getDistanceGivenType("stroke")} />
        </div>
        <div
          style={Object.assign({ color: lightBlue[500] }, this.itemStyles())}
        >
          <p style={parseHeader}>TYPE</p>
          <DistanceList distanceList={this.getDistanceGivenType("type")} />
        </div>
        <div style={Object.assign({ color: lime[500] }, this.itemStyles())}>
          <p style={parseHeader}>INTENSITY</p>
          <DistanceList distanceList={this.getDistanceGivenType("intensity")} />
        </div>
      </div>
    ) : (
      <div
        className="commitflex"
        style={Object.assign({}, landingStyles, this.props.style)}
      >
        <div style={Object.assign({ color: blueGrey[500] }, this.itemStyles())}>
          <p style={parseHeader}>DISTANCE</p>
          <div>
            <span style={{ margin: 0, padding: 0 }}>
              {this.getDistance(this.props.laps)}
            </span>
          </div>
        </div>
        <div
          style={Object.assign({ color: lightBlue[700] }, this.itemStyles())}
        >
          <p style={parseHeader}>TIME</p>
          <TimeList timeList={this.makeTimeList(this.props.laps)} />
        </div>
        <div style={Object.assign({ color: green[700] }, this.itemStyles())}>
          <p style={parseHeader}>STROKE</p>
          <DistanceList distanceList={this.getDistanceGivenType("stroke")} />
        </div>
        <div style={Object.assign({ color: purple[700] }, this.itemStyles())}>
          <p style={parseHeader}>TYPE</p>
          <DistanceList distanceList={this.getDistanceGivenType("type")} />
        </div>
        <div style={Object.assign({ color: red[700] }, this.itemStyles())}>
          <p style={parseHeader}>INTENSITY</p>
          <DistanceList distanceList={this.getDistanceGivenType("intensity")} />
        </div>
        <div style={Object.assign({ color: lime[800] }, this.itemStyles())}>
          <p style={parseHeader}>EQUIPMENT</p>
          <DistanceList distanceList={this.getDistanceForEquipment()} />
        </div>
      </div>
    )
  }

  getPace = (laps, distance) => {
    const speeds = laps
      .filter((el) => el.distance > 0)
      .map((el) => (distance * el.seconds) / el.distance)
    const paceSet = new Set(speeds)

    return Array.from(paceSet)
  }

  renderMobileWorkouts = () => (
    <div style={this.props.style}>
      <div
        className="commitflex"
        style={{
          paddingTop: "2px",
          paddingBottom: "0px",
          textTransform: "capitalize",
          justifyContent: "space-around",
          WebkitJustifyContent: "space-around",
          flexFlow: "row wrap",
          WebkitFlexFlow: "row wrap",
        }}
      >
        <div>
          <Typography
            style={{
              paddingLeft: "0px",
              fontSize: "12px",
              lineHeight: "1.5em",
            }}
          >
            Distance
          </Typography>
          <span style={{ marginTop: 0, color: blueGrey[800] }}>
            {`${this.getDistance(this.props.laps, true).toLocaleString()} ${
              this.props.metricAbbreviation
            }`}
          </span>
        </div>
        <div>
          <Typography
            style={{
              paddingLeft: "0px",
              fontSize: "12px",
              lineHeight: "1.5em",
            }}
          >
            Time
          </Typography>
          <span style={{ marginTop: 0, color: indigo[800] }}>
            {this.getTime(this.props.laps)}
          </span>
        </div>
        <div>
          <Typography
            style={{
              paddingLeft: "0px",
              fontSize: "12px",
              lineHeight: "1.5em",
            }}
          >
            100 pace
          </Typography>
          {/* <span style={{marginTop:0, color: green800}}>{this.toTime(this.getPace(this.props.laps, 100))}</span> */}
          <PaceList paceList={this.getPace(this.props.laps, 100)} />
        </div>
      </div>
    </div>
  )

  renderWebWorkouts = () => {
    let equipmentDiv = null

    // let intensityDiv = null
    // if (this.getDistanceGivenType("intensity").length > 0) {
    //   intensityDiv = (
    //     <div style={{ color: indigo800, width: "110px", marginBottom: "10px" }}>
    //       <label>Intensity</label>
    //       <br />
    //       <DistanceList distanceList={this.getDistanceGivenType("intensity")} />
    //     </div>
    //   )
    // }

    if (this.getDistanceForEquipment().length > 0) {
      equipmentDiv = (
        <div style={{ width: "16%" }}>
          <Typography
            style={{
              paddingLeft: "0px",
              fontSize: "12px",
              lineHeight: "1.5em",
            }}
          >
            Equipment
          </Typography>
          <DistanceList
            distanceList={this.getDistanceForEquipment()}
            style={{ fontSize: ".85em", color: red[800] }}
          />
        </div>
      )
    }

    return (
      <div style={this.props.style}>
        <Divider style={{ marginRight: "32px", marginLeft: "32px" }} />
        <div
          className="commitflex"
          style={{
            padding: "16px",
            textTransform: "capitalize",
            justifyContent: "space-around",
            WebkitJustifyContent: "space-around",
            flexFlow: "row wrap",
            WebkitFlexFlow: "row wrap",
          }}
        >
          <div style={{ width: "20%" }}>
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "12px",
                lineHeight: "1.5em",
              }}
            >
              Distance & Time
            </Typography>
            <div
              style={{ marginTop: 0, fontSize: ".85em", color: blueGrey[800] }}
              className="commitflex commitcenteralign"
            >
              <ActionSwapHoriz style={{ height: "18px", width: "18px" }} />
              &nbsp;
              {this.getDistance(this.props.laps)}
            </div>
            <div
              style={{ marginTop: 0, fontSize: ".85em", color: blueGrey[800] }}
              className="commitflex commitcenteralign"
            >
              <DeviceAccessTime style={{ height: "18px", width: "18px" }} />
              &nbsp;
              {this.getTime(this.props.laps)}
            </div>
            {/* <TimeList timeList={this.makeTimeList(this.props.laps)} style={{color:  indigo800}}/> */}
          </div>
          {/* <div style={{width: '18%'}}>
            <Typography style={{paddingLeft: '0px', fontSize: '12px', lineHeight: '1.5em'}}>Time</Typography>
            <TimeList timeList={this.makeTimeList(this.props.laps)} style={{color:  lightBlue[800]}}/>
          </div> */}
          <div style={{ color: green[800], width: "13%" }}>
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "12px",
                lineHeight: "1.5em",
              }}
            >
              100 pace
            </Typography>
            <PaceList
              paceList={this.getPace(this.props.laps, 100)}
              style={{ fontSize: ".85em" }}
            />
          </div>
          {/* <div style={{color:  lightBlue[800], width: '18%'}}>
            <label>200 pace</label><br />
            <PaceList paceList={this.getPace(this.props.laps, 200)} />
          </div> */}
          <div style={{ width: "16%" }}>
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "12px",
                lineHeight: "1.5em",
              }}
            >
              Strokes
            </Typography>
            <DistanceList
              distanceList={this.getDistanceGivenType("stroke")}
              style={{ fontSize: ".85em", color: purple[800] }}
            />
          </div>
          <div style={{ width: "16%" }}>
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "12px",
                lineHeight: "1.5em",
              }}
            >
              Types
            </Typography>
            <DistanceList
              distanceList={this.getDistanceGivenType("type")}
              style={{ fontSize: ".85em", color: amber[800] }}
            />
          </div>
          <div style={{ width: "16%" }}>
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "12px",
                lineHeight: "1.5em",
              }}
            >
              Intensity
            </Typography>
            <DistanceList
              distanceList={this.getDistanceGivenType("intensity")}
              style={{ fontSize: ".85em", color: lightBlue[800] }}
            />
          </div>
          {equipmentDiv}
        </div>
      </div>
    )
  }

  renderWorkoutsPage = () => {
    if (this.props.isMobile) return this.renderMobileWorkouts()

    if (this.props.laps.length === 0) return <div />

    return this.renderWebWorkouts()
  }

  render() {
    if (this.props.isLanding) return this.renderLandingPage()

    return this.renderWorkoutsPage()
  }
}

export default StatsInner
