import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { orange, blue, teal, grey } from "@material-ui/core/colors"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import $ from "jquery"

class WhatIsCommit extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
    routerLocation: PropTypes.object,
    isIos: PropTypes.bool,
    isAndroid: PropTypes.bool,
  }

  static propTypes = {
    user: PropTypes.object,
  }

  UNSAFE_componentWillMount() {
    $.getScript("https://fast.wistia.net/assets/external/E-v1.js")
  }

  goToEmail = () => {
    window.open("https://workouts.commitswimming.com/get-started/")
  }

  goToPricing = () => {
    this.props.history.push("/pricing")
  }

  openAndroidApp = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.commitswim&hl=en"
    )
  }

  openIosApp = () => {
    window.open(
      "https://itunes.apple.com/us/app/commit-swimming-workouts/id1054029727?ls=1&mt=8"
    )
  }

  render() {
    let takeAction = this.goToEmail
    let label = "Sign up now for free"

    if (this.context.isIos) {
      takeAction = this.openIosApp
      label = "Get Commit for iOS"
    } else if (this.context.isAndroid) {
      takeAction = this.openAndroidApp
      label = "Get Commit for Android"
    }
    const callToActionButton = (
      <Button
        variant="raised"
        onClick={takeAction}
        style={{
          height: "44px",
          margin: "16px",
          textTransform: "none",
          fontSize: "1.2em",
          backgroundColor: orange[500],
          color: "white",
        }}
      >
        {label}
      </Button>
    )

    return (
      <div>
        <div style={{ margin: "auto", maxWidth: "1000px" }}>
          <div style={{ padding: "16px" }}>
            <img
              alt="Commit Logo"
              src={"/logo-dark.svg"}
              onClick={this.goToHome}
              style={{ height: "40px", cursor: "pointer" }}
            />
            <h2 style={{ textAlign: "center", color: "#424242" }}>
              Swimming's #1 Workout Manager
            </h2>
            <Paper
              style={{
                margin: "auto",
                maxWidth: "760px",
                width: "96%",
                backgroundColor: "white",
              }}
            >
              <div
                className="wistia_responsive_padding"
                style={{ padding: "62.5% 0 0 0", position: "relative" }}
              >
                <div
                  className="wistia_responsive_wrapper"
                  style={{
                    height: "100%",
                    left: 0,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                  }}
                >
                  <iframe
                    src="https://fast.wistia.net/embed/iframe/uhhgc6ntht?videoFoam=true"
                    title="Commit Swimming Promo Video"
                    allowTransparency="true"
                    frameBorder="0"
                    style={{ border: "0 none" }}
                    scrolling="no"
                    className="wistia_embed"
                    name="wistia_embed"
                    allowFullScreen
                    mozAllowFullScreen
                    webkitAllowFullScreen
                    oAllowFullScreen
                    msAllowFullScreen
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </Paper>
            <div style={{ textAlign: "center", marginTop: "32px" }}>
              {callToActionButton}
              <h3 style={{ color: grey[500] }}>OR</h3>
              <Button
                onClick={this.goToPricing}
                style={{
                  color: grey[600],
                  height: "44px",
                  textTransform: "none",
                  fontSize: "1.2em",
                }}
              >
                View pricing first
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <section style={{ backgroundColor: "white" }}>
          <div
            className="commitflex commitcenteralign"
            style={{
              maxWidth: "1200px",
              margin: "0px auto",
              justifyContent: "space-around",
              WebkitJustifyContent: "space-around",
              flexFlow: "row wrap",
              WebkitFlexFlow: "row wrap",
            }}
          >
            <div
              className="commitflex commitcenteralign"
              style={{
                margin: "32px 0px",
                justifyContent: "center",
                WebkitJustifyContent: "center",
                flexFlow: "row wrap",
                WebkitFlexFlow: "row wrap",
              }}
            >
              <img
                alt="Tennessee"
                style={{ width: "100px", margin: "16px" }}
                src={"/tennesseeLogo.jpg"}
              />
              <div style={{ maxWidth: "400px", marginLeft: "32px" }}>
                <p style={{ color: orange[500], fontSize: "1.4em" }}>
                  "This is an enormous step forward from anything else that’s
                  available. It's a game changer."
                </p>
                <div
                  className="commitflex commitcenteralign"
                  style={{
                    justifyContent: "flex-start",
                    WebkitJustifyContent: "flex-start",
                    flexFlow: "row wrap",
                    WebkitFlexFlow: "row wrap",
                  }}
                >
                  <Avatar
                    size={80}
                    style={{ marginRight: "32px" }}
                    src={"/Kredich.jpg"}
                  />
                  <p style={{ fontWeight: 700 }}>
                    Matt Kredich,{" "}
                    <span style={{ color: orange[500] }}>Tennessee</span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="commitflex commitcenteralign"
              style={{
                margin: "32px 0px",
                justifyContent: "center",
                WebkitJustifyContent: "center",
                flexFlow: "row wrap",
                WebkitFlexFlow: "row wrap",
              }}
            >
              <img
                alt="Peddie"
                style={{ width: "100px", margin: "16px" }}
                src={"/PeddieLogo.jpg"}
              />
              <div style={{ maxWidth: "400px", marginLeft: "32px" }}>
                <p style={{ color: blue[500], fontSize: "1.4em" }}>
                  "Commit has created the most significant workout manager I've
                  ever seen. This program is a blessing."
                </p>
                <div
                  className="commitflex commitcenteralign"
                  style={{
                    justifyContent: "flex-start",
                    WebkitJustifyContent: "flex-start",
                    flexFlow: "row wrap",
                    WebkitFlexFlow: "row wrap",
                  }}
                >
                  <Avatar
                    size={80}
                    style={{ marginRight: "32px" }}
                    src={"/GregWriede.jpg"}
                  />
                  <p style={{ fontWeight: 700 }}>
                    Greg Wriede,{" "}
                    <span style={{ color: blue[500] }}>Peddie</span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="commitflex commitcenteralign"
              style={{
                margin: "32px 0px",
                justifyContent: "center",
                WebkitJustifyContent: "center",
                flexFlow: "row wrap",
                WebkitFlexFlow: "row wrap",
              }}
            >
              <img
                alt="Lone Star"
                style={{ width: "100px", margin: "16px" }}
                src={"/LoneStarLogo.jpg"}
              />
              <div style={{ maxWidth: "400px", marginLeft: "32px" }}>
                <p style={{ color: teal[500], fontSize: "1.4em" }}>
                  "Finally an affordable program that makes logging simple."
                </p>
                <div
                  className="commitflex commitcenteralign"
                  style={{
                    justifyContent: "flex-start",
                    WebkitJustifyContent: "flex-start",
                    flexFlow: "row wrap",
                    WebkitFlexFlow: "row wrap",
                  }}
                >
                  <Avatar
                    size={80}
                    style={{ marginRight: "32px" }}
                    src={"/CoachAdam.jpg"}
                  />
                  <p style={{ fontWeight: 700 }}>
                    Adam Depmore,{" "}
                    <span style={{ color: teal[500] }}>Lone Star Aquatics</span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="commitflex commitcenteralign"
              style={{
                margin: "32px 0px",
                justifyContent: "center",
                WebkitJustifyContent: "center",
                flexFlow: "row wrap",
                WebkitFlexFlow: "row wrap",
              }}
            >
              <img
                alt="EagleSwimTeam"
                style={{ width: "100px", margin: "16px" }}
                src={"/EagleSwimTeamLogo.jpg"}
              />
              <div style={{ maxWidth: "400px", marginLeft: "32px" }}>
                <p style={{ color: orange[500], fontSize: "1.4em" }}>
                  "After using a different workout manager for 16 years, I was
                  amazed how easy it was to switch to Commit."
                </p>
                <div
                  className="commitflex commitcenteralign"
                  style={{
                    justifyContent: "flex-start",
                    WebkitJustifyContent: "flex-start",
                    flexFlow: "row wrap",
                    WebkitFlexFlow: "row wrap",
                  }}
                >
                  <Avatar
                    size={80}
                    style={{ marginRight: "32px" }}
                    src={"/ScottWard.jpg"}
                  />
                  <p style={{ fontWeight: 700 }}>
                    Scott Ward,{" "}
                    <span style={{ color: orange[500] }}>Eagle Swim Team</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(WhatIsCommit)
