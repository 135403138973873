import $ from "jquery"

const downloadCSV = function(data, filename, mime) {
  const blob = new Blob([data], { type: mime || "application/octet-stream" })
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement("a")
    tempLink.style.display = "none"
    tempLink.href = blobURL
    tempLink.setAttribute("download", filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank")
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

export { downloadCSV }

// This is how you find where you are with the cursor when you click in mobile
const getTop = function(lineHeight, cursorPosition, text, fontFamily, fontSize) {
  const relString = text.substr(0, cursorPosition)
  const fakeEl = document.createElement("span")
  fakeEl.innerText = relString
  fakeEl.style.fontSize = fontSize
  fakeEl.style.lineHeight = `${lineHeight}px`
  fakeEl.style.fontFamily = fontFamily
  fakeEl.style.visibility = "hidden"
  document.body.appendChild(fakeEl)
  const height = fakeEl.offsetHeight
  $(fakeEl).remove()
  return height
}

const handleScroll = function(jqEl, offByOne) {
  const lineHeight = parseInt(jqEl.css("line-height"), 10)
  const cursorPosition = jqEl[0].selectionStart
  const textValue = jqEl[0].value
  const fontFamily = jqEl.css("fontFamily")
  const fontSize = jqEl.css("fontSize")

  // invisible top is the distance of textarea hidden at the top of the screen
  const invisibleTop = jqEl.scrollTop()

  // pixels between cursor and top of textarea
  let cursorTop = getTop(lineHeight, cursorPosition, textValue, fontFamily, fontSize)
  // pixels between cursor and visible textarea
  cursorTop -= invisibleTop

  if (jqEl.height() < cursorTop + lineHeight) {
    jqEl.scrollTop(invisibleTop + lineHeight)
  } else if (cursorTop < 0) {
    jqEl.scrollTop(invisibleTop - lineHeight)
  }

  // accommodate for ios mobile safari bug
  if (offByOne) {
    if (jqEl[0].selectionStart > 0) {
      jqEl[0].setSelectionRange(jqEl[0].selectionStart - 1, jqEl[0].selectionStart - 1)
      jqEl[0].setSelectionRange(jqEl[0].selectionStart + 1, jqEl[0].selectionStart + 1)
    } else {
      jqEl[0].setSelectionRange(0, 0)
    }
  }
}

const getTextWidth = function(text, font, canvas) {
  // DWD TODO re-use canvas object for better performance
  const context = canvas.getContext("2d")
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

const getCaretPosition = function(x, y, jqEl) {
  // get attributes of textbox for shadow elements
  const fontFamily = jqEl.css("fontFamily")
  const fontSize = jqEl.css("fontSize")
  const lineHeight = parseInt(jqEl.css("line-height"), 10)

  // get where the user touched in terms of rounded up to the nearest lineheight (ie 0-20 would returnn 20 = 1st line)
  y = Math.ceil((y + 1) / lineHeight) * lineHeight

  // split text into array of lines by line break
  const lines = $(jqEl)[0].value.split(/\r*\n/)

  // initialize variables we need
  let totalHeight = 0 // height we will accumulate until we reach "y" (Where the user touched)
  let charCount = 0 // characters we will accumulate until we reach  the "x" and "y" (Where the user touched)
  let foundIt = false // indicates when we can "exit" foreach below (once we find where the user touched in terms of characters)

  // create fake element with the same styles as the element touched has and append to body
  const fakeEl = document.createElement("span")
  const fakeElDiv = document.createElement("div")
  fakeEl.style.fontSize = fontSize
  fakeEl.style.lineHeight = `${lineHeight}px`
  fakeEl.style.fontFamily = fontFamily
  fakeEl.style.visibility = "hidden"
  fakeEl.style.webkitNbspMode = "space"
  fakeElDiv.style.paddingLeft = "17px"
  fakeElDiv.style.paddingRight = "17px"
  fakeElDiv.style.visibility = "hidden"
  document.body.appendChild(fakeElDiv)
  fakeElDiv.appendChild(fakeEl)

  // start with a reasonable character width estimate for iphones
  let widthEst
  // iphone 5
  if (jqEl.width() < 350) {
    widthEst = 30 // estimate of characters
  } else if (jqEl.width() < 400) {
    // iphone 6
    widthEst = 35 // estimate of characters
  } else {
    // 6plus
    widthEst = 40 // estimate of characters
  }

  // loop over each line
  lines.forEach((line) => {
    // only go through logic if we haven't figured it out yet
    if (!foundIt) {
      // get height of this line and add it to the running total height
      let height

      // if the # of chars in line are low enough, fair to assume that the height is 1 lineheight
      if (line.length <= widthEst - 10) {
        height = lineHeight
      } else {
        // otherwise need to create a fake element and get the height of it.
        // Note: for whatever reason the height has 2px added on it. Code below handles cases where there are <= 20 lines of text on this "line"
        // let text = ''
        // for (i = 0; i < line.length; i++){
        //   if (line[i] === ' ')
        //     text += '&nbsp'
        //   else
        //     text += line[i]
        // }

        fakeEl.innerHTML = line.replace(/ /g, "\u00a0") // text//line.replace(/ /g, '\u00a0');
        height = fakeEl.offsetHeight
        const remainder = height % lineHeight
        height -= remainder
        if (height > lineHeight * 10) height -= lineHeight
      }
      totalHeight += height

      // if the total height after this line is < where the user touched add all the chars (and line break) in this line to the charCount
      if (totalHeight < y) {
        charCount += line.length + 1
        // otherwise we need to figure out if this line is "multiline" and also where on the x axis the user touched
      } else {
        // this is text from beginning of line that user clicked on till end of the real line (used to figure out "x")
        let actualLine

        if (height <= lineHeight) {
          // the line that we care about for "x" is the whole line
          actualLine = line
        } else {
          // if this line is more than 1 lineheight we need to find the string where the line begins so we can:
          // 1. add the previous chars to the total count,
          // 2. use the remaining string to figure out the "x"

          const begOfLine = totalHeight - height
          const clickFromTop = y - begOfLine // where user clicked from top of this line (y of this line)
          const numLines = Math.floor((clickFromTop - 1) / lineHeight)
          let tempCharCount = numLines * widthEst
          const correctHeight = lineHeight * (numLines + 1) // add 1 because numLines is indexed at 0
          let direction = 0

          while (tempCharCount > 0 && tempCharCount < line.length) {
            fakeEl.innerHTML = line.replace(/ /g, "\u00a0").slice(0, tempCharCount) // text//line.replace(/ /g, '\u00a0').slice(0,tempCharCount);
            height = fakeEl.offsetHeight
            const remainder = height % lineHeight
            height -= remainder
            if (height > lineHeight * 10) height -= lineHeight
            if (height < correctHeight) {
              tempCharCount += 1
              if (direction === 1) break
              direction = -1
            } else if (height > correctHeight) {
              tempCharCount -= 1
              if (direction === -1) break
              direction = 1
            } else {
              // if the character that pushed us over the edge was not a space we have to go back to the first character of that word
              if (line[tempCharCount - 1] !== " ") {
                let newChar
                while (tempCharCount >= 0) {
                  newChar = line[tempCharCount - 1]
                  if (newChar === " ") break
                  tempCharCount -= 1
                }
              }
              break
            }
          }
          charCount += tempCharCount

          actualLine = line.slice(tempCharCount)
          // now we need to find when the chars push us over the limit for the right heigh
          let text = ""
          tempCharCount = actualLine.length
          for (let i = 0, len = actualLine.length; i < len; i++) {
            // if (actualLine[i] === ' ')
            //   text += '&nbsp'
            // else
            text += actualLine[i]

            text = text.replace(/ /g, "\u00a0")
            fakeEl.innerHTML = text
            height = fakeEl.offsetHeight
            // first time we cross over onto 2nd line
            if (height / lineHeight > 2) {
              // if the character that pushed us over the edge was not a space we have to go back to the first character of that word
              if (actualLine[i] !== " ") {
                let newChar
                while (i >= 0) {
                  newChar = actualLine[i]
                  if (newChar === " ") break
                  i -= 1
                }
                tempCharCount = i
              } else tempCharCount = i
              break
            }
          }
          actualLine = actualLine.slice(0, tempCharCount)
        }

        const fonts = fontFamily.split(",")
        const font = `${fontSize} ${fonts[0]}`
        let text = ""

        const canvas = document.createElement("canvas")
        canvas.paddingLeft = "15px"
        canvas.paddingRight = "15px"
        for (let i = 0, len = actualLine.length; i < len; i++) {
          text += actualLine[i]
          if (Math.floor(getTextWidth(text, font, canvas)) + 1 < x) {
            charCount += 1
          } else break
        }

        // we found the chars we need and don't care about the rest of the text in the textbox. foreach doens't have exit so we did this
        foundIt = 1
      }
    }
  })

  fakeEl.remove()
  fakeElDiv.remove()

  return charCount
}

const getRidOfOrSetCursor = function(jqEl) {
  if (jqEl && jqEl.length > 0 && jqEl.is(":focus")) {
    const style = jqEl[0].style
    if (style.textShadow === "") {
      style.textShadow = "rgba(0,0,0,0) 0 0 0"
    } else {
      style.textShadow = ""
    }
  }
}

const getCdnImageUrl = function(imageUrl) {
  return "https://d1cmdgokk4wpmq.cloudfront.net" + imageUrl
}

const isValidEmailRegEx = function(email) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

const isValidEmail = function(addressText, handleResponse) {
  if (addressText.length > 512) {
    handleResponse({
      isValid: false,
      errorMessage: "Oops! That looks like an invalid email address!",
    })
  } else if (addressText.split("@").length - 1 !== 1) {
    handleResponse({
      isValid: false,
      errorMessage: "Oops! That looks like an invalid email address!",
    })
  } else {
    handleResponse({
      isValid: true,
    })
  }
}

export {
  handleScroll,
  getCaretPosition,
  getRidOfOrSetCursor,
  getCdnImageUrl,
  isValidEmail,
  isValidEmailRegEx,
}
