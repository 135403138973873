import PropTypes from "prop-types"
// container that sets context for theme and mobile (screen width) and renders global snackbar
import React from "react"
import { withRouter } from "react-router"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import theme from "../CommitTheme.js"
import Snackbar from "@material-ui/core/Snackbar"
import $ from "jquery"
import { Switch, Route } from "react-router-dom"
import ScrollToTop from "../ScrollToTop"
// non auth components

import WhatIsCommit from "../WhatIsCommit.js"
import Demo from "../Demo"
import DemoIframe from "../DemoIFrame"
import SharedWorkout from "../SharedWorkout"
import SharedWorkoutIframe from "../SharedWorkoutIframe"

class OutsideContext extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    offline: PropTypes.bool,
  }

  static childContextTypes = {
    isTouch: PropTypes.bool,
    isAndroid: PropTypes.bool,
    isIos: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const threshold =
      props.location && props.location.pathname.includes("/app") ? 800 : 768
    this.state = { isMobile: $(window).width() < threshold }
  }

  getIsAndroid = () => navigator.userAgent.toLowerCase().indexOf("android") > -1

  getIsIos = () => !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)

  getIsTouch = () => this.getIsIos() || this.getIsAndroid()

  getChildContext() {
    return {
      isTouch: this.getIsTouch(),
      isAndroid: this.getIsAndroid(),
      isIos: this.getIsIos(),
    }
  }

  handleResize = () => {
    this.setState({ isMobile: $(window).width() < 768 })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }
  closeSnackbar = () => {
    const { location } = this.props
    if (location) {
      let { pathname, query, state } = location
      state = {
        snackbarOpen: false,
        snackbarMessage: "",
        snackbarAction: null,
        snackbarActionString: null,
      }
      this.props.history.replace({ pathname, query, state })
    }
  }

  renderSnackbar = () => {
    // console.log(Snackbar)
    if (this.props.offline) {
      return (
        <Snackbar
          open
          message="Offline."
          onClose={() => false}
          style={{ textAlign: "center" }}
        />
      )
    }
    const state = this.props.location ? this.props.location.state : undefined
    const open = state ? state.snackbarOpen : false
    const message = state ? state.snackbarMessage : ""
    const action = state ? state.snackbarActionString : null
    const onActionClick = state ? state.snackbarAction : null

    return (
      <Snackbar
        style={action ? {} : { textAlign: "center" }}
        open={open || false}
        message={message || ""}
        autoHideDuration={4000}
        onClose={this.closeSnackbar}
        action={
          action ? (
            <Button color="secondary" size="small" onClick={onActionClick}>
              {action}
            </Button>
          ) : null
        }
      />
    )
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        {[
          <ScrollToTop key="scrolltop">
            <Switch>
              <Route
                path="/what-is-commit-swimming"
                render={(props) => {
                  return <WhatIsCommit {...props} {...this.state} />
                }}
              />
              <Route
                path="/demo"
                render={(props) => {
                  return <Demo {...props} {...this.state} />
                }}
              />
              <Route
                path="/demo-iframe"
                render={(props) => {
                  return <DemoIframe {...props} {...this.state} />
                }}
              />
              <Route
                path="/share/:workoutId"
                render={(props) => {
                  return <SharedWorkout {...props} {...this.state} />
                }}
              />
              <Route
                path="/share-iframe/:workoutId"
                render={(props) => {
                  return <SharedWorkoutIframe {...props} {...this.state} />
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  return <Demo {...props} {...this.state} />
                }}
              />
            </Switch>
          </ScrollToTop>,
          <div key="snackbar">{this.renderSnackbar()}</div>,
        ]}
      </MuiThemeProvider>
    )
  }
}

export default withRouter(OutsideContext)
