import React from "react"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import "react-sharingbuttons/dist/main.css"
import { flatten, get } from "lodash"
import Button from "@material-ui/core/Button"
import Skeleton from "react-loading-skeleton"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import { generateRecursiveSetList } from "./parser/parsingHelpers.js"
import { Parser } from "./parser/lib"
import CommitTextBoxFormatted from "./CommitTextBoxDemoFormatted"
import { CommitTextBox } from "./CommitTextBoxDemo"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DeviceAccessTime from "@material-ui/icons/AccessTime"
import ActionSwapHoriz from "@material-ui/icons/SwapHoriz"
import moment from "moment"
import StatsTimer from "./StatsTimer.js"
import DistanceList from "./DistanceList"
import ListSubheader from "@material-ui/core/ListSubheader"
import { purple, amber, lightBlue, red } from "@material-ui/core/colors"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  ctaButton: {
    backgroundColor: "#fb8c00",
    color: "white",
    "&:hover": {
      backgroundColor: "#ef6c00",
    },
  },

  fab: {
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
  },
}

class SharedWorkoutIframe extends React.Component {
  state = {
    loading: true,
    open: false,
    hidden: false,
    allLaps: [],
    showTooltip: false,
    tooltipShown: false,
    currentSet: { text: "", innerDescriptions: [] },
  }
  componentDidMount() {
    const workoutId = get(this.props, "match.params.workoutId")
    if (workoutId) {
      fetch(
        `https://workouts.commitswimming.com/api/v1/sharedWorkout?workoutId=${workoutId}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            this.setState({
              loading: false,
              error: true,
            })
          } else {
            this.setState({
              loading: false,
              showTooltip: true,
              data,
              allLaps: flatten(
                generateRecursiveSetList(data.text, 0).map((el) =>
                  Parser.recursiveLapifyFromOuterSet({
                    setDescription: el,
                    userDefined: data.customParserDict,
                  })
                )
              ),
            })
            setTimeout(
              () => this.setState({ showTooltip: false, tooltipShown: true }),
              80000
            )
          }
        })
    } else {
      this.setState({ loading: false, error: true })
    }
  }
  handleClick = () => {
    this.setState((state) => ({
      open: !state.open,
    }))
  }

  handleDirectionChange = (event, value) => {
    this.setState({
      direction: value,
    })
  }

  handleHiddenChange = (event, hidden) => {
    this.setState((state) => ({
      hidden,
      // hidden implies !open
      open: hidden ? false : state.open,
    }))
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }
  setCurrentSet = (text, innerDescriptions) => {
    const newObj = { text, innerDescriptions }
    this.setState({ currentSet: newObj })
  }

  timeForGroup = (groupId) => {
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )

    return laps.map((el) => el.seconds).reduce((a, b) => a + b, 0)
  }

  displayTime = (groupId) => {
    const seconds = this.timeForGroup(groupId)
    const momentSeconds = moment().startOf("day").seconds(seconds)
    const hours = momentSeconds.hours()
    const minutes = momentSeconds.minutes()
    if (hours === 0) return `${minutes} min`
    return moment().startOf("day").seconds(seconds).format("H:mm")
  }

  getDistanceGivenType = (type, groupId) => {
    const groupedCount = {}
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      if (lap[type]) {
        const typeName = lap[type].name
        const distance = lap.distance
        if (typeName in groupedCount) groupedCount[typeName] += distance
        else groupedCount[typeName] = distance
      }
    })
    const groupedCorrect = []
    for (const name of Object.keys(groupedCount))
      groupedCorrect.push({ name, distance: groupedCount[name] })

    return groupedCorrect
  }

  getDistanceGivenIntensity = (groupId) => {
    const groupedCount = {}
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      if (lap.intensity) {
        let typeName = lap.intensity.name
        if (["1", "2", "3", "4", "5"].includes(typeName)) {
          const intensityLevel = this.props.intensityLevels.find(
            (el) => el._id === lap.intensity._id
          )
          if (intensityLevel) typeName = intensityLevel.name
        }
        const distance = lap.distance
        if (typeName in groupedCount) groupedCount[typeName] += distance
        else groupedCount[typeName] = distance
      }
    })
    const groupedCorrect = []
    for (const name of Object.keys(groupedCount))
      groupedCorrect.push({ name, distance: groupedCount[name] })

    return groupedCorrect
  }

  distanceForGroup = (groupId) => {
    // let myLaps = this.filterGroups();
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    const totalDistance = laps
      .map((el) => el.distance)
      .reduce((a, b) => a + b, 0)
    return totalDistance.toLocaleString()
  }

  getDistanceForEquipment = (groupId) => {
    const distanceList = []

    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      const equipmentsList = lap.equipments
      if (equipmentsList) {
        lap.equipments.forEach((equipObj) => {
          const thisElement = distanceList.find((el) => el._id === equipObj._id)
          if (thisElement) thisElement.distance += lap.distance
          else {
            const newObj = {
              _id: equipObj._id,
              name: equipObj.name,
              distance: lap.distance,
            }
            distanceList.push(newObj)
          }
        })
      }
    })
    for (let i = 0; i < distanceList.length; i++) delete distanceList[i]._id

    return distanceList
  }

  thisWorkoutUsesQuill() {
    return get(this.state, "data.usesQuill")
  }

  thisWorkoutQuillDelta() {
    return get(this.state, "data.quillDelta")
  }

  setAllText = (value) => {
    const setList = generateRecursiveSetList(value, 0)
    this.setState({
      allLaps: flatten(
        setList.map((el) =>
          Parser.recursiveLapifyFromOuterSet({
            setDescription: el,
            userDefined: this.state.data.customParserDict,
          })
        )
      ),
    })
  }

  renderWorkoutInfo() {
    const teamName = get(this.state, "data.team.name")
    const readableDate = this.state.data
      ? moment(this.state.data.dateTime).format("MMM DD, YYYY")
      : null
    if (teamName && readableDate) {
      return (
        <Typography
          variant="body2"
          color={"textSecondary"}
          style={{ margin: "0px", fontStyle: "italic" }}
        >{`Originally written for ${teamName} on ${readableDate}`}</Typography>
      )
    } else {
      return (
        <Typography
          variant="body2"
          style={{ margin: "0px", fontStyle: "italic" }}
        >
          <Skeleton width={280} />
        </Typography>
      )
    }
  }

  renderCommitTextBox() {
    if (this.thisWorkoutUsesQuill()) {
      return (
        <CommitTextBoxFormatted
          listenForWorkoutTextChange
          setCurrentSet={this.setCurrentSet}
          setAllText={this.setAllText}
          style={{
            WebkitUserSelect: "text",
            userSelect: "text",
            MozUserSelect: "text",
            MsUserSelect: "text",
            WebkitOverflowScrolling: "touch",
            overflow: "auto",
            height:
              typeof window.innerWidth == "number"
                ? window.innerHeight - 380
                : 360,
            color: "black",
            width: "100%",
            margin: "0px",
            verticalAlign: "bottom",
            padding: this.props.isMobile ? "15px" : "4px 16px 16px 16px",
            backgroundColor: "white",
            fontSize: this.props.isMobile ? "18px" : "16px",
            lineHeight: this.props.isMobile ? "20px" : "24px",
            fontFamily: "sans-serif",

            border: "none",
            resize: "none",
            outline: "none",
            WebkitBoxShadow: "none",
            MozBoxShadow: "none",
            boxShadow: "none",
            boxSizing: "border-box",
            whiteSpace: "pre-wrap",
          }}
          autoFocus={true}
          addGroupAutoComplete={true}
          startingText={this.state.data.text || ""}
          startingQuillDelta={this.thisWorkoutQuillDelta()}
          groupsArray={this.state.data.groups}
          placeholder="Write your sets here... Just type!"
        />
      )
    }
    return (
      <CommitTextBox
        addGroupAutoComplete={true}
        style={{
          WebkitUserSelect: "text",
          userSelect: "text",
          MozUserSelect: "text",
          MsUserSelect: "text",
          borderRadius: "8px",
          WebkitOverflowScrolling: "touch",
          overflow: "auto",
          height:
            typeof window.innerWidth == "number"
              ? window.innerHeight - 336
              : 360,
          color: "black",
          width: "100%",
          margin: "0px",
          verticalAlign: "bottom",
          padding: "16px",
          backgroundColor: "white",
          fontSize: "16px",
          lineHeight: "20px",
          fontFamily: "sans-serif",
          border: "none",
          resize: "none",
          outline: "none",
          WebkitBoxShadow: "none",
          MozBoxShadow: "none",
          boxShadow: "none",
          boxSizing: "border-box",
          whiteSpace: "pre-wrap",
        }}
        autoCapitalize="off"
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        listenForWorkoutTextChange={true}
        setAllText={this.setAllText}
        setAllTextContinuously
        setCurrentSet={this.setCurrentSet}
        startingValue={this.state.data.text || ""}
        placeholder="Write your sets here... Just type!"
        groupsArray={this.state.data.groups}
      />
    )
  }

  render() {
    let nameWidth = "25%"
    let iconWidths = "75%"
    return (
      <div
        style={{
          minHeight: "calc(100vh - 8px)",
          paddingTop: "8px",
          background: "white",
        }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "auto", paddingBottom: "16px" }}
        >
          <div
            className="commitflex"
            style={{
              justifyContent: "space-around",
              WebkitJustifyContent: "space-around",
              flexFlow: "row wrap",
              WebkitFlexFlow: "row wrap",
              padding: "16px 8px",
            }}
          >
            <div style={{ maxWidth: "650px", width: "100%" }}>
              <div
                className="commitflex commitcenteralign"
                style={{
                  height: "16px",
                  padding: "8px",
                  paddingBottom: "0px",
                }}
              >
                {this.renderWorkoutInfo()}
              </div>
              <Paper
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  marginTop: "8px",
                  height:
                    typeof window.innerWidth == "number"
                      ? window.innerHeight - 236
                      : 400,
                  WebkitOverflowScrolling: "touch",
                  overflow: this.context.isTouch ? "scroll" : "auto",
                }}
              >
                {this.state.loading ? (
                  <div style={{ padding: "16px", lineHeight: "1.5em" }}>
                    <Skeleton width={"100%"} count={1} />
                    <br />
                    <Skeleton width={"60%"} count={2} />
                    <br />
                    <Skeleton width={"20%"} count={1} />
                    <br />
                    <Skeleton width={"55%"} count={2} />
                    <br />
                    <Skeleton width={"70%"} count={4} />
                    <br />
                    <Skeleton width={"100%"} count={1} />
                    <br />
                    <Skeleton width={"30%"} count={1} />
                    <br />
                    <Skeleton width={"65%"} count={3} />
                    <br />
                    <Skeleton width={"60%"} count={2} />
                  </div>
                ) : this.state.error ? (
                  <div style={{ padding: "16px", textAlign: "center" }}>
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      Woops! We are sorry. The creator of this workout removed
                      public sharing from this workout.
                    </Typography>
                    <br />
                    <Typography
                      key="instructions"
                      variant="body1"
                      style={{ textAlign: "left" }}
                    >
                      Click the button below to look at some example sets
                      written in Commit and test drive it for yourself.
                    </Typography>
                    <br />
                    <br />
                    <Button
                      variant="outlined"
                      color="secondary"
                      component={RouterLink}
                      to="/demo"
                    >
                      View a live demo of Commit
                      <ArrowForward style={{ marginLeft: "4px" }} />
                    </Button>
                  </div>
                ) : (
                  [
                    <div key="textbox">{this.renderCommitTextBox()}</div>,
                    <div style={{ padding: "0px 16px" }} key="setstats">
                      <StatsTimer
                        setText={this.state.currentSet}
                        isLanding
                        isLandingDesign
                        style={{
                          minHeight: this.props.isMobile ? "80px" : "100px",
                          fontWeight: 500,
                          fontSize: "13px",
                        }}
                        waitTime={150}
                        genericParserDict={this.state.data.customParserDict}
                        customParserDict={{}}
                        isMobile={this.props.isMobile}
                      />
                    </div>,
                  ]
                )}
              </Paper>
            </div>
            <div style={{ maxWidth: "450px", width: "100%", marginTop: "8px" }}>
              <div style={{ marginTop: "16px" }}>
                {this.state.loading ? (
                  <ExpansionPanel style={{ borderRadius: "8px" }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div
                        className="commitflex commitcenteralign"
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            width: nameWidth,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Skeleton />
                        </div>
                        <div
                          className="commitflex commitcenteralign"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            width: iconWidths,
                          }}
                        >
                          <div className="commitflex commitcenteralign">
                            <ActionSwapHoriz
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                          </div>
                          <div className="commitflex commitcenteralign">
                            <DeviceAccessTime
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ width: "100%" }}>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Strokes
                            </ListSubheader>
                            <DistanceList
                              distanceList={[]}
                              style={{ color: purple[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Types
                            </ListSubheader>
                            <DistanceList
                              distanceList={[]}
                              style={{ color: amber[800] }}
                            />
                          </div>
                        </div>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Intensity
                            </ListSubheader>
                            <DistanceList
                              distanceList={[]}
                              style={{ color: lightBlue[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Equipment
                            </ListSubheader>
                            <DistanceList
                              distanceList={[]}
                              style={{ color: red[800] }}
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : (
                  this.state.data &&
                  this.state.data.groups.map((group) => (
                    <ExpansionPanel
                      key={group._id}
                      style={{ borderRadius: "8px" }}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div
                          className="commitflex commitcenteralign"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              width: nameWidth,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {group.name}
                          </div>
                          <div
                            className="commitflex commitcenteralign"
                            style={{
                              justifyContent: "space-around",
                              WebkitJustifyContent: "space-around",
                              width: iconWidths,
                            }}
                          >
                            <div className="commitflex commitcenteralign">
                              <ActionSwapHoriz
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {this.distanceForGroup(group._id)}
                            </div>
                            <div className="commitflex commitcenteralign">
                              <DeviceAccessTime
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {this.displayTime(group._id)}
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <div
                            className="commitflex"
                            style={{
                              justifyContent: "space-around",
                              WebkitJustifyContent: "space-around",
                              padding: "8px 0px 8px 0px",
                            }}
                          >
                            <div style={{ width: "45%" }}>
                              <ListSubheader
                                style={{
                                  paddingLeft: "0px",
                                  fontSize: "12px",
                                  lineHeight: "2em",
                                }}
                              >
                                Strokes
                              </ListSubheader>
                              <DistanceList
                                distanceList={this.getDistanceGivenType(
                                  "stroke",
                                  group._id
                                )}
                                style={{ color: purple[800] }}
                              />
                            </div>
                            <div style={{ width: "45%" }}>
                              <ListSubheader
                                style={{
                                  paddingLeft: "0px",
                                  fontSize: "12px",
                                  lineHeight: "2em",
                                }}
                              >
                                Types
                              </ListSubheader>
                              <DistanceList
                                distanceList={this.getDistanceGivenType(
                                  "type",
                                  group._id
                                )}
                                style={{ color: amber[800] }}
                              />
                            </div>
                          </div>
                          <div
                            className="commitflex"
                            style={{
                              justifyContent: "space-around",
                              WebkitJustifyContent: "space-around",
                              padding: "8px 0px 8px 0px",
                            }}
                          >
                            <div style={{ width: "45%" }}>
                              <ListSubheader
                                style={{
                                  paddingLeft: "0px",
                                  fontSize: "12px",
                                  lineHeight: "2em",
                                }}
                              >
                                Intensity
                              </ListSubheader>
                              <DistanceList
                                distanceList={this.getDistanceGivenIntensity(
                                  group._id
                                )}
                                style={{ color: lightBlue[800] }}
                              />
                            </div>
                            <div style={{ width: "45%" }}>
                              <ListSubheader
                                style={{
                                  paddingLeft: "0px",
                                  fontSize: "12px",
                                  lineHeight: "2em",
                                }}
                              >
                                Equipment
                              </ListSubheader>
                              <DistanceList
                                distanceList={this.getDistanceForEquipment(
                                  group._id
                                )}
                                style={{ color: red[800] }}
                              />
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(SharedWorkoutIframe))
