
import { extend, flatten, sampleSize, omit } from 'lodash'
// ////////////////////////////////////////////////////

import EJSON from "ejson"

const acronyms = {
  skids: [
    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "im",
      type: "stroke",
      type_value: "IM",
      data_type: "str",
    },

    {
      token: "drill",
      type: "type",
      type_value: "Drill",
      data_type: "str",
    },
  ],
  skips: [
    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "im",
      type: "stroke",
      type_value: "IM",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },
  ],
  skps: [
    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },
  ],
  skp: [
    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },
  ],
  skpd: [
    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },

    {
      token: "drill",
      type: "type",
      type_value: "Drill",
      data_type: "str",
    },
  ],
  dkps: [
    {
      token: "drill",
      type: "type",
      type_value: "Drill",
      data_type: "str",
    },

    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },
  ],
  kps: [
    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },

    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },

    {
      token: "swim",
      type: "type",
      type_value: "Swim",
      data_type: "str",
    },
  ],
  pull: [
    {
      token: "pull",
      type: "type",
      type_value: "Pull",
      data_type: "str",
    },

    {
      token: "buoy",
      type: "equipment",
      type_value: "Buoys",
      data_type: "str",
    },
  ],
  kick: [
    {
      token: "kick",
      type: "type",
      type_value: "Kick",
      data_type: "str",
    },
  ],
}

const modifiers = {
  evens: "evens",
  even: "evens",
  e: "evens",
  odds: "odds",
  odd: "odds",
  o: "odds",
  no: "no",
  without: "no",
}
// Example User Defined Keyword mappings
const userDefinedKeys = {
  types: [
    {
      _id: "KickPull",
      name: "KickPull",
      allForms: ["kickpull"],
    },
  ],
  strokes: [
    {
      _id: "FreeIM",
      name: "FreeIM",
      allForms: ["FreeIM", "fim"],
    },
  ],
  intensities: [
    {
      _id: "Warm Up",
      name: "Warm up",
      intensity: "1",
      allForms: ["warm up"],
    },
    {
      _id: "Warm Down",
      name: "Warm Down",
      intensity: "1",
      allForms: ["warm down"],
    },
    {
      _id: "Race",
      name: "race",
      intensity: "5",
      allForms: ["race"],
    },
  ],
  equipment: [
    {
      _id: "Chutes",
      name: "Chutes",
      allForms: ["chutes"],
    },
  ],

  groups: [
    {
      _id: "Mid-distance",
      name: "Mid-distance",
      allForms: ["#mid-distance"],
    },
    {
      _id: "Sprint",
      name: "Sprint",
      allForms: ["#sprint"],
    },
    {
      _id: "Distance",
      name: "Distance",
      allForms: ["#distance"],
    },
  ],
}

const diffPredefined = {
  strokes: [
    {
      _id: "Back",
      name: "Back",
      abbr: "BK",
      allForms: ["back", "bk", "backstroke"],
    },
    {
      _id: "Free",
      name: "Free",
      abbr: "FR",
      allForms: ["free", "fr", "freestyle", "fs"],
    },
    {
      _id: "Fly",
      name: "Fly",
      abbr: "FL",
      allForms: ["fly", "fl", "butterfly"],
    },
    {
      _id: "Breast",
      name: "Breast",
      abbr: "BR",
      allForms: ["breast", "br", "breaststroke", "brst"],
    },
    {
      _id: "IM",
      name: "IM",
      abbr: "IM",
      allForms: ["im", "imo"],
    },
    {
      _id: "Stroke",
      name: "Stroke",
      abbr: "ST",
      allForms: ["stroke", "st", "str"],
    },
    {
      _id: "MixStroke",
      name: "Mix",
      abbr: "MX",
      allForms: [],
    },
    {
      _id: "Choice",
      name: "Choice",
      abbr: "CH",
      allForms: ["choice", "ch"],
    },
    {
      _id: "Worst",
      name: "Worst",
      abbr: "WRS",
      allForms: ["worst", "wrs"],
    },
  ],
  types: [
    {
      _id: "Swim",
      name: "Swim",
      abbr: "S",
      allForms: ["swim", "s", "sw"],
    },
    {
      _id: "Kick",
      name: "Kick",
      abbr: "K",
      allForms: [
        "kick", // clashes with acronyms.
        "k",
        "kk",
      ],
    },
    {
      _id: "Pull",
      name: "Pull",
      abbr: "P",
      allForms: [
        "pull", // clashes with acronyms
        "p",
        "pp",
      ],
    },
    {
      _id: "Drill",
      name: "Drill",
      abbr: "DR",
      allForms: ["drill", "dr", "d"],
    },
    {
      _id: "MixType",
      name: "Mix",
      abbr: "MX",
      allForms: [],
    },
  ],
  intensities: [
    {
      _id: "Easy",
      name: "Easy",
      intensity: 1,
      abbr: "EZ",
      allForms: ["easy", "ez"],
    },
    {
      _id: "Descending",
      name: "Descending",
      intensity: 3,
      abbr: "Desc",
      allForms: ["descending", "descend", "desc"],
    },
    {
      _id: "Ascending",
      name: "Ascending",
      intensity: 3,
      abbr: "Asc",
      allForms: ["asc", "ascend", "ascending"],
    },
    {
      _id: "Moderate",
      name: "Moderate",
      intensity: 2,
      abbr: "MOD",
      allForms: ["moderate", "mod"],
    },
    {
      _id: "Build",
      name: "Build",
      intensity: 3,
      abbr: "BLD",
      allForms: ["build", "bld"],
    },
    {
      _id: "Fast",
      name: "Fast",
      intensity: 4,
      abbr: "FST",
      allForms: ["fast", "fst"],
    },
    {
      _id: "Sprint",
      name: "Sprint",
      intensity: 5,
      abbr: "SPR",
      allForms: ["sprint", "spr"],
    },
    {
      _id: "MixIntensity",
      name: "Mix",
      intensity: 0, // evenything must have intensity DC_TODO should it be 0 though?
      abbr: "MX",
      allForms: [],
    },
  ],
  equipment: [
    {
      _id: "Boards",
      name: "Boards",
      abbr: "board",
      allForms: ["board", "boards"],
    },
    {
      _id: "Paddles",
      name: "Paddles",
      abbr: "paddle",
      allForms: ["paddles", "paddle"],
    },
    {
      _id: "Fins",
      name: "Fins",
      abbr: "fin",
      allForms: ["fins", "fin", "flippers", "flipper"],
    },
    {
      _id: "Snorkels",
      name: "Snorkels",
      abbr: "snorkel",
      allForms: ["snorkels", "snorkel"],
    },
    {
      _id: "Zoomers",
      name: "Zoomers",
      abbr: "zoomer",
      allForms: ["zoomers", "zoomer"],
    },
    {
      _id: "Shoes",
      name: "Shoes",
      abbr: "shoe",
      allForms: ["shoes", "shoe", "sneakers", "sneaker"],
    },
    {
      _id: "Shirts",
      name: "Shirts",
      abbr: "shirt",
      allForms: ["shirts", "shirt", "tshirts", "tshirt"],
    },
    {
      _id: "Tubes",
      name: "Tubes",
      abbr: "tube",
      allForms: ["tubes", "tube"],
    },
    {
      _id: "Buoys",
      name: "Buoys",
      abbr: "buoy",
      allForms: ["buoys", "buoy"],
    },
    {
      _id: "Buckets",
      name: "Buckets",
      abbr: "bucket",
      allForms: ["buckets", "bucket"],
    },
    {
      _id: "MixEquipment",
      name: "Mix",
      abbr: "MX",
      allForms: [],
    },
  ],
}

// const LapObject = new SimpleSchema({
//   _id: {
//     type: String,
//   },
//   name: {
//     type: String,
//   },
// })
//
// const LapSchema = new SimpleSchema({
//   distance: {
//     type: Number,
//     decimal: true,
//     min: 0,
//   },
//   seconds: {
//     type: Number,
//     decimal: true,
//     min: 0,
//   },
//   "distanceSet.distance": {
//     type: Number,
//     decimal: true,
//     optional: true,
//   },
//   "interval.seconds": {
//     type: Number,
//     decimal: true,
//     optional: true,
//   },
//   "interval.default": {
//     type: Boolean,
//     optional: true,
//   },
//   "rest.seconds": {
//     type: Number,
//     decimal: true,
//     optional: true,
//   },
//   "hold.seconds": {
//     type: Number,
//     decimal: true,
//     optional: true,
//   },
//   type: {
//     type: LapObject,
//     optional: true,
//   },
//   stroke: {
//     type: LapObject,
//     optional: true,
//   },
//   intensity: {
//     type: LapObject,
//     optional: true,
//   },
//   equipment: {
//     type: LapObject,
//     optional: true,
//   },
//   equipments: {
//     optional: true,
//     type: [LapObject],
//   },
//   groups: {
//     optional: true,
//     type: [LapObject],
//     custom() {
//       for (let i = 0; i < this.value.length; i++) {
//         const val = this.value[i]
//         if (val === null || val === undefined)
//           return "Nulls not allowed in array"
//       }
//     },
//   },
// })

// Might need to change if Parser.makeGhostLap() changes
const defaultGhostLap = {
  distance: null,
  seconds: null,
  distanceSet: { distance: null },
  interval: { seconds: null },
  rest: { seconds: null },
  hold: { seconds: null },
  type: null,
  stroke: null,
  intensity: null,
  equipment: null,
  groups: [],
}

const isCharDigit = function (el) {
  return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(el)
}

const isDistanceNumber = function (j) {
  return Number.isInteger(j) && (j % 25 === 0 || j % 20 === 0)
}

const allIndexOf = function (str, toSearch) {
  const indices = []
  for (
    let pos = str.indexOf(toSearch);
    pos !== -1;
    pos = str.indexOf(toSearch, pos + 1)
  )
    indices.push(pos)

  return indices
}

const keyOrder = function (object) {
  const keysList = Object.keys(object)
  keysList.sort((a, b) => b.length - a.length)
  return keysList
}

const pullDigits = function (string) {
  const apart = []
  let parsingNumb = false
  let curString = ""
  let prevIndex = 0

  for (let i = 0; i < string.length; i++) {
    const el = string[i]
    const isDigit = isCharDigit(el)
    if (isDigit && parsingNumb)
      curString += el
    else if (isDigit && !parsingNumb) {
      if (curString.length > 0) {
        const basicInfo = {}
        basicInfo.token = curString
        basicInfo.begin = prevIndex
        basicInfo.end = i
        apart.push(basicInfo)

        prevIndex = i
      }
      curString = el
      parsingNumb = true
    } else if (!isDigit && parsingNumb) {
      if (curString) {
        const basicInfo = {}
        basicInfo.token = parseInt(curString, 10)
        basicInfo.begin = prevIndex
        basicInfo.end = i
        apart.push(basicInfo)
        prevIndex = i
      }
      curString = el
      parsingNumb = false
    } else if (!isDigit && !parsingNumb)
      curString += el
    else
      console.log("Can't happen")
  }

  if (parsingNumb) {
    const basicInfo = {}
    basicInfo.token = parseInt(curString, 10)
    basicInfo.begin = prevIndex
    basicInfo.end = prevIndex + curString.length
    apart.push(basicInfo)
  } else {
    const basicInfo = {}
    basicInfo.token = curString
    basicInfo.begin = prevIndex
    basicInfo.end = prevIndex + curString.length
    apart.push(basicInfo)
  }
  return apart
}

// Assumes that the 2 term interval is already typed correctly
//   Numbers need to be numbers
const is2TermInterval = function (tokenList) {
  if (!tokenList.length === 2)
    return false

  const first = tokenList[0]
  const second = tokenList[1]

  if (
    first === ":" &&
    Number.isInteger(second) &&
    second >= 0 &&
    second <= 180
  )
    return true

  return false
}

const parse2TermInterval = function (tokenList) {
  const token = tokenList.map(el => el.token.toString()).join("")
  const dataType = "time"
  const seconds = tokenList[1].token
  const termToken = {
    token,
    data_type: dataType,
    seconds,
  }
  return termToken
}

const is3TermInterval = function (tokenList) {
  if (!tokenList.length === 3)
    return false

  const first = tokenList[0]
  const second = tokenList[1]
  const third = tokenList[2]
  if (
    Number.isInteger(first) &&
    first >= 0 &&
    first < 60 &&
    second === ":" &&
    Number.isInteger(third) &&
    third >= 0 &&
    third < 180
  )
    return true

  return false
}

// Always call is3TermInterval before calling this
//  function. It doesn't do any checking
const parse3TermInterval = function (tokenList) {
  const token = tokenList.map(el => el.token.toString()).join("")
  const dataType = "time"
  const seconds = tokenList[0].token * 60 + tokenList[2].token
  const termToken = {
    token,
    data_type: dataType,
    seconds,
  }
  return termToken
}

const parseIntAsTime = function (object) {
  let seconds = 0
  if (object.token >= 100)
    seconds = Math.floor(object.token / 100) * 60 + object.token % 100
  else
    seconds = object.token

  object.data_type = "time"
  object.seconds = seconds
}

const readTokenList = function (tokenList, predefined) {
  if (tokenList.length === 0)
    return []


  const first = tokenList[0]
  let second = tokenList[1]
  let third = tokenList[2]

  if (!second) {
    second = {}
    second.token = null
    second.begin = null
    second.end = null
  }

  if (!third) {
    third = {}
    third.token = null
    third.begin = null
    third.end = null
  }

  // 3 term case, example "1:30"
  if (is3TermInterval([first.token, second.token, third.token])) {
    const rest = readTokenList(tokenList.slice(3), predefined)
    return [parse3TermInterval(tokenList.slice(0, 3))].concat(rest)
  }

  if (
    Number.isInteger(first.token) &&
    second.token === "x" &&
    Number.isInteger(third.token)
  ) {
    const rest = readTokenList(tokenList.slice(3), predefined)
    return tokenList
      .slice(0, 3)
      .map(el => addDictTags(el, predefined))
      .concat(rest)
  }

  // 2 term cases
  if (
    Number.isInteger(first.token) &&
    ["'s", "s", "m"].indexOf(second.token) > -1
  ) {
    const rest = readTokenList(tokenList.slice(2), predefined)
    return [
      addDictTags(first, predefined, { possible_distance_number: true }),
    ].concat(rest)
  }

  if (Number.isInteger(first.token) && second.token === "%") {
    const rest = readTokenList(tokenList.slice(2), predefined)
    return [addDictTags(first, predefined, { percentage: true })].concat(rest)
  }

  if (
    Number.isInteger(first.token) &&
    ["th", "st", "nd", "rd"].includes(second.token)
  ) {
    const tagDict = addDictTags(first, predefined)
    const rest = readTokenList(tokenList.slice(2), predefined)
    return [tagDict].concat(rest)
  }

  if (is2TermInterval([first.token, second.token])) {
    const rest = readTokenList(tokenList.slice(2), predefined)
    return [parse2TermInterval([first, second])].concat(rest)
  }

  const rest = readTokenList(tokenList.slice(1), predefined)
  const parseFirstToken = [addDictTags(first, predefined)]
  return parseFirstToken.concat(rest)
}

let addDictTags = function (tokenObject, predefined, optionalTagDict = {}) {
  const tags = optionalTagDict

  if (Number.isInteger(tokenObject.token)) {
    tokenObject.data_type = "int"
    if (isDistanceNumber(tokenObject.token))
      tokenObject.possible_distance_number = true
  } else {
    tokenObject.data_type = "str"
  }["types", "strokes", "intensities", "equipment"].forEach((name) => {
    predefined[name].forEach((obj) => {
      if (obj.allForms.includes(tokenObject.token)) {
        tokenObject._id = obj._id
        tokenObject.type_value = obj.name

        // Handling Type
        if (name === "types") tokenObject.type = "type"
        else if (name === "strokes") tokenObject.type = "stroke"
        else if (name === "intensities") tokenObject.type = "intensity"
        else tokenObject.type = "equipment"

        return extend(tokenObject, tags)
      }
    })
  })

  for (const key in modifiers) {
    if (key === tokenObject.token) {
      tokenObject.type = "modifier"
      tokenObject.modifier_value = modifiers[key]
      return extend(tokenObject, tags)
    }
  }

  if (!tokenObject.type)
    tokenObject.type = null

  return extend(tokenObject, tags)
}

// Returns true if token_one is Reps Range modifier
// or is modified by an Repse Range modifier. False otherwise.
const isRROrModifiedByRR = function (tokenOne) {
  const oneIsRR =
    tokenOne.modifier_value && tokenOne.modifier_value.includes("RR:")
  const oneIsModifiedByRR = tokenOne.modified_by && hasRR(tokenOne.modified_by)
  const oneIsNotModifiedByRR = !tokenOne.modifier_value

  return oneIsRR || (oneIsModifiedByRR && oneIsNotModifiedByRR)
}

const isEvensOrModifiedByEvens = function (tokenOne, tokenTwo) {
  const oneIsEvens = tokenOne.modifier_value === "evens"
  const oneIsModifiedByEvens =
    tokenOne.modified_by && tokenOne.modified_by.includes("evens")
  const twoIsNotOdds = tokenTwo.modifier_value !== "odds"

  return oneIsEvens || (oneIsModifiedByEvens && twoIsNotOdds)
}

const isOddsOrModifiedByOdds = function (tokenOne, tokenTwo) {
  const oneIsOdds = tokenOne.modifier_value === "odds"
  const oneIsModifiedByOdds =
    tokenOne.modified_by && tokenOne.modified_by.includes("odds")
  const twoIsNotEvens = tokenTwo.modifier_value !== "evens"

  return oneIsOdds || (oneIsModifiedByOdds && twoIsNotEvens)
}

const parseModifierPhrase = function (tokenDictionaries, index) {
  const first = tokenDictionaries[index]
  let second = tokenDictionaries[index + 1]
  let third = tokenDictionaries[index + 2]

  let repsNumb = 1
  for (let i = 0; i < tokenDictionaries.length; i++) {
    if (tokenDictionaries[i].type === "reps") {
      repsNumb = tokenDictionaries[i].token
      break
    }
  }

  if (!second) {
    second = {}
    second.token = null
    second.data_type = null
  }
  if (!third) {
    third = {}
    third.token = null
    third.data_type = null
  }

  if (isRROrModifiedByRR(first)) {
    if (
      second.data_type === "int" &&
      second.token <= repsNumb &&
      second.token > 0
    )
      return index + 1

    if (first.modifier_value) {
      second.modified_by = [first.modifier_value]
      second.modifier_phrase_index = index
      return index + 1
    }
    second.modified_by = first.modified_by.slice(0)
    second.modifier_phrase_index = first.modifier_phrase_index
    return index + 1
  }

  // 'no' phrase
  if (first.modifier_value === "no") {
    // Ex. "... without a board..."
    if (second.token === "a" && third.token !== null) {
      if (!third.modified_by && first.modified_by) {
        third.modified_by = first.modified_by.slice(0) // shallow copy of array
        third.modified_by.push("no")
        third.modifier_phrase_index = first.modifier_phrase_index
        return index + 3
      } else if (!third.modified_by && !first.modified_by) {
        third.modified_by = ["no"]
        third.modifier_phrase_index = 0
        return index + 3
      }
    } else {
      // Ex. "... no boards" or "... without boards"
      if (!second.modified_by && first.modified_by) {
        second.modified_by = first.modified_by.slice(0)
        second.modified_by.push("no")
        second.modifier_phrase_index = first.modifier_phrase_index
        return index + 2
      } else if (!second.modified_by && !first.modified_by) {
        second.modified_by = ["no"]
        second.modifier_phrase_index = 0
        return index + 2
      }
    }
  }

  // evens
  if (isEvensOrModifiedByEvens(first, second)) {
    if (!second.modified_by) {
      second.modified_by = ["evens"]
      // attaching phrase index
      for (let i = 0; i < tokenDictionaries.length; i++) {
        if (tokenDictionaries[i].modifier_value === "evens")
          second.modifier_phrase_index = parseInt(i)
      }
      return index + 1
    } console.log("this should never happen")
  }
  // odds
  if (isOddsOrModifiedByOdds(first, second)) {
    if (!second.modified_by) {
      second.modified_by = ["odds"]
      for (let i = 0; i < tokenDictionaries.length; i++) {
        if (tokenDictionaries[i].modifier_value === "odds")
          second.modifier_phrase_index = parseInt(i)
      }
      return index + 1
    } console.log("this should never happen")
  }
  return index + 1 // if first doesn't enter any of the above code
}

const parseIntervalPhrase = function (tokenDictionaries, index) {
  let oneBefore = tokenDictionaries[index - 1]
  const first = tokenDictionaries[index]
  let second = tokenDictionaries[index + 1]
  const minutesWords = ["min", "minute", "minutes"]

  for (let i = 0; i < tokenDictionaries.length; i++) {
    const token = tokenDictionaries[i]
    if (token.type === "distance")
      break
  }

  if (!oneBefore) {
    oneBefore = {}
    oneBefore.token = null
    oneBefore.data_type = null
  }
  if (!second) {
    second = {}
    second.token = null
    second.data_type = null
  }

  // PACE
  if (second.token === "pace") {
    if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "hold"
      return index + 1
    } else if (first.data_type === "time") {
      // Ex. '... 1:30 pace'
      first.type = "hold"
      return index + 1
    }
  }
  // Ex. '... pace 130'
  if (oneBefore.token === "pace") {
    if (
      first.data_type === "int" &&
      minutesWords.includes(second.token)
    ) {
      first.seconds = 60 * first.token
      first.type = "hold"
      return index + 1
    } else if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "hold"
      return index + 1
    } else if (first.data_type === "time") {
      // Ex. '... pace 1:30'
      first.type = "hold"
      return index + 1
    }
  }
  // Ex. '... (holding/hold) 130'
  if (oneBefore.token === "holding" || oneBefore.token === "hold") {
    if (
      first.data_type === "int" &&
      minutesWords.includes(second.token)
    ) {
      first.seconds = 60 * first.token
      first.type = "hold"
      return index + 1
    } else if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "hold"
      return index + 1
    } else if (first.data_type === "time") {
      // Ex. '... (holding/hold) 1:30'
      first.type = "hold"
      return index + 1
    }
  }
  // REST
  if (second.token === "rest" || second.token === "ri") {
    if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "rest"
      return index + 1
    } else if (first.data_type === "time") {
      first.type = "rest"
      return index + 1
    }
  }
  // '... rest (:10/10)'
  if (
    oneBefore.token === "rest" ||
    oneBefore.token === "resting" ||
    oneBefore.token === "ri"
  ) {
    if (
      first.data_type === "int" &&
      minutesWords.includes(second.token)
    ) {
      first.seconds = 60 * first.token
      first.type = "rest"
      return index + 1
    } else if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "rest"
      return index + 1
    } else if (first.data_type === "time") {
      first.type = "rest"
      return index + 1
    }
  }
  // INTERVAL
  if (oneBefore.token === "@") {
    if (
      first.data_type === "int" &&
      minutesWords.includes(second.token)
    ) {
      first.seconds = 60 * first.token
      first.type = "interval"
      return index + 1
    } else if (first.data_type === "int") {
      parseIntAsTime(first)
      first.type = "interval"
      return index + 1
    } else if (first.data_type === "time") {
      first.type = "interval"
      return index + 1
    }
  }
  // IF INT
  if (first.data_type === "int") {
    if (minutesWords.includes(second.token)) {
      first.seconds = 60 * first.token
      first.type = "interval"
      return index + 1
    } else if (oneBefore.token === "on" || oneBefore.token === "the") {
      parseIntAsTime(first)
      first.type = "interval"
      return index + 1
    }
  }
  // IF TIME
  if (first.data_type === "time") {
    first.type = "interval"
    return index + 1
  }
}

// Returns true if tokens fit the criteria for being a singleton rep reference
const isValidSingletonRep = function (tokenOne, tokenTwo, repsNumb) {
  if (!repsNumb) return false
  const oneGreaterThanLessThan = tokenOne.token > 0 && tokenOne.token <= repsNumb
  const oneIsAnInt = tokenOne.data_type === "int"
  const twoIsNotAnInt = tokenTwo.data_type !== "int"
  const twoIsNotTimeWord = !isTimeWordAfter(tokenTwo.token)

  return (
    oneGreaterThanLessThan && oneIsAnInt && twoIsNotAnInt && twoIsNotTimeWord
  )
}

// Returns true if a group of tokens fit the criteria of being a reps range. False otherwise
const isValidRepsRange = function (tokenOne, tokenTwo, tokenThree, repsNumb) {
  if (!repsNumb) return false
  const oneAndThreeAreInts =
    tokenOne.data_type === "int" && tokenThree.data_type === "int"
  const oneGreaterThanLessThan = tokenOne.token > 0 && tokenOne.token <= repsNumb
  const threeGreaterThanLessThan =
    tokenThree.token > tokenOne.token && tokenThree.token <= repsNumb

  return (
    oneAndThreeAreInts &&
    oneGreaterThanLessThan &&
    threeGreaterThanLessThan &&
    tokenTwo.token === "-"
  )
}

// Returns true if an element in list contains the string 'RR'. False otherwise.
let hasRR = function (list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].includes("RR"))
      return true
  }
  return false
}

let isTimeWordAfter = function (string) {
  return ["rest", "ri", "pace", "min"].includes(string)
}

const getDefinedKeywords = function (userDefinedObj) {
  const mappings = []
  for (const myType in userDefinedObj) {
    const list = userDefinedObj[myType]
    for (let i = 0; i < list.length; i++) {
      const obj = list[i]
      // console.log(obj);
      obj.allForms.forEach((thing) => {
        const keywords = [thing, i, myType, obj._id]
        mappings.push(keywords)
      })
    }
  }
  mappings.sort((a, b) => b[0].length - a[0].length)
  return mappings
}

const getAcronymnTokenIndices = function (
  string,
  acronyms,
  userDefined,
  predefined
) {
  const acronymKeys = keyOrder(acronyms) // Possibly clone acronyms
  const definedTokenIndices = []
  acronymKeys.forEach((acr) => {
    const indexOfAcr = allIndexOf(string, acr)
    indexOfAcr.forEach((index) => {
      if (
        (string.charAt(index - 1) === "" || string.charAt(index - 1) === " ") &&
        (string.charAt(index + acr.length) === "" ||
          string.charAt(index + acr.length) === " ")
      ) {
        const infoArr = [index, acr.length]

        const tokenList = []

        const acrTokens = EJSON.clone(acronyms[acr])
        acrTokens.forEach((token) => {
          const newToken = EJSON.clone(token) // it is necessary to clone each obj in acrTokens
          if (newToken.type === "type") {
            const type = predefined.types.find(el =>
              el.allForms.includes(newToken.type_value.toLowerCase()))
            newToken._id = type ? type._id : "YOU-DONE-FUCKED-UP"
          } else {
            const equip = predefined.equipment.find(el =>
              el.allForms.includes(newToken.type_value.toLowerCase()))
            newToken._id = equip ? equip._id : "YOU-DONE-FUCKED-UP"
          }
          newToken.begin = index
          newToken.end = newToken.begin + acr.length
          tokenList.push(newToken)
        })
        infoArr.push(tokenList)

        definedTokenIndices.push(infoArr)
      }
    })
  })
  return definedTokenIndices
}

const getUserDefinedTokenIndices = function (string, userDefined) {
  const definedTokenIndices = []
  const allFormsList = getDefinedKeywords(userDefined)
  allFormsList.forEach((keyList) => {
    const [key, indexOfObj, outerKey] = keyList

    const indexOfKey = allIndexOf(string, key)
    indexOfKey.forEach((index) => {
      if (
        (string.charAt(index - 1) === "" || string.charAt(index - 1) === " ") &&
        (string.charAt(index + key.length) === "" ||
          string.charAt(index + key.length) === " ")
      ) {
        const infoArr = [index, key.length]

        const tokenList = []
        // creating token
        const definedToken = {
          _id: keyList[3],
          begin: index,
          end: index + key.length,
          token: key,
          data_type: "str",
        }

        if (outerKey === "types") {
          definedToken.type = "type"
          definedToken.type_value = userDefined[outerKey][indexOfObj].name
        } else if (outerKey === "intensities") {
          definedToken.type = "intensity"
          definedToken.type_value = userDefined[outerKey][indexOfObj].name
        } else if (outerKey === "strokes") {
          definedToken.type = "stroke"
          definedToken.type_value = userDefined[outerKey][indexOfObj].name
        } else if (outerKey === "equipment") {
          definedToken.type = "equipment"
          definedToken.type_value = userDefined[outerKey][indexOfObj].name
        } else {
          // outerKey = groups
          definedToken.type = "group"
          definedToken.type_value = userDefined[outerKey][indexOfObj].name
        }

        tokenList.push(definedToken)
        infoArr.push(tokenList)

        definedTokenIndices.push(infoArr)
      }
    })
  })
  return definedTokenIndices
}

const removeNoiseCharacters = function (string, noiseCharacterList) {
  const newString = []

  for (let i = 0; i < string.length; i++) {
    const newChar = noiseCharacterList.includes(string[i]) ? " " : string[i]
    newString.push(newChar)
  }
  return newString.join("")
}

const tokenizePhrase = function (string, offset, predefined) {
  let tokenDictionaries = []
  let prevIndex = 0
  let currentToken = 0 // accesses the token that gets loaded onto tokenDictionaries
  for (let i = 0; i <= string.length; i++) {
    const next = string.charAt(i)
    if (next === " " || next === "") {
      const stringCut = string.slice(prevIndex, i).trim()
      if (stringCut !== "") {
        const tokenList = pullDigits(stringCut)
        const newToken = readTokenList(tokenList, predefined)
        // let newToken = label_token(stringCut, predefined);

        for (let el = 0; el < newToken.length; el++) {
          tokenDictionaries.push(newToken[el])
          tokenDictionaries = flatten(tokenDictionaries)

          if (el === 0) {
            tokenDictionaries[currentToken].begin = prevIndex + offset
            tokenDictionaries[currentToken].end =
              tokenDictionaries[currentToken].begin +
              tokenDictionaries[currentToken].token.toString().length
            currentToken++
          } else {
            tokenDictionaries[currentToken].begin =
              tokenDictionaries[currentToken - 1].end + offset
            tokenDictionaries[currentToken].end =
              tokenDictionaries[currentToken].begin +
              tokenDictionaries[currentToken].token.toString().length
            currentToken++
          }
        }
      }
      prevIndex = i + 1
    }
  }
  return tokenDictionaries
}

const removeUnwantedStuff = function (string, tokenDictionaries) {
  let modPhraseInxs = new Set()
  modPhraseInxs.add(0)

  tokenDictionaries.forEach((token) => {
    if (token.modifier_phrase_index)
      modPhraseInxs.add(token.modifier_phrase_index)
  })
  modPhraseInxs = Array.from(modPhraseInxs) // creates Array out of Set
  modPhraseInxs.sort((a, b) => a - b)

  let newTokenDicts = []
  modPhraseInxs.forEach((inx, i) => {
    const first = inx
    const second = modPhraseInxs[i + 1]
    if (!second) {
      // then we are at the last el of mod_phrase_inx
      let miniSet = tokenDictionaries.slice(first)
      const miniString = string.slice(miniSet[0].begin)
      miniSet = remove(miniString, miniSet)
      newTokenDicts = newTokenDicts.concat(miniSet)
    } else {
      let miniSet = tokenDictionaries.slice(first, second)
      const miniString = string.slice(
        miniSet[0].begin,
        miniSet[miniSet.length - 1].end
      )
      miniSet = remove(miniString, miniSet)
      newTokenDicts = newTokenDicts.concat(miniSet)
    }
  })

  return newTokenDicts
}

let remove = function (string, tokenDictionaries) {
  let lowerCaseString = string.toLowerCase()

  // Replace noise characters
  ;[",", "/", ";", ".", "!", "?", "(", ")"].forEach((el) => {
    allIndexOf(lowerCaseString, el).forEach((i) => {
      lowerCaseString = lowerCaseString.replace(lowerCaseString.charAt(i), " ")
    })
  })

  const kickNoBoard =
    lowerCaseString.includes("kick on your side") ||
    lowerCaseString.includes("kick on side") ||
    lowerCaseString.includes("streamline kick")

  const kickNoBoardNoBack =
    lowerCaseString.includes("kick on your back") ||
    lowerCaseString.includes("kick on back")

  let unwantedList = []
  if (kickNoBoard)
    unwantedList = ["Boards"]
  else if (kickNoBoardNoBack)
    unwantedList = ["Boards", "Back"]


  tokenDictionaries.forEach((token) => {
    if (token.modified_by && token.modified_by.includes("no"))
      unwantedList.push(token.type_value)
  })

  const newTokenDicts = []
  tokenDictionaries.forEach((token) => {
    if (!token.type_value)
      newTokenDicts.push(token)
    else if (!unwantedList.includes(token.type_value))
      newTokenDicts.push(token)
  })
  return newTokenDicts
}

// let generateRandomSetObj = function() {
//   let setObj = {
//     userId: "lalalala", // for passing schema test
//     teamId: "done", // for passing schema test
//     workoutId: "lkdjsf", // for passing schema test
//     order: 1.0,
//     description: {
//       _id: "stuff",
//       text: generateRandomSet(),
//       innerDescriptions: []
//     }
//   }
//   let depth = Math.floor(Math.random() * 4) // random num between 0 and 3
//   return random_set_blob(setObj, depth)
// }

// let random_set_blob = function(set_obj, depth) {
//   if (depth === 0) {
//     return set_obj
//   } else {
//     let number_of_sets = Math.floor(Math.random() * 3) // random num between 0 and 2
//     for (let i = 0; i < number_of_sets; i++) {
//       let new_set = {
//         _id: "string",
//         text: generateRandomSet(),
//         innerDescriptions: []
//       }
//       if (set_obj.description) {
//         set_obj.description.innerDescriptions.push(
//           random_set_blob(new_set, depth - 1)
//         )
//       } else {
//         set_obj.innerDescriptions.push(random_set_blob(new_set, depth - 1))
//       }
//     }
//     return set_obj
//   }
// }

const toTitleCase = function (str) {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

const ParserClass = class {
  constructor() {
    this.string = ""
    this.tokenDictionaries = []
    this.reps = null
    this.distance = null
    this.error = false
    this.freeStroke = { _id: "YOU-DONE-FUCKED-UP_FREE", name: "Free" }
    this.swimType = { _id: "YOU-DONE-FUCKED-UP_SWIM", name: "Swim" }
    this.defaultIntensity = null
  }
  reset() {
    this.string = ""
    this.tokenDictionaries = []
    this.reps = null
    this.distance = null
    this.error = false
    this.freeStroke = { _id: "YOU-DONE-FUCKED-UP_FREE", name: "Free" }
    this.swimType = { _id: "YOU-DONE-FUCKED-UP_SWIM", name: "Swim" }
    this.defaultIntensity = null
  }
  generateRandomSet() {
    const setElements = [
      "4",
      "100",
      "25",
      "75",
      "47.5",
      "333",
      "1",
      "2",
      "3",
      "4",
      "x",
      "@",
      "rest",
      "pace",
      "9:99",
      "0:01",
      "1",
      "min",
      "holding",
      "3 x 67.7",
      "ri",
      "hold",
      "resting",
      "buoys",
      "zoomers",
      "tshirts",
      "paddles",
      "free",
      "back",
      "fly",
      "breast",
      "swim",
      "kick",
      "pull",
      "skps",
      "kps",
      "#distance",
      "#sprint",
      "#",
      "##",
      "dkps",
      "skpd",
      "kdp",
      "dr",
      "ez",
      "mod",
      "fst",
      "asc",
      "desc",
      "evens",
      "odds",
      "#mid-distance",
      "no",
      "without",
      "without a",
    ]

    const numb = Math.floor(Math.random() * 10 + 6)
    return sampleSize(setElements, numb).join(" ")
  }

  removeBracketComments(string) {
    const newStringList = []
    let isInsideComment = false
    for (let i = 0; i < string.length; i++) {
      const char = string.charAt(i)

      // There is a reason that I ordered things like this
      if (char === "[" && !isInsideComment)
        isInsideComment = true


      if (!isInsideComment)
        newStringList.push(char)


      if (char === "]" && isInsideComment)
        isInsideComment = false
    }
    return newStringList.join("")
  }

  updateRepsDistance({ reps, distance }) {
    if (!this.reps && reps)
      this.reps = reps

    if (!this.distance && distance)
      this.distance = distance
  }

  parseNextToken(tokenDictionaries, index) {
    const first = tokenDictionaries[index]
    let second = tokenDictionaries[index + 1]
    let third = tokenDictionaries[index + 2]

    if (!second) {
      second = {}
      second.token = null
      second.data_type = null
    }
    if (!third) {
      third = {}
      third.token = null
      third.data_type = null
    }

    // 5 x 100
    if (
      first.data_type === "int" &&
      second.token === "x" &&
      third.data_type === "int"
    ) {
      first.type = "reps"
      third.type = "distance"
      this.updateRepsDistance({
        reps: first.token,
        distance: third.token,
      })
      return index + 3
    }

    // 4 100s
    if (
      first.data_type === "int" &&
      !("possible_distance_number" in first) &&
      "possible_distance_number" in second
    ) {
      first.type = "reps"
      second.type = "distance"
      this.updateRepsDistance({
        reps: first.token,
        distance: second.token,
      })
      return index + 2
    }

    // 100 ez
    if (first.data_type === "int" && "possible_distance_number" in first) {
      first.type = "distance"
      this.updateRepsDistance({ reps: 1, distance: first.token })
      return index + 1
    }

    // Detailing Multiple Reps (Reps Range)
    if (isValidRepsRange(first, second, third, this.reps)) {
      third.type = "modifier"
      third.modifier_value = `RR:${first.token}-${third.token}`
      third.startRep = first.token
      third.endRep = third.token
      const newIndex = parseModifierPhrase(tokenDictionaries, index + 2)
      return newIndex
    }

    // Detailing Single Rep Reference
    // Should probably do this after
    if (isValidSingletonRep(first, second, this.reps)) {
      first.type = "modifier"
      first.modifier_value = `RR:${first.token}`
      first.startRep = first.token
      first.endRep = first.token
      const newIndex = parseModifierPhrase(tokenDictionaries, index)
      return newIndex
    }

    // This chunk deals with time
    if (first.data_type === "time" || first.data_type === "int")
      parseIntervalPhrase(tokenDictionaries, index)
      // return newIndex;


    // This chunk deals with modifiers and modifier syntax
    if (first.type === "modifier") {
      const newIndex = parseModifierPhrase(tokenDictionaries, index)
      return newIndex
    }

    if (
      first.modified_by &&
      (first.modified_by.includes("evens") ||
        first.modified_by.includes("odds") ||
        hasRR(first.modified_by))
    ) {
      const newIndex = parseModifierPhrase(tokenDictionaries, index)
      return newIndex
    }
    return index + 1
  }

  tokenizeSet(string, userDefined, predefined) {
    const lowerCaseString = removeNoiseCharacters(string.toLowerCase(), [
      ",",
      "/",
      ";",
      ".",
      "!",
      "?",
      "(",
      ")",
    ])
    const tokenIndices = getAcronymnTokenIndices(
      lowerCaseString,
      acronyms,
      userDefined,
      predefined
    )
    const userDefinedTokenIndices = getUserDefinedTokenIndices(
      lowerCaseString,
      userDefined,
      predefined
    )

    const definedTokenIndices = tokenIndices
      .concat(userDefinedTokenIndices)
      .sort((a, b) => a[0] - b[0])

    const tokenDictList = []
    let prevIndex = 0
    let i = 0
    let el = 0 // for looping over definedTokenIndices
    while (i < lowerCaseString.length && el < definedTokenIndices.length) {
      if (i === definedTokenIndices[el][0]) {
        tokenDictList.push(tokenizePhrase(
          lowerCaseString.slice(prevIndex, i),
          prevIndex,
          predefined
        ))
        tokenDictList.push(definedTokenIndices[el][2])

        // moving prevIndex, i, and el to it's rightful place
        const slice = definedTokenIndices.slice(el)
        let j = 0
        for (j; j < slice.length; j++) {
          if (
            slice[j][0] + slice[j][1] >
            definedTokenIndices[el][0] + definedTokenIndices[el][1]
          )
            break
        }
        prevIndex = definedTokenIndices[el][0] + definedTokenIndices[el][1]
        i = definedTokenIndices[el][0] + definedTokenIndices[el][1]
        el += j
      }
      i++
    }
    tokenDictList.push(tokenizePhrase(
      lowerCaseString.slice(prevIndex, lowerCaseString.length),
      prevIndex,
      predefined
    ))
    return flatten(tokenDictList)
  }

  getDefaultStrokes(predefined) {
    const freeStroke = predefined.strokes.find(el => el.name === "Free")
    if (freeStroke)
      this.freeStroke = freeStroke
    else
      console.log("Can't find freestyle in predefined list. Using default. THIS IS BAD (if you are not testing)")
  }

  getDefaultTypes(predefined) {
    const swimType = predefined.types.find(el => el.name === "Swim")
    if (swimType)
      this.swimType = swimType
    else
      console.log("Can't find freestyle in predefined list. Using default. THIS IS BAD (if you are not testing)")
  }

  read(
    string,
    predefined = diffPredefined,
    userDefined = userDefinedKeys,
    above = defaultGhostLap,
    userDefaultIntensityId,
    userDefaultStrokeId,
    userDefaultTypeId
  ) {
    this.reset()
    this.string = string

    if (
      userDefaultStrokeId &&
      userDefined.strokes.find(el => el._id === userDefaultStrokeId)
    )
      this.freeStroke = userDefined.strokes.find(el => el._id === userDefaultStrokeId)
    else if (
      userDefaultStrokeId &&
      predefined.strokes.find(el => el._id === userDefaultStrokeId)
    )
      this.freeStroke = predefined.strokes.find(el => el._id === userDefaultStrokeId)
    else
      this.getDefaultStrokes(predefined)


    if (
      userDefaultTypeId &&
      userDefined.types.find(el => el._id === userDefaultTypeId)
    )
      this.swimType = userDefined.types.find(el => el._id === userDefaultTypeId)
    else if (
      userDefaultTypeId &&
      predefined.types.find(el => el._id === userDefaultTypeId)
    )
      this.swimType = predefined.types.find(el => el._id === userDefaultTypeId)
    else
      this.getDefaultTypes(predefined)

    if (
      userDefaultIntensityId &&
      userDefined.intensities.find(el => el._id === userDefaultIntensityId)
    )
      this.defaultIntensity = userDefined.intensities.find(el => el._id === userDefaultIntensityId)


    // if (!Match.test(string, String)) {
    //   console.log(`Lapify2 String was not an object: ${this.string}`)
    //   this.error = true
    //   return
    // }

    // Remove brackets here
    string = this.removeBracketComments(string)

    let tokenDictionaries = this.tokenizeSet(string, userDefined, predefined)
    if (tokenDictionaries.length === 0) {
      this.error = true
      return
    }

    let index = 0
    while (index < tokenDictionaries.length)
      index = this.parseNextToken(tokenDictionaries, index)

    tokenDictionaries = removeUnwantedStuff(string, tokenDictionaries)
    this.tokenDictionaries = tokenDictionaries

    let {
      reps,
      distance,
      distanceIndex,
      isOnlyIntervalCase,
    } = this.getRepsAndDistance(this.tokenDictionaries)
    this.reps = reps
    this.distance = distance
    this.isOnlyIntervalCase = isOnlyIntervalCase

    if (Number.isInteger(distanceIndex))
      distanceIndex += 1
    else
      distanceIndex = 0

    this.distanceIndex = distanceIndex
    this.afterTokens = this.tokenDictionaries.slice(this.distanceIndex)
  }
  grabFirstEvenAndOddSet(tokenSplit) {
    let firstEven = tokenSplit.find(el => el.isEven)
    const firstOdd = tokenSplit.find(el => el.isOdd)

    if (!firstEven && !firstOdd)
      return null

    if (!firstEven) {
      firstEven = {
        start: 0,
        end: tokenSplit[0].end,
        isEven: true,
        isOdd: false,
      }
    }
  }

  separateTokens(tokenSplit, reps, distance) {
    const globalTokens = [] // Tokens that are not evens/odds or reps ranges
    const evensOdds = []
    const repsRanges = []

    for (let i = 0; i < tokenSplit.length; i++) {
      const curToken = tokenSplit[i]
      if (curToken.isEven || curToken.isOdd)
        evensOdds.push(curToken)
      else if (curToken.start == 0 && curToken.end === reps * distance)
        globalTokens.push(curToken)
      else
        repsRanges.push(curToken)
    }
    return {
      globalTokens,
      evensOdds,
      repsRanges,
    }
  }

  sameStartAndEnd(tokenList) {
    if (tokenList.length === 0)
      return true

    const first = tokenList[0]
    for (let i = 0; i < tokenList.length; i++) {
      const curToken = tokenList[i]
      if (curToken.start !== first.start || curToken.end !== first.end)
        return false
    }
    return true
  }

  mergeGlobalTokens(tokenList, reps, distance) {
    // Check to make sure they all have the same start and end
    if (!this.sameStartAndEnd(tokenList)) {
      console.log(`Lapify2 Big problem with tokenList, need to be the same start and end:${
        this.string}`)
      return []
    }
    return {
      start: 0,
      end: reps * distance,
      singleGhostLapsArray:
        tokenList.length === 0
          ? []
          : tokenList
            .map(el => el.singleGhostLapsArray)
            .reduce((a, b) => this.mergeTwoGhostArraysNaive(a, b)),
    }
  }

  mergeEvensOdds(tokenList, reps, distance) {
    const firstEven = tokenList.find(el => el.isEven)
    const firstOdd = tokenList.find(el => el.isOdd)
    const returnToken = {
      start: 0,
      end: reps * distance,
    }
    if (!firstEven && !firstOdd) {
      returnToken.singleGhostLapsArray = []
      return returnToken
    }
    let newEvenGhostLaps = [Parser.makeGhostLap()]
    newEvenGhostLaps[0].lapDistance = distance
    if (firstEven) {
      newEvenGhostLaps = this.handleMagnify(
        firstEven.singleGhostLapsArray,
        distance
      )
    }

    let newOddGhostLaps = [Parser.makeGhostLap()]
    newOddGhostLaps[0].lapDistance = distance
    if (firstOdd) {
      newOddGhostLaps = this.handleMagnify(
        firstOdd.singleGhostLapsArray,
        distance
      )
    }

    returnToken.singleGhostLapsArray = newOddGhostLaps.concat(newEvenGhostLaps)
    return returnToken
  }

  areMergableGhostLaps(ghostLap1, ghostLap2) {
    return EJSON.equals(
      omit(ghostLap1, ["distance", "seconds"]),
      omit(ghostLap2, ["distance", "seconds"])
    )
  }

  smooshTogetherGhostLapsArray(ghostLapsArray) {
    const newGhostLapsArray = []
    for (let i = 0; i < ghostLapsArray.length; i++) {
      const curGhostLap = ghostLapsArray[i]
      let hasMerged = false
      for (let j = 0; j < newGhostLapsArray.length; j++) {
        const finalGhostLap = newGhostLapsArray[j]
        if (this.areMergableGhostLaps(curGhostLap, finalGhostLap)) {
          finalGhostLap.distance += curGhostLap.distance
          finalGhostLap.seconds += curGhostLap.seconds
          hasMerged = true
        }
      }
      if (!hasMerged)
        newGhostLapsArray.push(EJSON.clone(curGhostLap))
    }
    return newGhostLapsArray
  }

  handleMagnify(singleGhostLapsArray, distance) {
    const newSingleGhostLapsArray = []
    if (!this.allHaveLapDistance(singleGhostLapsArray)) {
      console.log(`Lapify2 Magnify broke, all single laps must have a lapDistance: ${
        this.string}`)
    }

    if (singleGhostLapsArray.length === 1) {
      newSingleGhostLapsArray.push(EJSON.clone(singleGhostLapsArray[0]))
      newSingleGhostLapsArray[0].lapDistance = distance
    } else if (singleGhostLapsArray.length > 1) {
      let leftover = distance
      let i = 0
      while (leftover > 0) {
        const curGhostLap = singleGhostLapsArray[i]
        const newLapDistance = Math.min(curGhostLap.lapDistance, leftover)

        // Make new lap, and set lapDistance
        const copyLap = EJSON.clone(curGhostLap)
        copyLap.lapDistance = newLapDistance
        newSingleGhostLapsArray.push(copyLap)

        leftover -= newLapDistance
        i = (i + 1) % singleGhostLapsArray.length
      }
    }
    return newSingleGhostLapsArray
  }

  generateToken(start, end) {
    return {
      start,
      end,
      singleGhostLapsArray: [],
    }
  }

  overlaps(token1, token2) {
    return token1.start < token2.end && token2.start < token1.end
  }
  overlapsArray(tokenList, newToken) {
    return tokenList.some(el => this.overlaps(el, newToken))
  }

  mergeRepsToken(orderedTokenList, newToken) {
    if (orderedTokenList.length === 0) {
      orderedTokenList.push(newToken)
      return orderedTokenList
    }
    if (newToken.end <= orderedTokenList[0].start) {
      orderedTokenList.unshift(newToken)
      return orderedTokenList
    }
    if (newToken.start >= orderedTokenList[orderedTokenList.length - 1].end) {
      orderedTokenList.push(newToken)
      return orderedTokenList
    }

    if (this.overlapsArray(orderedTokenList, newToken))
      return orderedTokenList


    for (let i = 0; i < orderedTokenList.length - 1; i++) {
      const thisToken = orderedTokenList[i]
      const nextToken = orderedTokenList[i + 1]
      if (newToken.start >= thisToken.end && newToken.end <= nextToken.start) {
        orderedTokenList.splice(i + 1, 0, newToken)
        return orderedTokenList
      }
    }
    return orderedTokenList
  }

  fillRepsRanges(orderedTokenList, reps, distance) {
    if (orderedTokenList.length === 0)
      return []

    const firstToken = orderedTokenList[0]
    if (firstToken.start !== 0)
      orderedTokenList.unshift(Parser.generateToken(0, firstToken.start))


    const lastToken = orderedTokenList[orderedTokenList.length - 1]
    if (lastToken.end !== reps * distance)
      orderedTokenList.push(Parser.generateToken(lastToken.end, reps * distance))


    let i = 0
    while (i < orderedTokenList.length - 1) {
      const thisToken = orderedTokenList[i]
      const nextToken = orderedTokenList[i + 1]
      if (thisToken.end < nextToken.start) {
        orderedTokenList.splice(
          i + 1,
          0,
          Parser.generateToken(thisToken.end, nextToken.start)
        )
        i += 1
      }
      i += 1
    }
    return orderedTokenList
  }

  mergeRepsRanges(tokenList, reps, distance) {
    const orderedTokenList = []
    tokenList.forEach(el => this.mergeRepsToken(orderedTokenList, el))
    this.fillRepsRanges(orderedTokenList, reps, distance)
    return orderedTokenList
  }

  handleIntervalCase(
    tokenDictionaries,
    predefined,
    userDefined,
    allGroups = [],
    secondsPerHundred = 120
  ) {
    const ghostLaps = this.breakApartLaps(tokenDictionaries)
    const first = ghostLaps[0]
    if (!first)
      return []

    first.seconds = first.interval.seconds
    if (first.interval.seconds) first.seconds = first.interval.seconds
    else if (first.rest.seconds) first.seconds = first.rest.seconds
    else if (first.hold.seconds)
      first.seconds = first.hold.seconds


    if (!first.seconds)
      return []

    if (first.intensity || first.type || first.stroke) {
      first.distance = Math.round((first.interval.seconds * 100 / secondsPerHundred) / 50)*50
      first.distanceSet = { distance: first.distance }
    } else {
      first.distance = 0
      first.distanceSet = { distance: 0 }
    }
    if (!first.stroke && this.freeStroke)
      first.stroke = { _id: this.freeStroke._id, name: this.freeStroke.name }

    if (!first.type && this.swimType)
      first.type = { _id: this.swimType._id, name: this.swimType.name }

    if (!first.intensity && this.defaultIntensity) {
      first.intensity = {
        _id: this.defaultIntensity._id,
        name: this.defaultIntensity.name,
      }
    }
    delete first.lapDistance
    first.groups = allGroups
    return [first]
  }

  findGroups(afterTokens) {
    return afterTokens.filter(el => el.type === "group").map(el => ({ _id: el._id, name: el.type_value }))
  }

  removeGroups(afterTokens) {
    return afterTokens.filter(el => el.type !== "group")
  }
  addGroupsToLaps(groups, laps) {
    for (let i = 0; i < laps.length; i++)
      laps[i].groups = EJSON.clone(groups)
  }

  checkForProperTypesAndValues(lapList) {
    const newLapList = []

    for (let i = 0; i < lapList.length; i++) {
      const curLap = lapList[i]
      // const isGood = Match.test(curLap, LapSchema)
      // if (!isGood) {
      //   console.log(`Lapify2 Lap failed schema test : ${this.string}`)
      //   return []
      // }
      newLapList.push(curLap)
    }

    return newLapList
  }

  hasExactlyOneInterval(tokens) {
    const filteredIntervals = tokens.filter(el => el.type === "interval")
    const exactlyOneInterval = filteredIntervals.length === 1
    let actualInterval = null
    if (exactlyOneInterval)
      actualInterval = filteredIntervals[0].seconds

    return { exactlyOneInterval, actualInterval }
  }
  changeAllIntervalsToInterval(tokenSplit, actualInterval) {
    // debugger
    for (let i = 0; i < tokenSplit.length; i++) {
      const curToken = tokenSplit[i]
      for (let j = 0; j < curToken.ghostLaps.length; j++) {
        const curGhostLapArray = curToken.ghostLaps[j]
        for (let k = 0; k < curGhostLapArray.length; k++) {
          curGhostLapArray[k].interval.seconds = actualInterval
          curGhostLapArray[k].interval.default = false
        }
      }
    }
  }

  getLastIntervalAfterModifier(afterTokens) {
    let thereIsAModifier = false
    let lastInterval = null

    for (let i = 0; i < afterTokens.length; i++) {
      const curToken = afterTokens[i]
      if (curToken.type === "modifier" && curToken.token !== "no") {
        thereIsAModifier = true
        lastInterval = null
      }

      if (thereIsAModifier && curToken.type === "interval")
        lastInterval = i
    }

    return lastInterval
  }

  lapify2({
    string = "",
    predefined = diffPredefined,
    userDefined = userDefinedKeys,
    above = [],
    secondsPerHundred = 120,
    userDefaultIntensityId,
    userDefaultStrokeId,
    userDefaultTypeId,
  }) {
    try {
      this.read(
        string,
        predefined,
        userDefined,
        above,
        userDefaultIntensityId,
        userDefaultStrokeId,
        userDefaultTypeId
      )
      if (this.error){
        return []
      }

      const { reps, distance, isOnlyIntervalCase } = this.getRepsAndDistance(this.tokenDictionaries)
      let allGroups = this.findGroups(this.afterTokens)
      if (allGroups.length === 0 && above.length > 0)
        allGroups = above[0].groups

      let removedGroups = this.removeGroups(this.afterTokens)
      if (isOnlyIntervalCase) {
        // Handle header case in here too
        return this.handleIntervalCase(
          this.tokenDictionaries,
          predefined,
          userDefined,
          allGroups,
          secondsPerHundred
        )
      }
      if (reps <= 0 || distance <= 0)
        return []

      const intervalIndexIfExists = this.getLastIntervalAfterModifier(removedGroups)
      if (intervalIndexIfExists) {
        const { exactlyOneInterval } = this.hasExactlyOneInterval(removedGroups)
        if (exactlyOneInterval) {
          const lastBit = removedGroups.slice(intervalIndexIfExists)
          const firstBit = removedGroups.slice(0, intervalIndexIfExists)
          removedGroups = lastBit.concat(firstBit)
        }
      }

      const { exactlyOneInterval, actualInterval } = this.hasExactlyOneInterval(removedGroups)
      const tokenSplit = this.splitAllTokens(removedGroups, reps, distance)
      /* tokenSplit = [{
                        tokens : [],
                        start: 0,
                        end : 400,
                        modifier : modifierToken
                        isEven : true,
                        isOdd : false,
                        brokenOutTokens : [[fast], [free, fly]]},
                      }]
      */

      for (let i = 0; i < tokenSplit.length; i++) {
        const curToken = tokenSplit[i]
        curToken.ghostLaps = curToken.brokenOutTokens.map(el =>
          this.breakApartLaps(el))
      }
      /* tokenSplit = [{
                        tokens : [],
                        start: 0,
                        end : 400,
                        modifier : modifierToken,
                        isEven : true,
                        isOdd : false,
                        brokenOutTokens : [[fast], [free, fly]]},
                        ghostLaps : [[fastGhostLap], [freeGhostLap, flyGhostLap]]
                      }]
      */
      if (exactlyOneInterval)
        this.changeAllIntervalsToInterval(tokenSplit, actualInterval)

      for (let i = 0; i < tokenSplit.length; i++) {
        const curToken = tokenSplit[i]
        curToken.singleGhostLapsArray = curToken.ghostLaps.reduce((a, b) =>
          this.mergeTwoGhostArraysNaive(a, b))
      }

      // The order of importance goes
      // 1. Reps range
      // 2. Evens Odds
      // 3. Global Token
      // 4. Token globally

      const { globalTokens, evensOdds, repsRanges } = this.separateTokens(
        tokenSplit,
        Parser.reps,
        Parser.distance
      )

      const globalToken = this.mergeGlobalTokens(
        globalTokens,
        Parser.reps,
        Parser.distance
      )
      const parityToken = this.mergeEvensOdds(
        evensOdds,
        Parser.reps,
        Parser.distance
      )
      const globalParityAbove = [
        parityToken.singleGhostLapsArray,
        globalToken.singleGhostLapsArray,
        above,
      ].reduce((a, b) => this.mergeTwoGhostArraysNaive(a, b))

      const finalToken = {
        start: 0,
        end: Parser.reps * Parser.distance,
        singleGhostLapsArray: globalParityAbove,
      }

      const repsTokenList = this.mergeRepsRanges(
        repsRanges,
        Parser.reps,
        Parser.distance
      )

      const finalTokenList = this.mergeRepsRangesAndOnlyToken(
        repsTokenList,
        finalToken
      )



      let laps = []
      for (let i = 0; i < finalTokenList.length; i++) {
        const token = finalTokenList[i]
        const newLaps = this.parserEngine(
          reps,
          distance,
          token.start,
          token.end,
          token.singleGhostLapsArray,
          secondsPerHundred
        )
        laps = laps.concat(newLaps)
      }

      const smooshedLaps = this.smooshTogetherGhostLapsArray(laps)

      this.addGroupsToLaps(allGroups, smooshedLaps)
      return this.checkForProperTypesAndValues(smooshedLaps)
    } catch (err) {
      console.log(`Lapify2 : ${err.message} : ${this.string}`)
      console.log(err.stack)
      return []
    }
  }

  mergeRepsRangesAndOnlyToken(orderedTokenList, onlyToken) {
    if (orderedTokenList.length === 0) return [onlyToken]
    const distance = this.countDistance(onlyToken)

    const finalTokenList = []
    for (let i = 0; i < orderedTokenList.length; i++) {
      const thisToken = orderedTokenList[i]
      if (thisToken.start % distance === 0) {
        const finalToken = {
          start: thisToken.start,
          end: thisToken.end,
          singleGhostLapsArray: this.mergeTwoGhostArraysNaive(
            thisToken.singleGhostLapsArray,
            onlyToken.singleGhostLapsArray
          ),
        }
        finalTokenList.push(finalToken)
      } else {
        // More logic here but for now same thing
        const finalToken = {
          start: thisToken.start,
          end: thisToken.end,
          singleGhostLapsArray: this.mergeTwoGhostArraysNaive(
            thisToken.singleGhostLapsArray,
            onlyToken.singleGhostLapsArray
          ),
        }
        finalTokenList.push(finalToken)
      }
    }
    return finalTokenList
  }

  // Do this naive loop way
  // More testing things to check
  // 1. What happens with 12.5, and the such
  // 2. What happens for big ones
  // 3. Optimization with
  // 4. Make sure you never generate more than reps*distance
  // 5. If you are about to make 1000 different laps, then say "mix"
  // to avoid the calculation
  mergeTwoGhostArraysNaive(firstGhostArray, secondGhostArray) {
    if (!Array.isArray(firstGhostArray) || !Array.isArray(secondGhostArray)) {
      console.log(`Ghost Array is not an array: ${this.string}`)
      return []
    }
    // if(firstGhostArray.length === 0 || secondGhostArray.length === 0){
    //   console.log("Ghost array is empty: " + this.string);
    //   return [];
    // }
    if (firstGhostArray.length === 0)
      return secondGhostArray

    if (secondGhostArray.length === 0)
      return firstGhostArray


    if (
      !(
        firstGhostArray.map(el => el.lapDistance).every(el => el > 0) ||
        secondGhostArray.map(el => el.lapDistance).every(el => el > 0)
      )
    ) {
      console.log(`Ghost array doesn't have lapDistance: ${this.string}`)
      return []
    }

    // Fast paths
    if (firstGhostArray.length === 1) {
      const onlyFirst = firstGhostArray[0]
      return secondGhostArray.map(el =>
        this.mergeTwoGhostLaps(onlyFirst, el, true))
    }
    if (secondGhostArray.length === 1) {
      const onlySecond = secondGhostArray[0]
      return firstGhostArray.map(el => this.mergeTwoGhostLaps(el, onlySecond))
    }

    // General case
    let i = 0
    let j = 0
    let firstCounter = firstGhostArray[0].lapDistance
    let secondCounter = secondGhostArray[0].lapDistance
    const ghostLaps = []
    while (
      !(
        ghostLaps.length > 0 &&
        i === 0 &&
        j === 0 &&
        firstCounter === firstGhostArray[0].lapDistance &&
        secondCounter === secondGhostArray[0].lapDistance
      )
    ) {
      const next = Math.min(firstCounter, secondCounter)
      const ghostLap = this.mergeTwoGhostLaps(
        firstGhostArray[i],
        secondGhostArray[j]
      )
      ghostLap.lapDistance = next
      ghostLaps.push(ghostLap)

      firstCounter -= next
      secondCounter -= next

      if (firstCounter === 0) {
        i = (i + 1) % firstGhostArray.length
        firstCounter = firstGhostArray[i].lapDistance
      }

      if (secondCounter === 0) {
        j = (j + 1) % secondGhostArray.length
        secondCounter = secondGhostArray[j].lapDistance
      }
    }
    return ghostLaps
  }
  // Assumptions every ghostLap has a distance
  parserEngineLapifier(
    reps,
    distance,
    start,
    end,
    ghostLaps,
    secondsPerHundred = 120
  ) {
    let ghostLaps2 = EJSON.clone(ghostLaps)
    // Maybe a better way to deal with this by doing this outside of this function
    if (ghostLaps2.length === 0) {
      const singleGhostLap = Parser.makeGhostLap()
      singleGhostLap.lapDistance = 25
      ghostLaps2 = [singleGhostLap]
    }

    const delta = end - start

    let totalDistance = ghostLaps2
      .map(el => el.lapDistance)
      .reduce((a, b) => a + b, 0)
    if (totalDistance === 0) {
      // Sanity check
      totalDistance = delta
    }
    const rotations = Math.floor(delta / totalDistance)
    let leftover = delta - rotations * totalDistance
    const ghostArray = []
    for (let i1 = 0; i1 < ghostLaps2.length; i1++) {
      const grab = Math.min(leftover, ghostLaps2[i1].lapDistance)
      ghostArray.push(grab)
      leftover -= Math.min(leftover, ghostLaps2[i1].lapDistance)
    }

    for (let i = 0; i < ghostLaps2.length; i++) {
      ghostLaps2[i].distance =
        rotations * ghostLaps2[i].lapDistance + ghostArray[i]

      if (!(ghostLaps2[i].distanceSet && ghostLaps2[i].distanceSet.distance))
        ghostLaps2[i].distanceSet = { distance }

      if (!ghostLaps2[i].interval.seconds) {
        ghostLaps2[i].interval.seconds =
          ghostLaps2[i].distanceSet.distance * secondsPerHundred / 100
        ghostLaps2[i].interval.default = true
      }
      ghostLaps2[i].seconds =
        ghostLaps2[i].distance *
        ghostLaps2[i].interval.seconds /
        ghostLaps2[i].distanceSet.distance
      if (!ghostLaps2[i].stroke && this.freeStroke) {
        // Need to change for production stuff
        ghostLaps2[i].stroke = {
          _id: this.freeStroke._id,
          name: this.freeStroke.name,
        }
      }
      if (!ghostLaps2[i].type && this.swimType) {
        ghostLaps2[i].type = {
          _id: this.swimType._id,
          name: this.swimType.name,
        }
      }
      if (!ghostLaps2[i].intensity && this.defaultIntensity) {
        ghostLaps2[i].intensity = {
          _id: this.defaultIntensity._id,
          name: this.defaultIntensity.name,
        }
      }
      delete ghostLaps2[i].lapDistance
    }
    // Remove anything with 0 distance and 0 seconds
    return ghostLaps2.filter(el => !(el.distance === 0 && el.seconds === 0))
  }

  allHaveLapDistance(ghostLaps) {
    return ghostLaps.map(el => el.lapDistance).every(el2 => el2)
  }

  parserEngine(reps, distance, start, end, ghostLaps, secondsPerHundred = 120) {
    if (ghostLaps.length === 0) {
      // console.log("The number of ghostLaps should never be 0");
    }
    if (start < 0)
      console.log(`Lapify2 Start was less than 0: ${this.string}`)


    if (!this.allHaveLapDistance(ghostLaps)) {
      const totalLength = ghostLaps.length
      if (distance % totalLength === 0) {
        for (let i = 0; i < ghostLaps.length; i++) {
          // DC_TODO change this to more generic pool length
          ghostLaps[i].lapDistance = 25
        }
      } else if (reps % totalLength === 0) {
        for (let i = 0; i < ghostLaps.length; i++)
          ghostLaps[i].lapDistance = distance
      } else
        ghostLaps = this.mergeGhostLaps(reps, distance, ghostLaps)
    }

    return this.parserEngineLapifier(
      reps,
      distance,
      start,
      end,
      ghostLaps,
      secondsPerHundred
    )
  }

  putRanges(tokenSplit, modifierList, reps, distance) {
    for (let i = 0; i < tokenSplit.length; i++) {
      if (
        modifierList[i].modifier_value &&
        modifierList[i].modifier_value.includes("RR")
      ) {
        const startRep = modifierList[i].startRep
        const endRep = modifierList[i].endRep
        tokenSplit[i].start = distance * (startRep - 1)
        tokenSplit[i].end = distance * endRep
        tokenSplit[i].start = Math.min(0, tokenSplit[i].start)
        tokenSplit[i].end = Math.max(distance * reps, tokenSplit[i].end)
      } else {
        tokenSplit[i].start = 0
        tokenSplit[i].end = reps * distance
      }
    }
  }

  findModifier(listOfListOfTokens) {
    for (let i = 0; i < listOfListOfTokens.length; i++) {
      const curTokenList = listOfListOfTokens[i]
      for (let j = 0; j < curTokenList.length; j++) {
        const token = curTokenList[j]
        if (token.type === "modifier" && token.token !== "no")
          return token
      }
    }
    return { type: "modifier", token: "global" }
  }

  addDefaultDistance(listOfGhostLaps) {
    for (let i = 0; i < listOfGhostLaps.length; i++) {
      const curList = listOfGhostLaps[i]
      for (let j = 0; j < curList.length; j++) {
        const ghostLap = curList[j]
        if (!ghostLap.lapDistance)
          ghostLap.lapDistance = 25
      }
    }
  }

  mergeLists(listA, listB) {
    const result = [];
    [listA, listB].forEach((curList) => {
      curList.forEach((aElement) => {
        const thisElement = result.find(el => el._id === aElement._id)
        // We found the element
        if (!thisElement)
          result.push(aElement)
      })
    })
    return result
  }

  mergeTwoGhostLaps(greater, lesser, keepLesserLapDistance = false) {
    const newGhostLap = EJSON.clone(greater)
    if (!newGhostLap.type) newGhostLap.type = lesser.type
    if (!newGhostLap.stroke) newGhostLap.stroke = lesser.stroke
    if (!newGhostLap.intensity) newGhostLap.intensity = lesser.intensity

    if (!newGhostLap.interval.seconds)
      newGhostLap.interval.seconds = lesser.interval.seconds
    if (!newGhostLap.rest.seconds)
      newGhostLap.rest.seconds = lesser.rest.seconds
    if (!newGhostLap.hold.seconds)
      newGhostLap.hold.seconds = lesser.hold.seconds

    newGhostLap.equipments = this.mergeLists(
      greater.equipments,
      lesser.equipments
    )

    if (keepLesserLapDistance)
      newGhostLap.lapDistance = lesser.lapDistance

    // newGhostLap.groups = this.mergeLists(greater.groups, lesser.groups);

    return newGhostLap
  }

  doctorRepRangeTokens({tokens, reps}) {
    /*
      Doctor tokens as follows:
      [{
        "token": 4,
        "begin": 9,
        "end": 10,
        "data_type": "int",
        "type": "modifier",
        "modifier_value": "RR:4",
        "startRep": 4,
        "endRep": 4
      },
      {
        "token": "fast",
        "begin": 11,
        "end": 15,
        "data_type": "str",
        "_id": "Fast",
        "type_value": "Fast",
        "type": "intensity",
        "modified_by": [
          "RR:4"
        ],
        "modifier_phrase_index": 3
      },
    ]
      Find all tokens that are a rep range (either range or single)
      use the total "reps" passed into this function to compare all of these RR tokens to the total reps:
        -Do the "ranges" cover a "mod" 0 version of the whole thing?
        -Are they all singles AND do they add up to the total thing? DONE
        -Are they all singles and do they add up to a "mod" 0 version of the whole thing?
      If any of these things above are true, doctor the usefultokens array so that it represents the truth AS IF they were a coherent set of rep ranges.
    */
    function isRR(tok){
      const {type, data_type, startRep, endRep, modifier_value} = tok
      return (type === "modifier" && data_type === "int" && modifier_value && modifier_value.includes('RR'))
    }

    // all of the tokens that represent a rep range
    const rrTokens = tokens.filter(el => isRR(el))
    function isSingleRepRR(tok){
      const {startRep, endRep} = tok
      return (Number.isInteger(startRep) && Number.isInteger(endRep) && endRep === startRep)
    }

    function isModifiedBySingleRepRR(token){
      const {modified_by} = token
      return modified_by && modified_by.length > 0 && modified_by.some(mod => mod.includes('RR:') && !mod.includes("-"))
    }

    function isRepeated(repRepresentation, reps){
      let partialReps = 0
      let dead = false
      for (let i=0; i < reps; i++){
        const currentRR = repRepresentation[i]
        if (currentRR === 1 && !dead){
          partialReps += 1
        } else {
          dead = true
        }
      }
      let isRepeated = false
      if (partialReps > 1 && reps % partialReps === 0){
        isRepeated = true
      }
      return {isRepeated, partialReps}
    }

    // get all the single rep RRs
    const singleRRTokens = rrTokens.filter(el => isSingleRepRR(el))
    // if all the rep range tokens are singles, see if they add up to the total reps or a mod 0 version of it.
    if (singleRRTokens.length > 1 &&  singleRRTokens.length === rrTokens.length){
      const sum = singleRRTokens.map(el => el.startRep).reduce((a,b) => a+b, 0)
      if (sum > 1 && reps % sum === 0){
        const repeatedAmount = reps/sum
        // At the end of the relevant tokens, clone and modify "repeatedAmount" times (notjust the rr tokens but also the tokens modified by the rr tokens)
        const toCloneAndModify = []
        let lastIndex = 0
        let currentRep = 1
        let doctoredTokens = []

        tokens.forEach((token, index) => {
          if (isSingleRepRR(token)){
            const nextSRRTokenIndex = singleRRTokens.findIndex(el => token.begin === el.begin) + 1
            const nextSRRToken = singleRRTokens[nextSRRTokenIndex]
            const startRep = currentRep
            const endRep = startRep + token.endRep - 1
            const modifier_value =  `RR:${startRep}-${endRep}`
            const doctoredToken = {
              ...token,
              modifier_value,
              startRep,
              endRep
            }
            currentRep = endRep + 1

            toCloneAndModify.push(doctoredToken)

            lastIndex = index
            doctoredTokens.push(doctoredToken)
            // find the original modifier_value and the "modified_by" tokens
            const modifiedBy = tokens.filter(el => {
              if (el.modified_by && el.modified_by.includes(token.modifier_value)){
                // this token also has to fall under this singlerepRR's jurisdiction
                if (el.begin > token.begin && (!nextSRRToken || el.begin < nextSRRToken.begin)){
                  return true
                }
                return false
              }
              return false
            })

            modifiedBy.forEach(modToken => {
              const modified_by = modToken.modified_by.map(el => {
                if (el === token.modifier_value){
                  return modifier_value
                }
                return el.modifier_value
              })
              const doctoredModToken = {
                ...modToken,
                modified_by,
              }
              doctoredTokens.push(doctoredModToken)

                toCloneAndModify.push(doctoredModToken)

            })
          } else if (!isModifiedBySingleRepRR(token, tokens)){
            // not related to what we are doing, just push it on
            doctoredTokens.push(token)
          } else {
            lastIndex = index
          }
        })

        // lastIndex represents the last index where a rr or modified rr was found.
        // need to jam on the "toCloneAndModify" pieces there and then return
        const firstPiece = doctoredTokens.slice(0,lastIndex+1)
        const secondPiece = doctoredTokens[lastIndex+1] ? doctoredTokens.slice(lastIndex+1, doctoredTokens.length) : []
        // now loop over the additional rounds and adjust the rep ranges
        for (let i=1; i < repeatedAmount; i++){

          toCloneAndModify.forEach(cloneToken => {
            if (isRR(cloneToken)){
              const startRep = currentRep
              const endRep = startRep + (cloneToken.endRep - cloneToken.startRep) // add the same delta as the one we are cloning
              const modifier_value =  `RR:${startRep}-${endRep}`
              const doctoredToken = {
                ...cloneToken,
                modifier_value,
                startRep,
                endRep
              }
              currentRep = endRep + 1
              firstPiece.push(doctoredToken)
              // find the original modifier_value and the "modified_by" tokens
              const modifiedBy = toCloneAndModify.filter(el => el.modified_by && el.modified_by.includes(cloneToken.modifier_value))

              modifiedBy.forEach(modToken => {
                const modified_by = modToken.modified_by.map(el => {
                  if (el === cloneToken.modifier_value){
                    return modifier_value
                  }
                  return el.modifier_value
                })
                const doctoredModToken = {
                  ...modToken,
                  modified_by,
                }
                firstPiece.push(doctoredModToken)
              })

            }
          })

        }

        return firstPiece.concat(secondPiece)

      }
    } else if (rrTokens.length > 0){
      //TODO -> this would be for sets like this:
      // 6 x 100s descend 1-3.
      // assuming that 4-6 should also be descend.
      // didn't do it because not sure if it's a fair assumption that this would be the intention
      // also because I ran out of time I wanted to spend on this

      // // Figure out if the rep ranges are intended to be "repeaated"
      // // set up a count for how many times each rep is "modified"
      // let repRepresentation = []
      // for (let i=0; i < reps; i++){
      //   repRepresentation.push(0)
      // }
      //
      // // loop over all the rrTokens and fill out the representation
      // rrTokens.forEach(rrToken => {
      //   const {startRep, endRep} = rrToken
      //   // so long as both start and end are ints, continue mapping this out
      //   if (Number.isInteger(startRep) && Number.isInteger(endRep) && endRep >= startRep){
      //     for (let i=startRep; i <= endRep; i++){
      //       // add 1 to the representation
      //       repRepresentation[i-1] += 1
      //     }
      //   }
      // })
      //
      // const {isRepeated, partialReps} = isRepeated(repRepresentation, reps)
      // if (isRepeated){
      //
      // }
    }

    // continue on our way
    return tokens
  }

  splitApartModifiers(tokenDictionaries, reps, distance) {
    if (!reps || !distance) {
      console.log("Need to specify reps and distance to splitApartModifiers")
      return []
    }
    let usefulTokens = tokenDictionaries.filter(el => el.type)
    const globalBreakDown = []

    const curObject = {
      start: 0,
      end: reps * distance,
      modifier: null,
      isEven: false,
      isOdd: false,
      tokens: [],
    }

    /*
      Doctor tokens as follows:
      Find all tokens that are a rep range (either range or single)
      use the total "reps" passed into this function to compare all of these RR tokens to the total reps:
        -Do the "ranges" cover the whole thing?
        -Do the "ranges" cover a "mod" 0 version of the whole thing?
        -Are they all singles AND do they add up to the total thing?
        -Are they all singles and do they add up to a "mod" 0 version of the whole thing?
      If any of these things above are true, doctor the usefultokens array so that it represents the truth AS IF they were a coherent set of rep ranges.
    */

    usefulTokens = this.doctorRepRangeTokens({tokens: usefulTokens, reps})


    for (let i = 0; i < usefulTokens.length; i++) {
      const curToken = usefulTokens[i]
      if (curToken.type === "modifier" && curToken.token !== "no") {
        // If you have seen a modifier and you have tokens already
        if (curObject.tokens.length > 0) {
          const modifier = curObject.modifier
          curObject.isEven = modifier
            ? modifier.modifier_value === "evens"
            : false
          curObject.isOdd = modifier
            ? modifier.modifier_value === "odds"
            : false
          const newOne = EJSON.clone(curObject)
          globalBreakDown.push(newOne)
        }
        curObject.tokens = []
        curObject.modifier = curToken
        const SR = curToken.startRep
        const ER = curToken.endRep
        let start = SR ? distance * (SR - 1) : 0
        let end = ER ? distance * ER : reps * distance
        start = Math.max(0, start)
        end = Math.min(end, distance * reps)
        curObject.start = start
        curObject.end = end
      } else {
        curObject.tokens.push(curToken)
      }
    }
    if (curObject.tokens.length > 0) {
      const modifier = curObject.modifier
      curObject.isEven = modifier ? modifier.modifier_value === "evens" : false
      curObject.isOdd = modifier ? modifier.modifier_value === "odds" : false
      const newOne = EJSON.clone(curObject)
      globalBreakDown.push(newOne)
    }
    return globalBreakDown
  }

  splitAllTokens(tokenDictionaries, reps, distance) {
    if (tokenDictionaries.length === 0) return []
    const tokensByModifierList = this.splitApartModifiers(
      tokenDictionaries,
      reps,
      distance
    )
    for (let i = 0; i < tokensByModifierList.length; i++)
      tokensByModifierList[i].brokenOutTokens = this.handleInnerTokens(tokensByModifierList[i].tokens)

    return tokensByModifierList
  }

  handleInnerTokens(tokenDictionaries) {
    const [frontTokens, laterTokens] = this.breakOffFrontModifiers(tokenDictionaries)
    const brokenOut = this.breakApartDoubleTypes(laterTokens)
    if (frontTokens.length > 0)
      brokenOut.unshift(frontTokens)

    return brokenOut
  }

  breakOffFrontModifiers(tokenDictionaries) {
    const typeMapping = new Map()
    const typeArray = [
      "stroke",
      "type",
      "intensity",
      "interval",
      "rest",
      "hold",
      "modifier",
      "distance",
    ]
    let sliceIndex = null
    for (let i = 0; i < tokenDictionaries.length; i++) {
      const curToken = tokenDictionaries[i]

      if (curToken.type) {
        if (
          typeMapping.has(curToken.type) &&
          typeArray.includes(curToken.type)
        ) {
          sliceIndex = typeMapping.get(curToken.type)
          break
        } else if (curToken.type === "distance") {
          sliceIndex = i
          break
        } else
          typeMapping.set(curToken.type, i)
      }
    }
    if (!sliceIndex)
      sliceIndex = 0


    return [
      tokenDictionaries.slice(0, sliceIndex),
      tokenDictionaries.slice(sliceIndex),
    ]
  }

  breakApartDoubleTypes(tokenDictionaries) {
    const tokenSplit = []
    let curArray = []
    const typeArray = ["stroke", "type", "intensity"]
    for (let i = 0; i < tokenDictionaries.length; i++) {
      const lastToken = tokenDictionaries[i - 1]
      const curToken = tokenDictionaries[i]
      const nextToken = tokenDictionaries[i + 1]

      const isNewBreak =
        typeArray.includes(curToken.type) &&
        nextToken &&
        curToken.type === nextToken.type &&
        lastToken &&
        curToken.type !== lastToken.type
      if (isNewBreak) {
        if (curArray.length > 0) tokenSplit.push(curArray)
        curArray = [curToken]
      } else
        curArray.push(curToken)
    }

    if (curArray.length > 0) tokenSplit.push(curArray)

    return tokenSplit
  }

  makeGhostLap() {
    return {
      distance: null,
      seconds: null,
      distanceSet: { distance: null },
      interval: { seconds: null },
      rest: { seconds: null },
      hold: { seconds: null },
      type: null,
      stroke: null,
      intensity: null,
      equipment: null,
      equipments: [],
      groups: [],
    }
  }

  breakApartLaps(tokenDictionaries) {
    const ghostLaps = []
    let curGhostLap = this.makeGhostLap()

    // token has a type = 'type', 'stroke', 'intensity', 'time',
    for (let i = 0; i < tokenDictionaries.length; i++) {
      const curToken = tokenDictionaries[i]
      if (curToken.possible_distance_number) {
        if (curGhostLap.lapDistance) {
          ghostLaps.push(curGhostLap)
          curGhostLap = this.makeGhostLap()
          curGhostLap.lapDistance = curToken.token
          curGhostLap.distanceSet.distance = curToken.token
        } else {
          curGhostLap.lapDistance = curToken.token
          curGhostLap.distanceSet.distance = curToken.token
        }
      } else if (["intensity", "stroke", "type"].includes(curToken.type)) {
        if (curGhostLap[curToken.type]) {
          // Make new
          ghostLaps.push(curGhostLap)
          curGhostLap = this.makeGhostLap()
          curGhostLap[curToken.type] = {
            _id: curToken._id,
            name: curToken.type_value,
          }
        } else {
          curGhostLap[curToken.type] = {
            _id: curToken._id,
            name: curToken.type_value,
          }
        }
      } else if (["interval", "rest", "hold"].includes(curToken.type)) {
        if (curGhostLap[curToken.type].seconds) {
          // Make new
          ghostLaps.push(curGhostLap)
          curGhostLap = this.makeGhostLap()
          curGhostLap[curToken.type].seconds = curToken.seconds
        } else {
          curGhostLap[curToken.type].seconds = curToken.seconds
          if (curToken.type === "interval")
            curGhostLap[curToken.type].default = false
        }
      } else if (curToken.type === "group") {
        curGhostLap.groups.push({
          _id: curToken._id,
          name: curToken.type_value,
        })
      } else if (curToken.type === "equipment") {
        curGhostLap.equipments.push({
          _id: curToken._id,
          name: curToken.type_value,
        })
      }
    }
    ghostLaps.push(curGhostLap)

    this.putDefaultLapDistances(ghostLaps)
    return ghostLaps
  }

  putDefaultLapDistances(ghostLaps) {
    for (let i = 0; i < ghostLaps.length; i++) {
      if (!ghostLaps[i].lapDistance)
        ghostLaps[i].lapDistance = 25
    }
  }
  // This looks good
  getRepsAndDistance(tokenDictionaries) {
    const distanceIndex = tokenDictionaries.findIndex(el => el.type === "distance")
    let distance = null
    let reps = null
    if (distanceIndex === -1) {
      return {
        reps: null,
        distance: null,
        distanceIndex: null,
        isOnlyIntervalCase: true,
      }
    }
    distance = tokenDictionaries[distanceIndex].token
    // Clean this up
    const repsNumb = tokenDictionaries
      .slice(0, distanceIndex)
      .reverse()
      .find(el => el.data_type === "int")
    if (!repsNumb)
      reps = 1
    else
      reps = repsNumb.token

    return {
      reps,
      distance,
      distanceIndex,
      isOnlyIntervalCase: false,
    }
  }

  mergeGhostLaps(reps, distance, ghostLaps) {
    const singleGhostLap = this.makeGhostLap()
    for (let i = 0; i < ghostLaps.length; i++) {
      const curGhostLap = ghostLaps[i]
      const types = ["intensity", "stroke", "type"]
      for (let j = 0; j < types.length; j++) {
        const el = types[j]
        if (curGhostLap[el] && !singleGhostLap[el])
          singleGhostLap[el] = EJSON.clone(curGhostLap[el])
        else if (curGhostLap[el] && singleGhostLap[el]) {
          // Need to switch this over for the production case where we pass in stuff
          // DC_TODO_PARSE
          singleGhostLap[el] = { _id: `Mix${toTitleCase(el)}`, name: "Mix" }
        }
      }

      const types2 = ["interval", "rest", "hold"]
      for (let j = 0; j < types2.length; j++) {
        const el = types2[j]
        if (curGhostLap[el].seconds && !singleGhostLap[el].seconds)
          singleGhostLap[el].seconds = curGhostLap[el].seconds
      }
    }
    singleGhostLap.lapDistance = reps * distance
    return [singleGhostLap]
  }

  getLoops(
    string,
    predefined = diffPredefined,
    userDefined = userDefinedKeys
  ) {
    this.read(string, predefined, userDefined)
    let loop = 1
    let oneGhostLap = Parser.makeGhostLap()

    if (this.error) {
      return [ {loop, ghostLap: oneGhostLap} ]
    }

    let firstIntegerToken = null
    for (let i = 0; i < this.tokenDictionaries.length - 1; i++) {
      const cur = this.tokenDictionaries[i]
      const next = this.tokenDictionaries[i + 1]
      if (
        cur.data_type === "int" &&
        ["x", "times", "time"].includes(next.token)
      )
        firstIntegerToken = cur
    }

    // firstIntegerToken = this.tokenDictionaries.find(el => el.data_type === 'int');
    if (firstIntegerToken){
      loop = firstIntegerToken.token
    }
    if (loop <= 0){
      loop = 1
    }

    let ghostLapList = this.breakApartLaps(this.tokenDictionaries)
    if (!ghostLapList || ghostLapList.length === 0){
      ghostLapList = [Parser.makeGhostLap()]
    }
    const allGroups = this.findGroups(this.tokenDictionaries)
    ghostLapList = ghostLapList.map(el => ({...el, groups: allGroups}))

    const finalLoops = []
    if (loop % ghostLapList.length === 0){
      // if the "rounds" descriptor appears to divise into the number of loops, apply the relevant "lap" to each round
      const numberSame = loop/ghostLapList.length
      for (let i=0; i < ghostLapList.length; i++){
        finalLoops.push({
          loop: numberSame,
          ghostLap:  ghostLapList[i]
        })
      }
    } else {
      // the "rounds" descriptor didn't make sense relative to the number of rounds
      //defaults back to simply the number of loops and the very first relevant "lap" descriptor we see
      finalLoops.push({
        loop,
        ghostLap: ghostLapList[0]
      })
    }

    return finalLoops
  }

  recursiveLapifyFromOuterSet({
    setDescription = "",
    predefined = diffPredefined,
    userDefined = userDefinedKeys,
    secondsPerHundred = 120,
    userDefaultIntensityId,
    userDefaultStrokeId,
    userDefaultTypeId,
  }) {
    // Total hack for now
    if (!isNaN(parseFloat(secondsPerHundred)) && isFinite(secondsPerHundred)) {
      if (secondsPerHundred < 45 || secondsPerHundred > 540) {
        console.log("SecondsPerHundred is off : Must be a time between :45 and 9:00")
        secondsPerHundred = 120
      }
    } else {
      console.log(`this is the seconds per hundred: ${secondsPerHundred}`)
      console.log("SecondsPerHundred is off: Not an integer, maybe infinite.")
      secondsPerHundred = 120
    }
    return this.recursiveLapify2(
      setDescription,
      predefined,
      userDefined,
      [],
      secondsPerHundred,
      userDefaultIntensityId,
      userDefaultTypeId,
      userDefaultStrokeId
    )
  }

  recursiveLapify2(
    setBlock,
    predefined = diffPredefined,
    userDefined = userDefinedKeys,
    above = [],
    secondsPerHundred = 120,
    userDefaultIntensityId,
    userDefaultTypeId,
    userDefaultStrokeId
  ) {
    if (setBlock.innerDescriptions.length === 0) {
      return this.lapify2({
        string: setBlock.text,
        predefined,
        userDefined,
        above,
        secondsPerHundred,
        userDefaultIntensityId,
        userDefaultStrokeId,
        userDefaultTypeId,
      })
    }
    let loops = this.getLoops(
      setBlock.text,
      predefined,
      userDefined
    )
    let finalLapList = []
    loops.forEach(({loop, ghostLap}) => {
      if (above.length > 0)
        ghostLap = this.mergeTwoGhostLaps(ghostLap, above[0])

      if (ghostLap.groups.length === 0 && above.length > 0)
        ghostLap.groups = above[0].groups

      const bigLapList = []
      for (let i = 0; i < setBlock.innerDescriptions.length; i++) {
        const set = setBlock.innerDescriptions[i]
        const laps = this.recursiveLapify2(
          set,
          predefined,
          userDefined,
          [ghostLap],
          secondsPerHundred,
          userDefaultIntensityId,
          userDefaultTypeId,
          userDefaultStrokeId
        )
        bigLapList.push(laps)
      }
      const lapList = this.smooshTogetherGhostLapsArray(flatten(bigLapList)) // [lap1, lap2, ...]
      lapList.forEach((lap) => {
        lap.distance *= loop
        lap.seconds *= loop
      })
      finalLapList = finalLapList.concat(lapList)
    })

    return finalLapList
  }
  countDistance(ghostLapsArray) {
    let totalDistance = 0
    for (let i = 0; i < ghostLapsArray.length; i++)
      totalDistance += ghostLapsArray[i].lapDistance

    return totalDistance
  }
}

let Parser = new ParserClass()

export { Parser, diffPredefined, userDefinedKeys }
