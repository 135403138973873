import PropTypes from "prop-types"
import React from "react"
import moment from "moment"

class PaceList extends React.Component {
  static propTypes = {
    paceList: PropTypes.arrayOf(PropTypes.number),
    style: PropTypes.object,
  }

  static defaultProps = {
    style: {},
  }

  toTime = (timeInSeconds) => {
    let time = moment()
      .startOf("day")
      .milliseconds(1000 * timeInSeconds)
    let formatted = time.format("m:ss.S")
    if (formatted[formatted.length - 1] === "0") return time.format("m:ss")
    else return formatted
  }

  render() {
    let spanStyle = this.props.style || { margin: 0, padding: 0 }

    return (
      <div>
        {this.props.paceList.map((el) => {
          return (
            <div key={el}>
              <span style={spanStyle}> {this.toTime(el)} </span>
              <br />
            </div>
          )
        })}
      </div>
    )
  }
}

export default PaceList
