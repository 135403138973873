import PropTypes from "prop-types"
import React from "react"

class DistanceList extends React.Component {
  static propTypes = {
    distanceList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        distance: PropTypes.number,
      })
    ),
    style: PropTypes.object,
  }

  static defaultProps = {
    style: {},
  }

  render() {
    let spanStyle = this.props.style || { margin: 0, padding: 0 }

    return (
      <div>
        {this.props.distanceList.filter((el) => el.distance > 0).map((el) => {
          return (
            <div key={el.name}>
              <span style={spanStyle}>
                {el.name}: {el.distance.toLocaleString()}{" "}
              </span>
              <br />
            </div>
          )
        })}
      </div>
    )
  }
}

export default DistanceList
