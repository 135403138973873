import PropTypes from "prop-types"
import React from "react"
import moment from "moment"

class TimeList extends React.Component {
  static propTypes = {
    timeList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        time: PropTypes.number,
      })
    ),
    style: PropTypes.object,
  }

  static defaultProps = {
    style: {},
  }

  toTime = (timeInSeconds) => {
    let time = moment()
      .startOf("day")
      .seconds(timeInSeconds)
    if (time.hours() > 0) {
      return time.format("h:mm:ss")
    } else {
      return time.format("m:ss")
    }
  }

  render() {
    let spanStyle = this.props.style || { margin: 0, padding: 0 }

    return (
      <div>
        {this.props.timeList.filter((el) => el.time > 0).map((el) => {
          return (
            <div key={el.name}>
              <span style={spanStyle}>
                {el.name}: {this.toTime(el.time)}{" "}
              </span>
              <br />
            </div>
          )
        })}
      </div>
    )
  }
}

export default TimeList
