/*
  Helper functions that are used in the Commit Text Box and
  the group stats, and the single stats

  Basically these functions take in a block of text, and
  a cursorPosition, and aim to answer generic questions
*/

/*          String Helper Functions
////////////////////////////////////////////////////////
*/
import React from "react"
import { get, isEqual } from "lodash"
import ReactDOM from "react-dom"
import ReactQuill from "react-quill"
import AutoSuggestGroups2 from "./AutoSuggestGroups2.js"
import { Parser } from "./parser/lib"
import { debounce } from "lodash"
import {
  generateRecursiveSetObject,
  scanStringForCharacter,
  getStartPosition2,
  getSpacesAtBeginningOfLine,
  getLineText,
} from "./parser/parsingHelpers.js"

/*            End Helper Functions
////////////////////////////////////////////////////////
*/

/*
  The main idea of the Commit Text Box is to be the primary holder of complexity.
  It aims to take care of the workout writing, group autocomplete,
  database saving, and set favoriting.

  It also aims to provide the same experience on any browser, and mobile device.

  In the future, I hope to support set autocomplete features based on history.

  The complexity of this widget is "opt-in", meaning that one has to manually decide which
  of the features one supports. For example the landing page doesn't have the group
  autocomplete and the set favoriting.
*/

const generateSpaces = function(numb) {
  let start = ""
  for (let i = 0; i < numb; i++) {
    start += " "
  }
  return start
}

const afterText = function(text, cursorPosition) {
  if (cursorPosition === 0) return false
  if (cursorPosition > text.length) return true

  let cur = cursorPosition - 1

  while (text[cur] !== "\n" && cur >= 0) {
    if (text[cur] !== " ") return true
    cur = cur - 1
  }
  return false
}

const canSummonGroupsList = function(text, cursorPosition) {
  if (cursorPosition <= 0) return false
  // The next character has to be a space
  let pointedAt = text.charAt(cursorPosition)
  if (![" ", "", "\n"].includes(pointedAt)) {
    return false
  }

  let start = scanStringForCharacter(
    text,
    cursorPosition - 1,
    ["\n", "", " "],
    true
  )
  let begPos = start
  if (["\n", "", " "].includes(text.charAt(start))) begPos = start + 1

  return text.charAt(begPos) === "#"
}

const CustomToolbar = () => (
  <div id="toolbar" className="ql-toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-link" />
    </span>
    <span className="ql-formats">
      <select defaultValue="normal" className="ql-size">
        <option value="normal" />
        <option value="large" />
        <option value="huge" />
      </select>
      <select defaultValue="#000" className="ql-color">
        <option value="#0091ea" />
        <option value="#d50000" />
        <option value="#6200ea" />
        <option value="#388e3c" />
        <option value="#ef6c00" />
        <option value="#f06292" />
        <option value="#000" />
      </select>
    </span>
  </div>
)

class CommitTextBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quillDelta: props.startingQuillDelta || {}, // quill delta
      text: props.startingText || "",
      visibleWidgetName: "", // When this is set to the name of the widget that widget becomes visible
      cursorTop: 0, // Pixel value of the cursor from the top
      cursorLeft: 0, // Pixel value of the cursor from the left
    }

    // Kvothe make sure this still works
    //update variables outside this component to communicate with other components
    this.lazySetAllText = debounce(() => {
      this.props.setAllText(this.state.text)
    }, 600)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const quillEditor = this.refs.textManFancy.getEditor()
    //update the value of the textbox if new props come through with a different value than the current state AND we're not actively in the textbox
    //examples include... set bank adding a set to the workout AND other coache editing the workout on their machine
    if (
      quillEditor &&
      !isEqual(nextProps.startingQuillDelta, this.props.startingQuillDelta) &&
      !isEqual(nextProps.startingQuilDelta, quillEditor.getContents()) &&
      !quillEditor.hasFocus() &&
      nextProps.listenForWorkoutTextChange
    ) {
      // manually jam the new delta into the text area
      quillEditor.setContents(nextProps.startingQuillDelta)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.style.height !== nextProps.style.height) {
      return true
    }

    if (this.state.visibleWidgetName !== nextState.visibleWidgetName) {
      return true
    }

    if (nextState.visibleWidgetName.length > 0) {
      // this needs to be here in case we click from 1 # to another #, we want to re-render autosuggest groups (just a different version)
      return true
    }

    if (!isEqual(this.state.quillDelta, nextState.quillDelta)) {
      return true
    }

    return false
  }

  componentDidMount() {
    const quillEditor = this.refs.textManFancy.getEditor()
    if (this.props.autoFocus) {
      quillEditor.focus()
    }

    if (this.props.startingText && !this.props.startingQuillDelta) {
      quillEditor.setText(this.props.startingText)
    }

    // // When this component mounts the very first time create some state that is useful for "Saving / Updating"
    this.status = "AllChangesSaved"
  }

  getValue = () => {
    return this.state.text
  }

  lazySetCurrentSet = (cursorPosition, text) => {
    let newSet = generateRecursiveSetObject(text, cursorPosition)
    this.props.setCurrentSet(newSet.text, newSet.innerDescriptions)
  }

  //sets new value after user selects a grpoup from the autosuggest widget after typing '#'
  insertGroup = ({ groupText, withoutHashtag, index }) => {
    let curName = groupText + " " // The space is needed to put a space after the name
    const quillEditor = this.refs.textManFancy.getEditor()
    curName = curName.slice(withoutHashtag.length)
    quillEditor.insertText(index, curName)
    quillEditor.setSelection(index + curName.length)
    this.setState({ visibleWidgetName: "" })
  }

  handleChangeSelectionQuill = (range, source, editor) => {
    if (range) {
      const { index, length } = range
      this.setState({ quillRangeLength: length, quillRangeIndex: index })
      const text = editor.getText()
      if (this.props.setAllText) {
        this.lazySetAllText()
      }
      if (this.props.setCurrentSet && index) {
        this.lazySetCurrentSet(index, text)
      }

      // indicates no highlighting by user
      if (range.length === 0) {
        const quillEditor = this.refs.textManFancy.getEditor()
        if (
          canSummonGroupsList(text, range.index, this.props.groupsArray) &&
          quillEditor.hasFocus()
        ) {
          const bounds = quillEditor.getBounds(index, length)
          this.setState({
            visibleWidgetName: "AutoSuggestGroups",
            cursorLeft: bounds.left,
            cursorTop: bounds.bottom,
          })
        } else {
          this.setState({ visibleWidgetName: "" })
        }
      }
    }
  }

  handleChangeQuill = (quillHtml, partialDelta, source, editor) => {
    const quillDelta = editor.getContents()
    const text = editor.getText()
    this.setState({ text, quillDelta, quillHtml })
  }

  handleBackspace = (e, cursorPosition, quillEditor) => {
    const text = quillEditor.getText()

    // Deal with highlighted text case
    const range = quillEditor.getSelection()
    if (range && range.length > 0) {
      quillEditor.deleteText(cursorPosition, range.length)
      return
    }

    // Deal backspace at beginning of document case (do nothing)
    if (cursorPosition === 0) {
      return
    }

    if (afterText(text, cursorPosition)) {
      quillEditor.deleteText(cursorPosition - 1, 1)
      return // Do the default handling
    }

    // Cursor is at the beginning of the line
    let cursorAtBeginning = getStartPosition2(text, cursorPosition)
    if (cursorPosition === cursorAtBeginning) {
      quillEditor.deleteText(cursorPosition - 1, 1)
      return
    }
    // If you got this far, we are doing our franken backspace
    e.preventDefault()

    // Only remove 6 characters back if you are at the front of a row of text
    let spacesOnThisLine = getSpacesAtBeginningOfLine(text, cursorPosition)
    let beginOfLineCursor = getStartPosition2(text, cursorPosition)

    let numberOfBackSpaces = spacesOnThisLine % 6
    if (numberOfBackSpaces === 0) {
      numberOfBackSpaces = 6
    }
    let beginText = beginOfLineCursor + spacesOnThisLine
    quillEditor.deleteText(beginText - numberOfBackSpaces, numberOfBackSpaces)
  }

  handleEnter = (e, cursorPosition, quillEditor) => {
    const text = quillEditor.getText()
    //DWD TODO - if we hit enter. look at all chars to left and figure out if they represent a header. if so, tab in the entered text that was to the right of your cursor

    /*
    Things we need to do this right
    1. Number of spaces on this line
    2. Position of cursor (is it after the spaces or before)
    3. Actual text after spaces
    */
    let cursorAtBeginning = getStartPosition2(text, cursorPosition)
    let spacesOnThisLine = getSpacesAtBeginningOfLine(text, cursorPosition)
    let isCursorAfterText =
      cursorPosition - 1 > cursorAtBeginning + spacesOnThisLine

    // Do math to figure out how many spaces to throw in
    let textOnThisLine = getLineText(text, cursorPosition).trim()

    let isHeader = Parser.lapify2({ string: textOnThisLine }).length === 0 // May need to rethink this
    if (
      cursorPosition - 1 === cursorAtBeginning ||
      (spacesOnThisLine === 0 && !isHeader)
    ) {
      quillEditor.insertText(cursorPosition, "\n")
      return
    }

    e.preventDefault()

    // The cursor is in the whitespace. Add in this amount of spaces.
    // Basically move everything down one space
    if (!isCursorAfterText) {
      const spaceString = generateSpaces(spacesOnThisLine)
      const toAdd = "\n" + spaceString
      quillEditor.insertText(cursorPosition, toAdd)
      return
    }

    let spacesAfterEnter = spacesOnThisLine
    //Possibly don't need the second check textOnThisLine
    if (isHeader && textOnThisLine !== "") {
      // e.preventDefault();
      // handleScroll($(this.refs.textMan), 'enter', !this.props.isAndroid)
      spacesAfterEnter += 6
    }
    // Create the spaces string
    const spaceString = generateSpaces(spacesAfterEnter)
    const toAdd = "\n" + spaceString
    quillEditor.insertText(cursorPosition, toAdd)
  }

  handleSpace = (e, cursorPosition, quillEditor) => {
    const text = quillEditor.getText()

    // If the cursor is after text, return from this function and do the default space addition
    if (afterText(text, cursorPosition)) return // Do the default handling
    // If you got here, that means that you are do our doctered space. So in that case preventDefault action
    // after this function
    e.preventDefault()
    let spacesOnThisLine = getSpacesAtBeginningOfLine(text, cursorPosition)
    let numberOfSpacesToAdd = 6 - (spacesOnThisLine % 6)
    let spaceString = generateSpaces(numberOfSpacesToAdd)

    quillEditor.insertText(cursorPosition, spaceString)
  }

  handleTab = (e, cursorPosition, quillEditor) => {
    e.preventDefault()

    const text = quillEditor.getText()
    // Possibly move the cursor up to right before the text when you tab before the text
    let cursorAtBeginning = getStartPosition2(text, cursorPosition - 1)
    let spacesOnThisLine = getSpacesAtBeginningOfLine(text, cursorPosition - 1)
    let isCursorAfterText =
      cursorPosition - 1 > cursorAtBeginning + spacesOnThisLine

    if (isCursorAfterText) {
      quillEditor.insertText(cursorPosition, "      ")
    } else {
      const numberOfSpacesToAdd = 6 - (spacesOnThisLine % 6)
      const spaceString = generateSpaces(numberOfSpacesToAdd)
      quillEditor.insertText(cursorPosition, spaceString)
    }
  }

  handleBlur = () => {
    this.setState({ visibleWidgetName: "" })
    if (this.props.setCurrentSet) {
      this.props.setCurrentSet("", [])
    }
  }

  handleKeyDown = (e) => {
    //if we are already scheduled to save and we put a keydown (whether it is just an arrow key or actually typing, we should restarrt the clock so the save function doesn't interfere with the client throead that's handling the cursor position on the textarea)
    const quillEditor = this.refs.textManFancy.getEditor()
    const cursorPosition = get(quillEditor, "selection.lastRange.index", 0)

    // Prevent undo operation in textarea, it fucks with the state of the cursor and is
    // inconsistent across browsers, and mobile browsers
    if ((e.keyCode === 90 && e.metaKey) || (e.keyCode === 90 && e.ctrlKey)) {
      e.preventDefault()
      return
    }

    // If a widget is present
    if (this.state.visibleWidgetName === "AutoSuggestGroups") {
      if (e.keyCode === 13) {
        let selectedName = this.refs.AutoSuggestGroups.getSelectedName()
        let existingName = this.getPartialName(
          quillEditor.getText(),
          cursorPosition
        )
        if (existingName === selectedName)
          this.handleEnter(e, cursorPosition, quillEditor)
        else {
          e.preventDefault()
          let curName = selectedName + " " // The space is needed to put a space after the name
          curName = curName.slice(existingName.length)
          quillEditor.insertText(cursorPosition, curName)
        }
      }

      // need to handle tab and backspace as per normal BECAUSE we don't allow the native events to fire.
      if (e.keyCode === 9) {
        this.handleTab(e, cursorPosition, quillEditor)
      }
      if (e.keyCode === 8) {
        this.handleBackspace(e, cursorPosition, quillEditor)
      }

      // Up arrow
      if (e.keyCode === 38) {
        let didWork = this.refs.AutoSuggestGroups.decreaseIndexIfPossible()
        if (didWork) {
          e.preventDefault()
        }
      }

      // Down arrow
      if (e.keyCode === 40) {
        let didWork = this.refs.AutoSuggestGroups.increaseIndexIfPossible()
        if (didWork) {
          e.preventDefault()
        }
      }
    } else {
      if (e.keyCode === 13) {
        this.handleEnter(e, cursorPosition, quillEditor)
      }
      if (e.keyCode === 9) {
        this.handleTab(e, cursorPosition, quillEditor)
      }
      if (e.keyCode === 8) {
        this.handleBackspace(e, cursorPosition, quillEditor)
      }

      if (e.keyCode === 32) {
        this.handleSpace(e, cursorPosition, quillEditor)
      }
    }
  }

  handlePaste = (e) => {
    e.preventDefault()
    let stringToPut = e.clipboardData.getData("text/plain") // Get copied text as string
    stringToPut = stringToPut.replace(/\t/g, "      ") // Convert tabs to spaces
    stringToPut = stringToPut.replace(/\r\n/g, "\n") // Convert lone \r\n to \n
    stringToPut = stringToPut.replace(/\r/g, "\n") // Convert lone \r to \n
    const quillEditor = this.refs.textManFancy.getEditor()
    const range = quillEditor.getSelection()
    if (range) {
      const cursorPosition = range.index
      if (range.length === 0) {
        quillEditor.insertText(cursorPosition, stringToPut)
      } else {
        quillEditor.deleteText(cursorPosition, range.length)
        quillEditor.insertText(cursorPosition, stringToPut)
      }
    } else {
      return
    }
  }

  //render functions or functions related to rendering directly
  getPartialName(text, cursorPosition) {
    let partialNameStart = scanStringForCharacter(
      text,
      cursorPosition,
      "#",
      true
    )
    // partialNameStart is at the end of the text
    return partialNameStart >= text.length
      ? ""
      : text.slice(partialNameStart + 1, cursorPosition)
  }

  renderAutoSuggest() {
    if (this.props.addGroupAutoComplete) {
      const quillEditor = this.refs.textManFancy
        ? this.refs.textManFancy.getEditor()
        : null
      const text = quillEditor ? quillEditor.getText() : ""
      const range = quillEditor ? quillEditor.getSelection() : null
      const index = range ? range.index : 0
      let withoutHashtag = this.getPartialName(text, index)
      const reactNode = ReactDOM.findDOMNode(this.refs.textManFancy)
      if (reactNode) {
        const { top, left } = reactNode.getBoundingClientRect()
        return (
          <AutoSuggestGroups2
            ref="AutoSuggestGroups"
            groupsArray={this.props.groupsArray}
            isVisible={this.state.visibleWidgetName === "AutoSuggestGroups"}
            left={this.state.cursorLeft + left + 16}
            top={this.state.cursorTop + top}
            textBoxHeight={reactNode ? reactNode.clientHeight || 300 : 300}
            partialName={withoutHashtag}
            onGroupClick={(groupText) =>
              this.insertGroup({ index, groupText, withoutHashtag })
            }
          />
        )
      }
      return null
    }
    return null // Display nothing if you are not displaying autosuggest
  }

  render() {
    let {
      setCurrentSet,
      setAllText,
      singleCycle,
      addAutoSave,
      workoutId,
      addGroupAutoComplete,
      groupsArray,
      className,
      startingValue,
      autoFocus,
      setThisLastTouchY,
      listenForWorkoutTextChange,
      isMobile,
      ...other
    } = this.props
    return (
      <div style={{ display: "block" }}>
        <CustomToolbar />
        <ReactQuill
          {...other}
          ref="textManFancy"
          id={"CommitBigBadTextBoxFancy"}
          bounds={"#CommitBigBadTextBoxFancy"}
          modules={CommitTextBox.modules}
          formats={CommitTextBox.formats}
          theme="snow"
          onBlur={this.handleBlur}
          defaultValue={this.props.startingQuillDelta || {}}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onChangeSelection={this.handleChangeSelectionQuill}
          onChange={this.handleChangeQuill}
          className={className ? className + " needsclick" : "needsclick"}
        />
        {this.renderAutoSuggest()}
      </div>
    )
  }
}
// <ReactToPrint
//   trigger={() => (
//     <IconButton>
//       <PrintIcon />
//     </IconButton>
//   )}
//   content={() => this.refs.textManFancy}
// />
const pasteMatcherText = function(node, delta) {
  let ops = []
  delta.ops.forEach((op) => {
    if (op.insert && typeof op.insert === "string") {
      op.insert = op.insert.replace(/\t/g, "      ") // Convert tabs to spaces
      op.insert = op.insert.replace(/\r\n/g, "\n") // Convert lone \r\n to \n
      op.insert = op.insert.replace(/\r/g, "\n") // Convert lone \r to \n

      ops.push({
        insert: op.insert,
      })
    }
  })
  delta.ops = ops
  return delta
}

const pasteMatcherEl = function(node, delta) {
  let ops = []
  delta.ops.forEach((op) => {
    if (op.insert && typeof op.insert === "string") {
      op.insert = op.insert.replace(/\t/g, "      ") // Convert tabs to spaces
      op.insert = op.insert.replace(/\r\n/g, "\n") // Convert lone \r\n to \n
      op.insert = op.insert.replace(/\r/g, "\n") // Convert lone \r to \n

      ops.push({
        insert: op.insert,
      })
    }
  })
  delta.ops = ops
  return delta
}

CommitTextBox.formats = ["bold", "color", "italic", "size", "underline", "link"]

CommitTextBox.modules = {
  // we need to convert tabs to spaces on pa\ste.... so do that here
  clipboard: {
    matchers: [
      [Node.TEXT_NODE, pasteMatcherText],
      [Node.ELEMENT_NODE, pasteMatcherEl],
    ],
  },
  // quill does default things with tab, enter, and backspace: https://quilljs.com/docs/modules/keyboard/#configuration
  // We don't want it to. So we overwrite this here.
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function() {
          return
        },
      },
      enter: {
        key: 13,
        handler: function() {
          return
        },
      },
      backspace: {
        key: 8,
        handler: function() {
          return
        },
      },
    },
  },
  toolbar: {
    container: "#toolbar",
  },
}

export default CommitTextBox
