import React from "react"
import "./_common.css"
import "./quill.css"
import { BrowserRouter } from "react-router-dom"
import OutsideContext from "./layout/OutsideLayout.js"

const immutableRoutesToShutUpWarning = (
  <BrowserRouter>
    <OutsideContext />
  </BrowserRouter>
)

const renderRoutes = () => immutableRoutesToShutUpWarning

export default renderRoutes
