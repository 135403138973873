import PropTypes from "prop-types"
import React from "react"
import { Parser } from "./parser/lib"
import StatsInner from "./StatsInner"
/*
This component takes waitTime from above, and won't re-render unless that waitTime has
elapsed (Useful on landing page).

The case with the Landing page is that we should not re render until the user stops
typing. It also does the splitting when we have a currentTeamId
*/

class StatsTimer extends React.Component {
  static propTypes = {
    setText: PropTypes.object.isRequired,
    secondsPerHundred: PropTypes.number,
    isLanding: PropTypes.bool, //Used to detect if this is landing or not
    waitTime: PropTypes.number,
    genericParserDict: PropTypes.object.isRequired,
    customParserDict: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    metricAbbreviation: PropTypes.string,
    isMobile: PropTypes.bool,
  }

  static defaultProps = {
    waitTime: 0,
  }

  state = {
    stopUpdate: false,
  }

  stopAndWait = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.setState({ stopUpdate: true })
    this.timer = setTimeout(
      () => this.setState({ stopUpdate: false }),
      this.props.waitTime
    )
  }

  componentDidMount() {
    if (this.props.waitTime > 0) this.stopAndWait()
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.waitTime > 0) this.stopAndWait()
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.stopUpdate || !nextState.stopUpdate
  }

  clientRecursiveLapify = () => {
    if (this.props.isLanding) {
      return Parser.recursiveLapifyFromOuterSet({
        setDescription: this.props.setText,
        userDefined: this.props.customParserDict,
      })
    } else {
      return Parser.recursiveLapifyFromOuterSet({
        setDescription: this.props.setText,
        predefined: this.props.genericParserDict,
        userDefined: this.props.customParserDict,
        secondsPerHundred: this.props.secondsPerHundred,
        userDefaultIntensityId: this.props.defaultIntensityLevel,
        userDefaultStrokeId: this.props.defaultStroke,
        userDefaultTypeId: this.props.defaultType,
      })
    }
  }

  render() {
    let laps = this.clientRecursiveLapify()
    return (
      <StatsInner
        laps={laps}
        style={this.props.style}
        isLanding={this.props.isLandingDesign}
        metricAbbreviation={this.props.metricAbbreviation}
        isMobile={this.props.isMobile}
      />
    )
  }
}

export default StatsTimer
