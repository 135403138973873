import React from "react"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import { Facebook, Twitter, Email } from "react-sharingbuttons"
import "react-sharingbuttons/dist/main.css"
import { flatten } from "lodash"
import Button from "@material-ui/core/Button"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import Link from "@material-ui/core/Link"
import { generateRecursiveSetList } from "./parser/parsingHelpers.js"
import { Parser } from "./parser/lib"
import { CommitTextBox } from "./CommitTextBoxDemo"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import TextField from "@material-ui/core/TextField"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DeviceAccessTime from "@material-ui/icons/AccessTime"
import ActionSwapHoriz from "@material-ui/icons/SwapHoriz"
import moment from "moment"
import MobileStepper from "@material-ui/core/MobileStepper"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import StatsTimer from "./StatsTimer.js"
import DistanceList from "./DistanceList"
import ListSubheader from "@material-ui/core/ListSubheader"
import { purple, amber, lightBlue, red } from "@material-ui/core/colors"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  ctaButton: {
    backgroundColor: "#fb8c00",
    color: "white",
    "&:hover": {
      backgroundColor: "#ef6c00",
    },
  },
  fab: {
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
  },
}

const stepOneText =
  "Some basic example sets. Try typing your own!\n\n300\n\n2 x 300s @ 5:00 kick build\n\n8 x 100s on 1:25 odds sprint evens easy\n\n12 x 50s - 4 kick @1:00, 4 paddle pull @:55, 4 swim @:50\n\n300 choice (50 drill 25 swim)\n\n6:00 easy swimming, focus on technique\n"

class Demo extends React.Component {
  state = {
    activeStep: 0, // step of the tutorial
    tutorialSteps: [
      {
        label: "Single line sets",
        text: stepOneText,
      },
      {
        label: "Use your own lingo",
        text: "Try typing a basic set using the term you just defined. Example...",
      },
      {
        label: "Circuits and rounds",
        text: "Check out this circuit:\n\n3x through drill/ swim/ kick\n      100 IM\n      200 Free\n\nWhen Commit detects a circuit and you hit 'enter', it automatically indents the next line for you. Anything indented underneath a circuit is part of it.\n\nTry it yourself below!...\n\n",
      },
      {
        label: "Group specific sets",
        text: "In Commit, you can write one practice for multiple groups. Use group tags to designate sets to a specific group:\n\nWarmup\n      300 choice\n      100 drill/kick\n\n#GroupA\n      12 x 50s pace @ :50\n      200 pull @ 3:15\n\n#GroupB\n      4 x 100s @ 1:20 choice\n      6 x 75s kick fast easy fast",
      },
      {
        label: "Using brackets",
        text: "You can use brackets to tell Commit to ignore something: \n\n4x50s @:50 fly - [strong underwater kick off the wall]\n\nThis tells Commit to ignore the word 'kick' for that set.\n\n",
      },
    ],
    lingoDefined: false,
    lingoTerm: "",
    lingoType: "intensity",
    open: false,
    hidden: false,
    allLaps: flatten(
      generateRecursiveSetList(stepOneText, 0).map((el) =>
        Parser.recursiveLapifyFromOuterSet({
          setDescription: el,
        })
      )
    ),
    currentSet: { text: "", innerDescriptions: [] },
  }
  handleClick = () => {
    this.setState((state) => ({
      open: !state.open,
    }))
  }

  handleDirectionChange = (event, value) => {
    this.setState({
      direction: value,
    })
  }

  handleHiddenChange = (event, hidden) => {
    this.setState((state) => ({
      hidden,
      // hidden implies !open
      open: hidden ? false : state.open,
    }))
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }
  handleNext = () => {
    this.setState((prevState) => {
      let { tutorialSteps } = prevState
      const value = tutorialSteps[prevState.activeStep + 1].text
      const setList = generateRecursiveSetList(value, 0)
      return {
        activeStep: prevState.activeStep + 1,
        allLaps: flatten(
          setList.map((el) =>
            Parser.recursiveLapifyFromOuterSet({
              setDescription: el,
              userDefined: this.getCustomParserDict(),
            })
          )
        ),
      }
    })
  }
  setCurrentSet = (text, innerDescriptions) => {
    const newObj = { text, innerDescriptions }
    this.setState({ currentSet: newObj })
  }

  timeForGroup = (groupId) => {
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )

    return laps.map((el) => el.seconds).reduce((a, b) => a + b, 0)
  }

  displayTime = (groupId) => {
    const seconds = this.timeForGroup(groupId)
    const momentSeconds = moment().startOf("day").seconds(seconds)
    const hours = momentSeconds.hours()
    const minutes = momentSeconds.minutes()
    if (hours === 0) return `${minutes} min`
    return moment().startOf("day").seconds(seconds).format("H:mm")
  }

  getDistanceGivenType = (type, groupId) => {
    const groupedCount = {}
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      if (lap[type]) {
        const typeName = lap[type].name
        const distance = lap.distance
        if (typeName in groupedCount) groupedCount[typeName] += distance
        else groupedCount[typeName] = distance
      }
    })
    const groupedCorrect = []
    for (const name of Object.keys(groupedCount))
      groupedCorrect.push({ name, distance: groupedCount[name] })

    return groupedCorrect
  }

  getDistanceGivenIntensity = (groupId) => {
    const groupedCount = {}
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      if (lap.intensity) {
        let typeName = lap.intensity.name
        if (["1", "2", "3", "4", "5"].includes(typeName)) {
          const intensityLevel = this.props.intensityLevels.find(
            (el) => el._id === lap.intensity._id
          )
          if (intensityLevel) typeName = intensityLevel.name
        }
        const distance = lap.distance
        if (typeName in groupedCount) groupedCount[typeName] += distance
        else groupedCount[typeName] = distance
      }
    })
    const groupedCorrect = []
    for (const name of Object.keys(groupedCount))
      groupedCorrect.push({ name, distance: groupedCount[name] })

    return groupedCorrect
  }

  distanceForGroup = (groupId) => {
    // let myLaps = this.filterGroups();
    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    const totalDistance = laps
      .map((el) => el.distance)
      .reduce((a, b) => a + b, 0)
    return totalDistance.toLocaleString()
  }

  getDistanceForEquipment = (groupId) => {
    const distanceList = []

    const laps = this.state.allLaps.filter(
      (lap) =>
        lap.groups.length === 0 ||
        lap.groups.map((j) => j._id).includes(groupId)
    )
    laps.forEach((lap) => {
      const equipmentsList = lap.equipments
      if (equipmentsList) {
        lap.equipments.forEach((equipObj) => {
          const thisElement = distanceList.find((el) => el._id === equipObj._id)
          if (thisElement) thisElement.distance += lap.distance
          else {
            const newObj = {
              _id: equipObj._id,
              name: equipObj.name,
              distance: lap.distance,
            }
            distanceList.push(newObj)
          }
        })
      }
    })
    for (let i = 0; i < distanceList.length; i++) delete distanceList[i]._id

    return distanceList
  }
  createLingo = () => {
    if (!this.state.lingoTerm) {
      alert("Oops! Define a custom term before continuing.")
      return
    }
    // change the text for this starting value state
    let { tutorialSteps } = this.state
    tutorialSteps[this.state.activeStep].text =
      tutorialSteps[this.state.activeStep].text +
      "\n\n10 x 50s @ 1:30 " +
      this.state.lingoTerm

    const value = tutorialSteps[this.state.activeStep].text
    const setList = generateRecursiveSetList(value, 0)

    this.setState({
      lingoDefined: true,
      tutorialSteps,
      allLaps: flatten(
        setList.map((el) =>
          Parser.recursiveLapifyFromOuterSet({
            setDescription: el,
            userDefined: this.getCustomParserDict(),
          })
        )
      ),
    })
  }

  getCustomParserDict = () => {
    if (!this.state.lingoTerm) {
      return {
        groups: [
          {
            name: "GroupA",
            _id: "groupA",
            allForms: ["#groupa"],
          },
          {
            name: "GroupB",
            _id: "groupB",
            allForms: ["#groupb"],
          },
        ],
      }
    }
    if (this.state.lingoType === "intensity") {
      return {
        intensities: [
          {
            name: this.state.lingoTerm,
            _id: "intensityId",
            allForms: [this.state.lingoTerm.toLowerCase()],
            intensity: 5,
          },
        ],
        groups: [
          {
            name: "GroupA",
            _id: "groupA",
            allForms: ["#groupa"],
          },
          {
            name: "GroupB",
            _id: "groupB",
            allForms: ["#groupb"],
          },
        ],
      }
    }

    if (this.state.lingoType === "stroke") {
      return {
        strokes: [
          {
            name: this.state.lingoTerm,
            _id: "strokeId",
            allForms: [this.state.lingoTerm.toLowerCase()],
          },
        ],
        groups: [
          {
            name: "GroupA",
            _id: "groupA",
            allForms: ["#groupa"],
          },
          {
            name: "GroupB",
            _id: "groupB",
            allForms: ["#groupb"],
          },
        ],
      }
    }

    if (this.state.lingoType === "type") {
      return {
        types: [
          {
            name: this.state.lingoTerm,
            _id: "typeId",
            allForms: [this.state.lingoTerm.toLowerCase()],
          },
        ],
        groups: [
          {
            name: "GroupA",
            _id: "groupA",
            allForms: ["#groupa"],
          },
          {
            name: "GroupB",
            _id: "groupB",
            allForms: ["#groupb"],
          },
        ],
      }
    }
  }

  setAllText = (value) => {
    let { tutorialSteps } = this.state
    tutorialSteps[this.state.activeStep].text = value
    this.setState({ tutorialSteps })

    const setList = generateRecursiveSetList(value, 0)
    this.setState({
      allLaps: flatten(
        setList.map((el) =>
          Parser.recursiveLapifyFromOuterSet({
            setDescription: el,
            userDefined: this.getCustomParserDict(),
          })
        )
      ),
    })
  }
  handleBack = () => {
    this.setState((prevState) => {
      let { tutorialSteps } = prevState
      const value = tutorialSteps[prevState.activeStep - 1].text
      const setList = generateRecursiveSetList(value, 0)
      return {
        activeStep: prevState.activeStep - 1,
        allLaps: flatten(
          setList.map((el) =>
            Parser.recursiveLapifyFromOuterSet({
              setDescription: el,
              userDefined: this.getCustomParserDict(),
            })
          )
        ),
      }
    })
  }
  render() {
    const { classes } = this.props
    const { activeStep, tutorialSteps } = this.state
    const maxSteps = tutorialSteps.length
    let nameWidth = "25%"
    let iconWidths = "75%"
    return (
      <div
        style={{
          minHeight: "100vh",
          paddingTop: "8px",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "8px" }}>
          <a
            href="https://commitswimming.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Commit Logo"
              src={"/logo-dark.svg"}
              onClick={this.goToHome}
              style={{ height: "32px", cursor: "pointer", margin: "auto" }}
            />
          </a>
        </div>

        <div
          style={{ maxWidth: "1200px", margin: "auto", paddingBottom: "16px" }}
        >
          <div
            className="commitflex"
            style={{
              justifyContent: "space-around",
              WebkitJustifyContent: "space-around",
              flexFlow: "row wrap",
              WebkitFlexFlow: "row wrap",
              padding: "16px 8px",
            }}
          >
            <div style={{ maxWidth: "650px", width: "100%" }}>
              <Paper
                elevation={1}
                style={{
                  maxWidth: "400px",
                  padding: "8px",
                  backgroundColor: "#e0f2f1",
                  borderRadius: "8px",
                  margin: "auto",
                }}
              >
                <div
                  className="commitflex commitcenteralign"
                  style={{
                    height: "16px",
                    padding: "8px",
                    justifyContent: "center",
                    WebkitJustifyContent: "center",
                  }}
                >
                  <Typography variant="body1" style={{ marginRight: "16px" }}>
                    Demo Mode!
                  </Typography>
                  <Typography variant="body2">
                    <Link href={"/what-is-commit-swimming"}>
                      Click here to learn more.
                    </Link>
                  </Typography>
                </div>
                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                  {tutorialSteps[activeStep].label}
                </Typography>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  nextButton={
                    <Button
                      size="small"
                      onClick={this.handleNext}
                      style={
                        activeStep === maxSteps - 1
                          ? {
                              visibility: "hidden",
                              textTransform: "none",
                              borderRadius: "8px",
                              marginTop: "-50px",
                            }
                          : {
                              textTransform: "none",
                              borderRadius: "8px",
                              marginTop: "-50px",
                            }
                      }
                    >
                      Next
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={this.handleBack}
                      style={
                        activeStep === 0
                          ? {
                              visibility: "hidden",
                              textTransform: "none",
                              borderRadius: "8px",
                              marginTop: "-50px",
                            }
                          : {
                              textTransform: "none",
                              borderRadius: "8px",
                              marginTop: "-50px",
                            }
                      }
                    >
                      <KeyboardArrowLeft />
                      Back
                    </Button>
                  }
                />
              </Paper>
              <Paper
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  marginTop: "8px",
                  height: this.props.isMobile ? 440 : 460,
                  WebkitOverflowScrolling: "touch",
                  overflow: this.context.isTouch ? "scroll" : "auto",
                }}
              >
                {this.state.activeStep === 1 && !this.state.lingoDefined ? (
                  <div style={{ padding: "16px", textAlign: "center" }}>
                    <Typography key="instructions" variant="body1">
                      Define your own term. On the next screen we'll use it to
                      write a set.
                    </Typography>
                    <TextField
                      key="selecttype"
                      select
                      label="Term type"
                      value={this.state.lingoType}
                      onChange={(e) =>
                        this.setState({ lingoType: e.target.value })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      helperText="Please select the type of term"
                      margin="normal"
                      variant="outlined"
                    >
                      <option key={"intensity"} value={"intensity"}>
                        Intensity (ex. "EN1" or "Red")
                      </option>
                      <option key={"stroke"} value={"stroke"}>
                        Stroke (ex. "Reverse IM")
                      </option>
                      <option key={"type"} value={"type"}>
                        Type (ex. "Scull")
                      </option>
                    </TextField>
                    <br />
                    <br />
                    <TextField
                      key="term"
                      label="Define your term"
                      margin="dense"
                      value={this.state.lingoTerm}
                      onChange={(e) =>
                        this.setState({
                          lingoTerm: e.target.value,
                        })
                      }
                      variant="outlined"
                    />
                    <br />
                    <br />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={this.createLingo}
                    >
                      Create
                      <ArrowForward style={{ marginLeft: "4px" }} />
                    </Button>
                  </div>
                ) : (
                  [
                    <CommitTextBox
                      key="textbox"
                      style={{
                        WebkitUserSelect: "text",
                        userSelect: "text",
                        MozUserSelect: "text",
                        MsUserSelect: "text",
                        borderRadius: "8px",
                        WebkitOverflowScrolling: "touch",
                        overflow: "auto",
                        height: 360,
                        color: "black",
                        width: "100%",
                        margin: "0px",
                        verticalAlign: "bottom",
                        padding: "16px",
                        backgroundColor: "white",
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontFamily: "sans-serif",
                        border: "none",
                        resize: "none",
                        outline: "none",
                        WebkitBoxShadow: "none",
                        MozBoxShadow: "none",
                        boxShadow: "none",
                        boxSizing: "border-box",
                        whiteSpace: "pre-wrap",
                      }}
                      autoCapitalize="off"
                      autoComplete="off"
                      spellCheck="false"
                      autoCorrect="off"
                      listenForWorkoutTextChange={true}
                      setAllText={this.setAllText}
                      setAllTextContinuously
                      setCurrentSet={this.setCurrentSet}
                      startingValue={this.state.tutorialSteps[activeStep].text}
                      placeholder="Write your sets here... Just type!"
                    />,
                    <div style={{ padding: "0px 16px" }} key="setstats">
                      <StatsTimer
                        setText={this.state.currentSet}
                        isLanding
                        isLandingDesign
                        style={{
                          minHeight: this.props.isMobile ? "80px" : "100px",
                          fontWeight: 500,
                          fontSize: "13px",
                        }}
                        waitTime={150}
                        genericParserDict={{}}
                        customParserDict={this.getCustomParserDict()}
                        isMobile={this.props.isMobile}
                      />
                    </div>,
                  ]
                )}
              </Paper>
              <div style={{ textAlign: "center" }}>
                <Fab
                  variant="extended"
                  key="cta"
                  size="medium"
                  aria-label="Give Commit a Try"
                  className={classes.ctaButton}
                  style={{
                    textTransform: "none",
                    margin: "16px",
                  }}
                  href="https://workouts.commitswimming.com/get-started/"
                >
                  Give Commit a try today
                  <ArrowForward style={{ marginLeft: "4px" }} />
                </Fab>
              </div>
            </div>
            <div style={{ maxWidth: "450px", width: "100%" }}>
              <div
                className="commitcenteralign commitflex"
                style={{
                  height: "92px",
                  justifyContent: "center",
                  WebkitJustifyContent: "center",
                }}
              >
                <Facebook url={"https://commitswimming.com/demo"} />
                <Twitter
                  url={"https://commitswimming.com/demo"}
                  shareText={
                    "Simple and powerful digital swimming workout log. Check it out!"
                  }
                />
                <Email
                  subject={"Interesting demo of Commit Swimming"}
                  url={"https://commitswimming.com/demo"}
                />
              </div>
              {activeStep === 3 ? (
                <div>
                  <ExpansionPanel
                    defaultExpanded
                    style={{ borderRadius: "8px" }}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div
                        className="commitflex commitcenteralign"
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            width: nameWidth,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {"GroupA"}
                        </div>
                        <div
                          className="commitflex commitcenteralign"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            width: iconWidths,
                          }}
                        >
                          <div className="commitflex commitcenteralign">
                            <ActionSwapHoriz
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {this.distanceForGroup("groupA")}
                          </div>
                          <div className="commitflex commitcenteralign">
                            <DeviceAccessTime
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {this.displayTime("groupA")}
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ width: "100%" }}>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Strokes
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenType(
                                "stroke",
                                "groupA"
                              )}
                              style={{ color: purple[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Types
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenType(
                                "type",
                                "groupA"
                              )}
                              style={{ color: amber[800] }}
                            />
                          </div>
                        </div>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Intensity
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenIntensity(
                                "groupA"
                              )}
                              style={{ color: lightBlue[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Equipment
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceForEquipment(
                                "groupA"
                              )}
                              style={{ color: red[800] }}
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    defaultExpanded
                    style={{ borderRadius: "8px" }}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div
                        className="commitflex commitcenteralign"
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            width: nameWidth,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {"GroupB"}
                        </div>
                        <div
                          className="commitflex commitcenteralign"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            width: iconWidths,
                          }}
                        >
                          <div className="commitflex commitcenteralign">
                            <ActionSwapHoriz
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {this.distanceForGroup("groupB")}
                          </div>
                          <div className="commitflex commitcenteralign">
                            <DeviceAccessTime
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {this.displayTime("groupB")}
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ width: "100%" }}>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Strokes
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenType(
                                "stroke",
                                "groupB"
                              )}
                              style={{ color: purple[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Types
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenType(
                                "type",
                                "groupB"
                              )}
                              style={{ color: amber[800] }}
                            />
                          </div>
                        </div>
                        <div
                          className="commitflex"
                          style={{
                            justifyContent: "space-around",
                            WebkitJustifyContent: "space-around",
                            padding: "8px 0px 8px 0px",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Intensity
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceGivenIntensity(
                                "groupB"
                              )}
                              style={{ color: lightBlue[800] }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <ListSubheader
                              style={{
                                paddingLeft: "0px",
                                fontSize: "12px",
                                lineHeight: "2em",
                              }}
                            >
                              Equipment
                            </ListSubheader>
                            <DistanceList
                              distanceList={this.getDistanceForEquipment(
                                "groupB"
                              )}
                              style={{ color: red[800] }}
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              ) : (
                <ExpansionPanel defaultExpanded style={{ borderRadius: "8px" }}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Workout Stats</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{ width: "100%" }}>
                      <div
                        className="commitflex"
                        style={{
                          justifyContent: "space-around",
                          WebkitJustifyContent: "space-around",
                          padding: "8px 0px 8px 0px",
                        }}
                      >
                        <div style={{ width: "45%" }}>
                          <ListSubheader
                            style={{
                              paddingLeft: "0px",
                              fontSize: "12px",
                              lineHeight: "2em",
                            }}
                          >
                            Strokes
                          </ListSubheader>
                          <DistanceList
                            distanceList={this.getDistanceGivenType(
                              "stroke",
                              "groupA"
                            )}
                            style={{ color: purple[800] }}
                          />
                        </div>
                        <div style={{ width: "45%" }}>
                          <ListSubheader
                            style={{
                              paddingLeft: "0px",
                              fontSize: "12px",
                              lineHeight: "2em",
                            }}
                          >
                            Types
                          </ListSubheader>
                          <DistanceList
                            distanceList={this.getDistanceGivenType(
                              "type",
                              "groupA"
                            )}
                            style={{ color: amber[800] }}
                          />
                        </div>
                      </div>
                      <div
                        className="commitflex"
                        style={{
                          justifyContent: "space-around",
                          WebkitJustifyContent: "space-around",
                          padding: "8px 0px 8px 0px",
                        }}
                      >
                        <div style={{ width: "45%" }}>
                          <ListSubheader
                            style={{
                              paddingLeft: "0px",
                              fontSize: "12px",
                              lineHeight: "2em",
                            }}
                          >
                            Intensity
                          </ListSubheader>
                          <DistanceList
                            distanceList={this.getDistanceGivenIntensity(
                              "groupA"
                            )}
                            style={{ color: lightBlue[800] }}
                          />
                        </div>
                        <div style={{ width: "45%" }}>
                          <ListSubheader
                            style={{
                              paddingLeft: "0px",
                              fontSize: "12px",
                              lineHeight: "2em",
                            }}
                          >
                            Equipment
                          </ListSubheader>
                          <DistanceList
                            distanceList={this.getDistanceForEquipment(
                              "groupA"
                            )}
                            style={{ color: red[800] }}
                          />
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Demo)
