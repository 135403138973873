import PropTypes from "prop-types"
import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Paper from "@material-ui/core/Paper"

class AutoSuggestGroups extends React.Component {
  static contextTypes = {
    isMobile: PropTypes.bool,
  }

  static propTypes = {
    groupsArray: PropTypes.array.isRequired, // GroupsList
    partialName: PropTypes.string.isRequired, // #All
    isVisible: PropTypes.bool.isRequired, //Can we see this widget
    left: PropTypes.number.isRequired, // Location of widget
    top: PropTypes.number.isRequired, // Location of widget
    textBoxHeight: PropTypes.number.isRequired,
    onGroupClick: PropTypes.func.isRequired,
    setDisableBlur: PropTypes.func.isRequired,
  }

  state = { index: 0 }

  filterGroupsByName = (partialName, groupsArray) => {
    if (this.props.partialName === "") {
      return groupsArray
    } else {
      let partialLength = this.props.partialName.length
      let filtered = []
      for (let i = 0; i < groupsArray.length; i++) {
        if (
          groupsArray[i].name.toLowerCase().slice(0, partialLength) ===
          partialName.toLowerCase()
        )
          filtered.push(groupsArray[i])
      }
      if (
        filtered.length === 1 &&
        filtered[0].name.toLowerCase() === partialName.toLowerCase()
      )
        return []
      return filtered
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //need this to reset the index when appropriate
    if (
      (nextProps.left !== this.props.left &&
        nextProps.top !== this.props.top) ||
      this.props.isVisible !== nextProps.isVisible
    )
      this.setState({ index: 0 })
  }

  increaseIndexIfPossible = () => {
    if (
      this.state.index <
      this.filterGroupsByName(this.props.partialName, this.props.groupsArray)
        .length -
        1
    ) {
      this.setState({ index: this.state.index + 1 })
      return true
    }
    return false
  }

  decreaseIndexIfPossible = () => {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 })
      return true
    }
    return false
  }

  getStyle = (i) => {
    if (this.state.index === i) return { backgroundColor: "rgba(0, 0, 0, .1)" }
    else return {}
  }

  getSelectedName = () => {
    let name = this.props.partialName
    if (
      this.filterGroupsByName(this.props.partialName, this.props.groupsArray)[
        this.state.index
      ]
    )
      name = this.filterGroupsByName(
        this.props.partialName,
        this.props.groupsArray
      )[this.state.index]["name"]
    return name
  }

  handleGroupClick = (name) => {
    this.props.onGroupClick(name)
  }

  getHeight = () => {
    let height = 0.5 * 360
    if (height < 120) height = 120
    return height
  }

  getActualHeight = (nGroups) => {
    let height = nGroups * 36
    if (height > this.getHeight()) height = this.getHeight()

    return height
  }

  render() {
    if (!this.props.isVisible) {
      this.props.setDisableBlur(false)
      return null
    }

    let filteredGroups = this.filterGroupsByName(
      this.props.partialName,
      this.props.groupsArray
    )
    let groups = filteredGroups.map((el, i) => {
      return (
        <ListItem
          style={this.getStyle(i)}
          key={el._id}
          button
          onMouseDown={this.handleMouseDown}
          onClick={() => this.handleGroupClick(el.name)}
        >
          <ListItemText primary={el.name} />
        </ListItem>
      )
    })
    if (groups.length === 0) {
      this.props.setDisableBlur(false)
      return null
    }

    let top = this.props.top + 12

    if (
      this.props.textBoxHeight + 64 <
        this.props.top + 12 + this.getActualHeight(filteredGroups.length) &&
      this.props.isMobile
    ) {
      top = this.props.top + 12 - this.getActualHeight(filteredGroups.length)
    }
    this.props.setDisableBlur(true)
    return (
      <Paper
        style={{
          position: "absolute",
          top,
          left: this.props.left,
          maxHeight: this.getHeight(),
          minWidth: "100px",
          WebkitOverflowScrolling: "touch",
          overflowY: "scroll",
          zIndex: 2,
        }}
      >
        <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
          {groups}
        </List>
      </Paper>
    )
  }
}

export default AutoSuggestGroups
