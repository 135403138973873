import PropTypes from "prop-types"
/*
  Helper functions that are used in the Commit Text Box and
  the group stats, and the single stats

  Basically these functions take in a block of text, and
  a cursorPosition, and aim to answer generic questions
*/

/*          String Helper Functions
////////////////////////////////////////////////////////
*/
import React from "react"
import ReactDOM from "react-dom"
import { Parser } from "./parser/lib"
import AutoSuggestGroups from "./AutoSuggestGroups"
import $ from "jquery"
import { handleScroll, getCaretPosition } from "./helpers-2.js"
import { debounce } from "lodash"
import {
  generateRecursiveSetObject,
  scanStringForCharacter,
  getStartPosition2,
  getSpacesAtBeginningOfLine,
  getLineText,
} from "./parser/parsingHelpers.js"
import getCaretCoordinates from "textarea-caret"

/*            End Helper Functions
////////////////////////////////////////////////////////
*/

/*
  The main idea of the Commit Text Box is to be the primary holder of complexity.
  It aims to take care of the workout writing, group autocomplete,
  database saving, and set favoriting.

  It also aims to provide the same experience on any browser, and mobile device.

  In the future, I hope to support set autocomplete features based on history.

  The complexity of this widget is "opt-in", meaning that one has to manually decide which
  of the features one supports. For example the landing page doesn't have the group
  autocomplete and the set favoriting.
*/

const generateSpaces = function(numb) {
  let start = ""
  for (let i = 0; i < numb; i++) {
    start += " "
  }
  return start
}

const afterText = function(text, cursorPosition) {
  if (cursorPosition === 0) return false
  if (cursorPosition > text.length) return true

  let cur = cursorPosition - 1
  while (text[cur] !== "\n" && cur >= 0) {
    if (text[cur] !== " ") return true
    cur = cur - 1
  }
  return false
}

const canSummonGroupsList = function(text, cursorPosition) {
  if (cursorPosition <= 0) return false
  // The next character has to be a space
  let pointedAt = text.charAt(cursorPosition)
  if (![" ", "", "\n"].includes(pointedAt)) {
    return false
  }

  let start = scanStringForCharacter(
    text,
    cursorPosition - 1,
    ["\n", "", " "],
    true
  )
  let begPos = start
  if (["\n", "", " "].includes(text.charAt(start))) begPos = start + 1

  return text.charAt(begPos) === "#"
}

const getBeginningAndEndOfGroupsList2 = function(text, cursorPosition) {
  let start = scanStringForCharacter(
    text,
    cursorPosition - 1,
    ["\n", "", " "],
    true
  )

  if (["\n", "", " "].includes(text.charAt(start)))
    return { begin: start + 2, end: cursorPosition }
  else return { begin: start + 1, end: cursorPosition }
}

class CommitTextBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.startingValue || "", // Full text of the entire text box
      visibleWidgetName: "", // When this is set to the name of the widget that widget becomes visible
      cursorTop: 0, // Pixel value of the cursor from the top
      cursorLeft: 0, // Pixel value of the cursor from the left
    }

    // Kvothe make sure this still works
    //update variables outside this component to communicate with other components
    this.lazySetAllText = debounce(() => {
      this.props.setAllText(this.state.value)
    }, 600)
  }

  static contextTypes = {
    isTouch: PropTypes.bool,
    isAndroid: PropTypes.bool,
    isIos: PropTypes.bool,
  }

  static propTypes = {
    startingValue: PropTypes.string, // Value to seed the commitTextBox
    setAllText: PropTypes.func, // Session key where all the text is put

    setCurrentSet: PropTypes.func, // Function that sets state in upper widget to what the current set is
    cycleStrings: PropTypes.array, //Optional array. Cycles strings until user input
    singleCycle: PropTypes.bool, //if true, it assumes only 1 item in the cyclestrings array and stops after the first item.
    listenForWorkoutTextChange: PropTypes.bool,

    // Needed for Autosaving to the Database
    addAutoSave: PropTypes.bool.isRequired, // Set to false if you don't want to save to the database (landing page)
    workoutId: PropTypes.string, // Needed for saving of the workout

    // Needed for the Groups Autocomplete
    addGroupAutoComplete: PropTypes.bool.isRequired, // Set to false if you don't want groupAutocomplete (landing page)
    groupsArray: PropTypes.array.isRequired, // Needed for groups array hover thing

    autoFocus: PropTypes.bool,
  }

  //Life cycle stuff
  static defaultProps = {
    cycleStrings: [],
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //update the value of the textbox if new props come through with a different value than the current state AND we're not actively in the textbox
    //examples include... set bank adding a set to the workout AND other coache editing the workout on their machine
    if (
      nextProps.startingValue !== this.props.startingValue &&
      nextProps.startingValue !== this.state.value &&
      !$(this.refs.textMan).is(":focus") &&
      nextProps.listenForWorkoutTextChange
    ) {
      this.setState(
        { value: nextProps.startingValue || "" },
        this.updateSessionVariables
      )
      // this.setCurrentSetWithProps(nextProps)
    }

    //for onboarding flow
    if (
      nextProps.cycleStrings &&
      nextProps.cycleStrings.length > 0 &&
      this.props.cycleStrings[0] !== nextProps.cycleStrings[0]
    ) {
      //      debugger;
      this.typewrite(nextProps.cycleStrings[0])
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.style.height !== nextProps.style.height) {
      return true
    }

    if (this.state.visibleWidgetName !== nextState.visibleWidgetName) {
      return true
    }

    if (nextState.visibleWidgetName.length > 0) {
      // this needs to be here in case we click from 1 # to another #, we want to re-render autosuggest groups (just a different version)
      return true
    }

    if (this.state.value !== nextState.value) {
      return true
    }

    return false
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      ReactDOM.findDOMNode(this.refs.textMan).focus()
    }

    if (this.props.cycleStrings && this.props.cycleStrings.length) {
      this.typewrite(this.props.cycleStrings[0])
    }
    // // When this component mounts the very first time create some state that is useful for "Saving / Updating"
    this.status = "AllChangesSaved"
  }

  //uses 3rd party plugin to get pixel coordinates given text and cursor position within text
  //this is used in the auto suggest groups widget when users type #...
  calcCursorCoordinates = () => {
    const cursorPosition = this.getCursorPosition()
    const coords = getCaretCoordinates(this.refs.textMan, cursorPosition)
    coords.top =
      this.refs.textMan.offsetTop - this.refs.textMan.scrollTop + coords.top
    coords.left =
      this.refs.textMan.offsetLeft - this.refs.textMan.scrollLeft + coords.left
    this.setState({ cursorTop: coords.top, cursorLeft: coords.left })
  }

  lazySetCurrentSet = (cursorPosition) => {
    let newSet = generateRecursiveSetObject(this.state.value, cursorPosition)
    this.props.setCurrentSet(newSet.text, newSet.innerDescriptions)
  }

  updateSessionVariables = () => {
    const cursorPosition = this.getCursorPosition()

    // These chunks are useful for when we want to communicate to other widgets
    if (this.props.setAllText) {
      if (this.props.setAllTextContinuously) {
        this.props.setAllText(this.state.value)
      } else {
        this.lazySetAllText()
      }
    }
    if (this.props.setCurrentSet) {
      this.lazySetCurrentSet(cursorPosition)
    }

    //determine whether to render the groupslist widget
    if (!this.textIsHighlighted()) {
      if (
        canSummonGroupsList(
          this.state.value,
          cursorPosition,
          this.props.groupsArray
        ) &&
        $(this.refs.textMan).is(":focus")
      ) {
        // Calc coordinates only works on the web
        this.calcCursorCoordinates()
        this.setState({ visibleWidgetName: "AutoSuggestGroups" })
      } else {
        this.setState({ visibleWidgetName: "" })
      }
    }
  }

  setDisableBlur = (newVal) => {
    this.disableBlur = newVal
  }

  //sets new value after user selects a group from the autosuggest widget after typing '#'
  insertGroup = (groupText) => {
    let curName = groupText + " " // The space is needed to put a space after the name
    let startStop = getBeginningAndEndOfGroupsList2(
      this.state.value,
      this.getCursorPosition()
    )
    let newState =
      this.state.value.slice(0, startStop.begin) +
      curName +
      this.state.value.slice(startStop.end)
    let newCursorPosition = startStop.begin + curName.length

    //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
    this.setState({ value: newState }, () =>
      this.setCursorPosition(newCursorPosition)
    )
  }

  //functions to set and get the current cursorposition
  setCursorPosition = (newCursor) => {
    this.refs.textMan.setSelectionRange(newCursor, newCursor)
  }

  getCursorPosition = () => {
    if (this.refs.textMan) {
      return $(this.refs.textMan)[0].selectionStart
    } else {
      return 0
    }
  }

  //returns true if there is highlighted text in the textarea
  textIsHighlighted = () => {
    if (this.refs.textMan) {
      return (
        $(this.refs.textMan)[0].selectionStart !==
        $(this.refs.textMan)[0].selectionEnd
      )
    } else {
      return false
    }
  }

  //function that's called on touch iOS devices to jam the cursor in the correct spot based on user touch.
  setSelectionRange = () => {
    //x position within the textarea is where the user tapped minus the padding minus any offset of the actual textarea element itself from the left
    let xPosition =
      this.lastTouchX -
      parseInt($(this.refs.textMan).css("padding-left"), 10) -
      $(this.refs.textMan).offset().left
    //y position within the textarea is where the user tapped plus any invisible part of the textarea minus the padding minus any offset of the actual textarea element itself from the top
    let yPosition =
      this.lastTouchY +
      $(this.refs.textMan).scrollTop() -
      $(this.refs.textMan).offset().top -
      parseInt($(this.refs.textMan).css("padding-top"), 10)
    //use x and y within textbox to get caret position
    let caretPosition = getCaretPosition(
      xPosition,
      yPosition,
      $(this.refs.textMan)
    )
    //set the selection range of the element
    this.setCursorPosition(caretPosition)
    // this.updateSessionVariables()
  }

  //Beginning of functions that handle user actions that we are totally cool with
  handleChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ value: e.target.value })
  }

  handleBackspace = (e) => {
    const cursorPosition = this.getCursorPosition()

    // Deal with highlighted text case
    if (this.textIsHighlighted()) {
      return
    }

    // Deal backspace at beginning of document case (do nothing)
    if (cursorPosition === 0) return

    if (afterText(this.state.value, cursorPosition)) {
      return // Do the default handling
    }

    // Cursor is at the beginning of the line
    let cursorAtBeginning = getStartPosition2(this.state.value, cursorPosition)
    if (cursorPosition === cursorAtBeginning) return

    // If you got this far, we are doing our franken backspace
    e.preventDefault()
    handleScroll($(this.refs.textMan), !this.context.isAndroid)

    // Only remove 6 characters back if you are at the front of a row of text
    let spacesOnThisLine = getSpacesAtBeginningOfLine(
      this.state.value,
      cursorPosition
    )
    let beginOfLineCursor = getStartPosition2(this.state.value, cursorPosition)

    let numberOfBackSpaces = spacesOnThisLine % 6
    if (numberOfBackSpaces === 0) {
      numberOfBackSpaces = 6
    }
    let beginText = beginOfLineCursor + spacesOnThisLine
    let newState =
      this.state.value.slice(0, beginText - numberOfBackSpaces) +
      this.state.value.slice(beginText)
    let newCursorPosition = beginText - numberOfBackSpaces

    //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
    this.setState({ value: newState }, () =>
      this.setCursorPosition(newCursorPosition)
    )
  }

  handleEnter = (e) => {
    const cursorPosition = this.getCursorPosition()
    //DWD TODO - if we hit enter. look at all chars to left and figure out if they represent a header. if so, tab in the entered text that was to the right of your cursor

    /*
    Things we need to do this right
    1. Number of spaces on this line
    2. Position of cursor (is it after the spaces or before)
    3. Actual text after spaces
    */
    let cursorAtBeginning = getStartPosition2(this.state.value, cursorPosition)
    let spacesOnThisLine = getSpacesAtBeginningOfLine(
      this.state.value,
      cursorPosition
    )
    let isCursorAfterText =
      cursorPosition > cursorAtBeginning + spacesOnThisLine

    // Do math to figure out how many spaces to throw in
    let textOnThisLine = getLineText(this.state.value, cursorPosition).trim()

    let isHeader = Parser.lapify2({ string: textOnThisLine }).length === 0 // May need to rethink this

    if (
      cursorPosition === cursorAtBeginning ||
      (spacesOnThisLine === 0 && !isHeader)
    ) {
      return
    }

    e.preventDefault()
    handleScroll($(this.refs.textMan), !this.context.isAndroid)

    // The cursor is in the whitespace. Add in this amount of spaces.
    // Basically move everything down one space
    if (!isCursorAfterText) {
      // e.preventDefault();
      // handleScroll($(this.refs.textMan), 'enter', !this.props.isAndroid)
      let spaceString = generateSpaces(spacesOnThisLine)
      let newState =
        this.state.value.slice(0, cursorAtBeginning) +
        spaceString +
        "\n" +
        this.state.value.slice(cursorAtBeginning)

      let newCursor = cursorPosition + 1 + spaceString.length

      //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
      this.setState({ value: newState }, () =>
        this.setCursorPosition(newCursor)
      )
      return
    }

    let spacesAfterEnter = spacesOnThisLine
    //Possibly don't need the second check textOnThisLine
    if (isHeader && textOnThisLine !== "") {
      // e.preventDefault();
      // handleScroll($(this.refs.textMan), 'enter', !this.props.isAndroid)
      spacesAfterEnter += 6
    }

    // Create the spaces string
    let spaceString = generateSpaces(spacesAfterEnter)
    let newState =
      this.state.value.slice(0, cursorPosition) +
      "\n" +
      spaceString +
      this.state.value.slice(cursorPosition)

    let newCursor = cursorPosition + 1 + spacesAfterEnter

    //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
    this.setState({ value: newState }, () => this.setCursorPosition(newCursor))
    //console.log("select");
  }

  handleSpace = (e) => {
    const cursorPosition = this.getCursorPosition()
    // If the cursor is after text, return from this function and do the default space addition
    if (afterText(this.state.value, cursorPosition)) return // Do the default handling

    // If you got here, that means that you are do our doctered space. So in that case preventDefault action
    // after this function
    e.preventDefault()
    let val = this.state.value
    let cursorAtBeginning = getStartPosition2(this.state.value, cursorPosition)
    let spacesOnThisLine = getSpacesAtBeginningOfLine(
      this.state.value,
      cursorPosition
    )
    let numberOfSpacesToAdd = 6 - (spacesOnThisLine % 6)
    let spaceString = generateSpaces(numberOfSpacesToAdd)
    let newCursorPosition =
      cursorAtBeginning + spacesOnThisLine + numberOfSpacesToAdd

    //use the old cursor position here to create the new value state
    let newState =
      val.slice(0, cursorPosition) + spaceString + val.slice(cursorPosition)

    //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
    this.setState({ value: newState }, () =>
      this.setCursorPosition(newCursorPosition)
    )
  }

  handleTab = (e) => {
    e.preventDefault()
    const cursorPosition = this.getCursorPosition()

    // Possibly move the cursor up to right before the text when you tab before the text
    let cursorAtBeginning = getStartPosition2(this.state.value, cursorPosition)
    let spacesOnThisLine = getSpacesAtBeginningOfLine(
      this.state.value,
      cursorPosition
    )
    let isCursorAfterText =
      cursorPosition > cursorAtBeginning + spacesOnThisLine

    let val = this.state.value
    if (isCursorAfterText) {
      let newState =
        val.slice(0, cursorPosition) + "      " + val.slice(cursorPosition)
      //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
      this.setState({ value: newState }, () =>
        this.setCursorPosition(cursorPosition + 6)
      )
      return
    } else {
      let numberOfSpacesToAdd = 6 - (spacesOnThisLine % 6)
      let spaceString = generateSpaces(numberOfSpacesToAdd)
      let newCursorPosition =
        cursorAtBeginning + spacesOnThisLine + numberOfSpacesToAdd

      let newState =
        val.slice(0, cursorPosition) + spaceString + val.slice(cursorPosition)

      //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
      this.setState({ value: newState }, () =>
        this.setCursorPosition(newCursorPosition)
      )
      return
    }
  }

  handleKeyDown = (e) => {
    const cursorPosition = this.getCursorPosition()
    // distanceUpper
    // If there is something stop it

    // Prevent undo operation in textarea, it fucks with the state of the cursor and is
    // inconsistent across browsers, and mobile browsers
    if ((e.keyCode === 90 && e.metaKey) || (e.keyCode === 90 && e.ctrlKey)) {
      e.preventDefault()
      return
    }

    // If a widget is present
    if (this.state.visibleWidgetName === "AutoSuggestGroups") {
      if (e.keyCode === 13) {
        let selectedName = this.refs.AutoSuggestGroups.getSelectedName()
        let existingName = this.getPartialName(this.state.value, cursorPosition)
        if (existingName === selectedName) this.handleEnter(e)
        else {
          e.preventDefault()
          let curName = selectedName + " " // The space is needed to put a space after the name
          let startStop = getBeginningAndEndOfGroupsList2(
            this.state.value,
            cursorPosition
          )
          let newState =
            this.state.value.slice(0, startStop.begin) +
            curName +
            this.state.value.slice(startStop.end)
          let newCursorPosition = startStop.begin + curName.length

          //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
          this.setState({ value: newState }, () =>
            this.setCursorPosition(newCursorPosition)
          )
        }
      }

      // Up arrow
      if (e.keyCode === 38) {
        let didWork = this.refs.AutoSuggestGroups.decreaseIndexIfPossible()
        if (didWork) {
          e.preventDefault()
        }
      }

      // Down arrow
      if (e.keyCode === 40) {
        let didWork = this.refs.AutoSuggestGroups.increaseIndexIfPossible()
        if (didWork) {
          e.preventDefault()
        }
      }
    } else {
      if (e.keyCode === 13) {
        this.handleEnter(e)
      }
      if (e.keyCode === 9) {
        this.handleTab(e)
      }
      if (e.keyCode === 8) {
        this.handleBackspace(e)
      }

      if (e.keyCode === 32) {
        this.handleSpace(e)
      }
    }
  }

  handlePaste = (e) => {
    const cursorPosition = this.getCursorPosition()
    e.preventDefault()
    //distanceUpper
    let stringToPut = e.clipboardData.getData("text/plain") // Get copied text as string
    stringToPut = stringToPut.replace(/\t/g, "      ") // Convert tabs to spaces
    stringToPut = stringToPut.replace(/\r\n/g, "\n") // Convert lone \r\n to \n
    stringToPut = stringToPut.replace(/\r/g, "\n") // Convert lone \r to \n
    let val = this.state.value
    let newState =
      val.slice(0, cursorPosition) + stringToPut + val.slice(cursorPosition)

    //set the new cursoir postion once the value is updated becuaes the new cursor position we calculated was based off of the newstate value
    this.setState({ value: newState }, () =>
      this.setCursorPosition(cursorPosition + stringToPut.length)
    )
  }

  //this is the only one that calls updateSessionVariables. It is called anytime the cursor moves and therefore is the only time we need to check for autosuggestgroups, set active 'set text', and set active 'all text'
  handleSelect = (e) => {
    this.updateSessionVariables()
  }

  handleBlur = (e) => {
    //this is here for the #groups widget. When a user clicks on a group, the default behavior is to blur the textbox, so we come into this function.
    //we make sure to set disableblur to true when a group is clicked, which subsequently runs this first bit of code and refocuses the textbox.
    //lastly, we toggle disableBlue to false after this happens
    if (this.disableBlur) {
      this.setDisableBlur(false)
      $(this.refs.textMan).focus()
    } else {
      //normal blur behavior means make sure there are no auxillary widgets visible, save the workout, and clear the currentset text
      this.setState({ visibleWidgetName: "" })

      if (this.props.setCurrentSet) {
        this.props.setCurrentSet("", [])
      }
    }
  }

  //these events are only relevant if it's a touch device
  // handleClick = (e) => {
  //   if (this.context.isTouch) {
  //     //only override cursor position on iphone
  //     if (this.context.isIos) {
  //       //prevent default so cursor doesn't flash in the touchend default spot at beginning or end of word if click is never fired on long tap
  //       e.preventDefault()
  //
  //       //place cursor in the right spot
  //       this.setSelectionRange()
  //     }
  //   }
  // }

  // handleTouchStart = (e) => {
  //   if (this.context.isTouch) {
  //     //these are passed to touchend if we need to treat it like a click
  //     this.lastTouchX = e.touches[0].clientX
  //     this.lastTouchY = e.touches[0].clientY
  //     if (this.props.setThisLastTouchY) {
  //       this.props.setThisLastTouchY(this.lastTouchY)
  //     }
  //   }
  // }

  // handleScroll = () => {
  //   if (this.context.isIos) {
  //     getRidOfOrSetCursor($(this.refs.textMan))
  //   }
  // }
  //
  // handleTouchMove = () => {
  //   if (this.context.isTouch) {
  //     //set userMoving to true to prevent our custom cursor position logic from kicking in
  //     this.userMoving = true
  //   }
  // }
  //
  // handleTouchEnd = (e) => {
  //   if (this.context.isTouch && !this.userMoving) {
  //     //make sure this isn't a scroll event and only override cursor position on iphone
  //     if (this.context.isIos) {
  //       //prevent default so cursor doesn't flash in the touchend default spot at beginning or end of word if click is never fired on long tap
  //       e.preventDefault()
  //
  //       //place cursor in the right spot
  //       this.setSelectionRange()
  //     }
  //     //set userMoving to false on every touch end
  //     this.userMoving = false
  //   }
  // }

  //render functions or functions related to rendering directly
  getPartialName(text, cursorPosition) {
    let partialNameStart = scanStringForCharacter(
      text,
      cursorPosition,
      "#",
      true
    )
    // partialNameStart is at the end of the text
    return partialNameStart >= text.length
      ? ""
      : text.slice(partialNameStart + 1, cursorPosition)
  }

  renderAutoSuggest() {
    if (this.props.addGroupAutoComplete) {
      const cursorPosition = this.getCursorPosition()
      let withoutHashtag = this.getPartialName(this.state.value, cursorPosition)
      return (
        <AutoSuggestGroups
          ref="AutoSuggestGroups"
          groupsArray={this.props.groupsArray}
          isVisible={this.state.visibleWidgetName === "AutoSuggestGroups"}
          left={this.state.cursorLeft}
          top={this.state.cursorTop}
          textBoxHeight={
            this.refs.textMan ? $(this.refs.textMan).innerHeight() || 300 : 300
          }
          partialName={withoutHashtag}
          onGroupClick={this.insertGroup}
          setDisableBlur={this.setDisableBlur}
        />
      )
    }
    return null // Display nothing if you are not displaying autosuggest
  }

  render() {
    let {
      setCurrentSet,
      setAllText,
      singleCycle,
      addAutoSave,
      workoutId,
      addGroupAutoComplete,
      groupsArray,
      className,
      startingValue,
      cycleStrings,
      autoFocus,
      setThisLastTouchY,
      setDisableBlur,
      listenForWorkoutTextChange,
      setAllTextContinuously,
      isMobile,

      ...other
    } = this.props

    return (
      <div>
        <textarea
          ref="textMan"
          {...other}
          value={this.state.value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onCopy={this.handleCopy}
          onPaste={this.handlePaste}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onSelect={this.handleSelect}
          className={className ? className + " needsclick" : "needsclick"}
        />
        {this.renderAutoSuggest()}
      </div>
    )
  }
}

export { CommitTextBox }
